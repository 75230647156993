import { StringKeys } from "./utility/typescriptTypes";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { RulesEnum } from "./rules/rules.map";

export class MDetailFormConfig {
  config!: IMDetailFormConfig[];
  constructor(config: IMDetailFormConfig[]) {
    this.config = config;
  }

  getConfigKeys(): string[] {
    return this.config.map(c => String(c.key));
  }

  getConfigByKey(key: string) {
    return this.config.find(c => c.key === key);
  }

  getConfigValueByKey<T>(key: string): T {
    return this.getConfigByKey(key)?.model;
  }

  set configValueByKey(newValue: { key: string; value: any }) {
    const found = this.getConfigByKey(newValue.key);
    if (found) {
      found.model = newValue.value;
    }
  }
}

export interface IFormCondition {
  key: string;
  value: string | boolean;
}

export interface IMDetailFormConfig<T = any> {
  /**
   * category of the value; groups the values by category, e.g adress oder personal information
   */
  category: string;

  /**
   * Short description of the category
   */
  categoryHint?: string;

  /**
   * key to identify this config. must be unique
   */
  key: StringKeys<T>;

  /**
   * keywords for the search of the form
   */
  searchKeywords: string[];

  /**
   * name of the component that should be rendered
   */
  type: DetailFormComponentsEnum;

  /**
   * v-model of the component that shoud be rendered
   */
  model?: any;

  /**
   * Makrs a path as clearable (note, this may apply to a leaf or even a parent, if its children are clearable, however it is not an inherited property!)
   */
  clearable?: boolean;

  /**
   * Makrs a path as clearable as array
   */
  isArray?: boolean;

  /**
   * Rules for the form
   */
  rules?: RulesEnum[];

  /**
   * The Property of the component that are bind via v-bind to the template
   */
  props?: {
    items?: any[];
    itemCallback?: () => any[];
    itemValue?: string;
    itemText?: string;
    label?: string;
  } & any;

  /**
   * Render form element if model of key matches value
   */
  condition?: IFormCondition;
}

export interface IFormableClass {
  formables: IMDetailFormConfig[];
  clearables: string[];
}

type IIsFormableConfiguration = Omit<Omit<IMDetailFormConfig, "key">, "type"> & {
  type: DetailFormComponentsEnum | IFormableClass;
};

/**
 * Class decorator that adds a getter for searchable properties of the class
 *
 * @param constructor
 * @returns
 */
function IsFormable<T extends { new (...args: any[]): {} }>(constructor: T) {
  return class extends constructor {
    /**
     * List of searchable properties of the class
     */
    static get formables(): IMDetailFormConfig[] {
      return constructor.prototype.formables ?? [];
    }

    /**
     * List of clearable properties of the class
     */
    static get clearables(): string[] {
      return constructor.prototype.clearables ?? [];
    }

    constructor(...args: any[]) {
      super(...args);

      // formables getter is not enumerable
      Object.defineProperty(constructor, "formables", {
        enumerable: false
      });

      // clearables getter is not enumerable
      Object.defineProperty(constructor, "clearables", {
        enumerable: false
      });
    }
  };
}

/**
 * Property decorator that adds a property to the prototype that contains the formables
 * method-decorator @see https://www.typescriptlang.org/docs/handbook/decorators.html#method-decorators
 *
 * @param config
 * @returns
 */
function FormConfig(config: IIsFormableConfiguration) {
  return function(target: any, propertyKey: string) {
    if (!target.formables) {
      target.formables = [];
    }
    if (!target.clearables) {
      target.clearables = [];
    }

    if (config.clearable) {
      // add the clearble to the clearble array of the object
      target.clearables.push(propertyKey);
    }
    (config.type as IFormableClass)?.clearables?.forEach(nestedClearbleKey => {
      // in case we have a nesting add the nested clearble to the clearble array of the parent
      const nestedKey = `${propertyKey}.${nestedClearbleKey}`;
      target.clearables.push(nestedKey);
    });

    if (config.isArray && !Object.values(DetailFormComponentsEnum).includes(config.type as DetailFormComponentsEnum)) {
      target.formables.push({
        ...config,
        key: propertyKey,
        searchKeywords: [
          ...(config.searchKeywords ?? []),
          ...(config.type as IFormableClass).formables.map(f => f.searchKeywords).flat(),
          ...(config.type as IFormableClass).formables
            .map(f => f.props?.label)
            .filter(l => !!l)
            .flat()
        ],
        type: DetailFormComponentsEnum.ARRAY,
        props: {
          ...(config.props ?? {}),
          form: config.type as IFormableClass
        }
      });
    }

    if (!config.isArray) {
      if (Object.values(DetailFormComponentsEnum).includes(config.type as DetailFormComponentsEnum)) {
        // add the formable to the formables array of the object
        target.formables.push({ ...config, key: propertyKey });
      } else {
        // in case we have a nesting add the nested formables to the formables array of the parent
        (config.type as IFormableClass).formables.forEach(nestedSearchableConfig => {
          const nestedKey = `${propertyKey}.${nestedSearchableConfig.key}`;
          target.formables.push({ ...nestedSearchableConfig, key: nestedKey });
        });
      }
    }
  };
}

/**
 * Apply type of IsFormable class
 *
 * @see IsFormable
 */
class Form {
  private static assertIsFormableClass<T extends Function>(c: T): asserts c is T & IFormableClass {
    if (!((c as any) as IFormableClass).formables) {
      ((c as any) as IFormableClass).formables = [];
    }

    if (!((c as any) as IFormableClass).clearables) {
      ((c as any) as IFormableClass).clearables = [];
    }
  }
  /**
   * Apply type of IsFormable class
   *
   * @see IsFormable
   */
  static createForClass<Class extends Function>(c: Class): Class & IFormableClass {
    this.assertIsFormableClass(c);
    return c;
  }
}

export { Form, IsFormable, FormConfig };
