import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { IEntity } from "@/lib/utility/data/entity.interface";
import {
  MrfiktivActivityLogViewModelGen,
  MrfiktivCreateActivityLogDtoGen,
  MrfiktivUpdateActivityLogDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import sharedActivityLogService from "@/services/shared/sharedActivityLogService";
import { ThgActivityLogViewModelGen } from "@/services/thg/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityLogDataAccessLayer } from "@/store/modules/access-layers/activity-log-service.access-layer";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { IReference, Reference } from "./reference.entity";
import { IShortUser, ShortUser } from "./short-user.entity";
import { ITimestampDocument, TimestampDocument } from "./timestamp.entity";
import { PartnerUserAccessLayer } from "@/store/modules/access-layers/partner-user.access-layer";

@IsFilterable
class ActivityLogBase
  implements
    IEntity<IActivityLog, MrfiktivUpdateActivityLogDtoGen>,
    MrfiktivActivityLogViewModelGen,
    ThgActivityLogViewModelGen {
  /** The id of the activity log */
  id: string;

  /** The id of the partner */
  partnerId: string;

  /** The references of the activity */
  @FilterConfig({ type: Reference })
  source: IReference;

  /** The action type */
  @FilterConfig({
    type: FilterTypes.ENUM,
    displayName: "objects.activityLog.actionType",
    config: {
      items: Object.values(ActionEnum).map(v => {
        return {
          text: `enums.ActionEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  actionType: ActionEnum;

  /** The users id */

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.activityLog.userId",
    config: {
      itemCallback: () => PartnerUserAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId?: string;

  /** The user */
  @FilterConfig({ type: ShortUser })
  user?: IShortUser;

  /** The target reference of the activity */
  @FilterConfig({ type: Reference })
  target?: IReference[];

  /** An optional comment to the source resource activity */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.activityLog.comment"
  })
  comment?: string;

  /** An optional free text activity */
  @FilterConfig({
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(ActivityTypeEnum)
    },
    displayName: "objects.activityLog.activity"
  })
  activity?: ActivityTypeEnum;

  /** Is the activity deletable */
  isDeletable?: boolean;

  /** Is the activity updatable */
  isUpdatable?: boolean;

  /** Is the activity viewable by the public */
  isPublic?: boolean;

  /**
   * The timestamps of the document
   * @example {"created":"2021-01-01T12:12:12.317Z","lastModified":"2021-01-01T12:12:12.317Z","modified":["2021-01-01T12:12:12.317Z","2021-01-01T12:12:12.000Z"]}
   */
  @FilterConfig({ type: TimestampDocument })
  timestamp: ITimestampDocument;

  constructor(activityLog?: Partial<MrfiktivActivityLogViewModelGen | ThgActivityLogViewModelGen | IActivityLog>) {
    this.id = activityLog?.id ?? "";
    this.partnerId = activityLog?.partnerId ?? "";
    this.source = new Reference(activityLog?.source);
    this.actionType = (activityLog?.actionType ?? ActionEnum.CREATE) as ActionEnum;
    this.userId = activityLog?.userId;
    this.user = new ShortUser(activityLog?.user);
    this.target = (activityLog?.target as IReference[])?.map(t => new Reference(t)) ?? [];
    this.comment = activityLog?.comment;
    this.activity = activityLog?.activity as ActivityTypeEnum | undefined;
    this.isDeletable = activityLog?.isDeletable;
    this.isUpdatable = activityLog?.isUpdatable;
    this.isPublic = activityLog?.isPublic;
    this.timestamp = new TimestampDocument(activityLog?.timestamp);
  }

  map(activityLog?: Partial<MrfiktivActivityLogViewModelGen | ThgActivityLogViewModelGen>) {
    this.id = activityLog?.id ?? "";
    this.partnerId = activityLog?.partnerId ?? "";
    this.source = new Reference(activityLog?.source);
    this.actionType = (activityLog?.actionType ?? ActionEnum.CREATE) as ActionEnum;
    this.userId = activityLog?.userId;
    this.user = new ShortUser(activityLog?.user);
    this.target = (activityLog?.target as IReference[])?.map(t => new Reference(t)) ?? [];
    this.comment = activityLog?.comment;
    this.activity = activityLog?.activity as ActivityTypeEnum | undefined;
    this.isDeletable = activityLog?.isDeletable;
    this.isUpdatable = activityLog?.isUpdatable;
    this.isPublic = activityLog?.isPublic;
    this.timestamp = new TimestampDocument(activityLog?.timestamp);
  }

  /**
   * Refreshes the entity using the backend, returns the updated entity
   */

  async fetch(): Promise<this> {
    const res = await sharedActivityLogService.getOneForPartner(this.partnerId, this.id);
    this.map(res);
    ActivityLogDataAccessLayer.set(this);

    return this;
  }
  /**
   * Partial update of the entity using the backend, returns the updated entity and updates the data access layer
   * @param dto
   */
  async updatePartial(dto: MrfiktivUpdateActivityLogDtoGen): Promise<this> {
    const res = await sharedActivityLogService.updateForPartner(this.partnerId, this.id, dto);

    this.map(res);
    ActivityLogDataAccessLayer.set(this);

    return this;
  }

  /**
   * Deletes the entity from the backend and the data access layer
   */
  async delete(): Promise<void> {
    await sharedActivityLogService.removeForPartner(this.partnerId, this.id);
    ActivityLogDataAccessLayer.delete(this);
  }

  /**
   * Creates a new activity log
   * @returns
   */
  async create(): Promise<this> {
    const data: MrfiktivCreateActivityLogDtoGen = {
      actionType: this.actionType,
      source: this.source,
      activity: this.activity,
      comment: this.comment,
      target: this.target,
      isDeletable: this.isDeletable,
      isPublic: this.isPublic,
      isUpdatable: this.isUpdatable
    };
    const res = await sharedActivityLogService.create(this.partnerId, data);

    this.map(res);
    ActivityLogDataAccessLayer.set(this);

    return this;
  }

  async createAssigneeActivity(data: {
    source: IReference;
    partnerId: string;
    activityType: ActivityTypeEnum.CREATE_ASSIGNEE | ActivityTypeEnum.DELETE_ASSIGNEE;
    newAssignees?: string[];
  }) {
    if (!data.newAssignees?.length) return;

    this.partnerId = data.partnerId;
    this.source = data.source;
    this.target = data.newAssignees.map(assignee => {
      return {
        refType: BackendResourceEnum.USER,
        refId: assignee
      };
    });
    this.actionType = ActionEnum.UPDATE;
    this.activity = data.activityType;

    await this.create();
  }
}

type IActivityLog = ActivityLogBase;
const ActivityLog = Filter.createForClass(ActivityLogBase);

export { ActivityLog, IActivityLog };
