import { render, staticRenderFns } from "./TheNavigationLayout.vue?vue&type=template&id=f7c87f5e&scoped=true&"
import script from "./TheNavigationLayout.vue?vue&type=script&lang=ts&"
export * from "./TheNavigationLayout.vue?vue&type=script&lang=ts&"
import style0 from "./TheNavigationLayout.vue?vue&type=style&index=0&id=f7c87f5e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7c87f5e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBadge,VIcon,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VSubheader})
