import VueRouter from "vue-router";
import { BaseGoToHelper } from "./goToHelper";
import { $t } from "./t";

export enum PartnerUserRouteNames {
  PARTNER_USER_TABLE = "PARTNER_USER_TABLE",
  PARTNER_USER_DETAIL = "PARTNER_USER_DETAIL"
}

type Tab = { newTab?: boolean };
type Partner = { partnerId: string };
type PartnerUser = { partnerUserId: string };
type Query = { query?: Record<string, string> };

export class PartnerUserGoToHelper extends BaseGoToHelper {
  static get breadCrumbs() {
    const locations = PartnerUserGoToHelper.locations;

    return {
      PartnerUserTable: (partnerId: string) => {
        return {
          text: $t("user.users").toString(),
          exact: true,
          to: locations.partnerUserTable({ partnerId })
        };
      },
      PartnerUserDetail: (partnerId: string, partnerUserId: string) => {
        return {
          text: $t("common.nouns.detail").toString(),
          exact: true,
          to: locations.partnerUserDetail({ partnerId, partnerUserId })
        };
      }
    };
  }

  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  static locations = {
    partnerUserTable: (d: Partner & Query) => {
      return {
        name: PartnerUserRouteNames.PARTNER_USER_TABLE,
        params: { partnerId: d.partnerId },
        query: d.query
      };
    },
    partnerUserDetail: (d: Partner & PartnerUser & Query) => {
      return {
        name: PartnerUserRouteNames.PARTNER_USER_DETAIL,
        params: { partnerId: d.partnerId, partnerUserId: d.partnerUserId },
        query: d.query
      };
    }
  };

  goToPartnerUserTable(d: Tab & Partner) {
    this.go(PartnerUserGoToHelper.locations.partnerUserTable(d), d.newTab);
  }

  goToPartnerUserDetail(d: Tab & Partner & PartnerUser) {
    this.go(PartnerUserGoToHelper.locations.partnerUserDetail(d), d.newTab);
  }
}
