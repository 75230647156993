import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IPerson, Person } from "@/models/person.entity";

export const PersonDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IPerson, ""> {
  getIdentifier(entity: IPerson): string {
    return (entity as IPerson).id;
  }

  mapEntity(entity: IPerson): IPerson {
    return new Person(entity);
  }
})();
