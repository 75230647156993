import {
  MrfiktivProjectConfigurationViewModelGen,
  MrfiktivProjectCustomViewViewmodelGen,
  MrfiktivCustomFieldListElementViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { CustomFieldListElement, ICustomFieldListElement } from "./custom-field-list-element.entity";
import { IProjectCustomView, ProjectCustomView } from "./project-custom-view.entity";
import { Filter, FilterConfig, IsFilterable } from "@/lib/filterable";

@IsFilterable
class ProjectConfigurationBase implements MrfiktivProjectConfigurationViewModelGen {
  customFieldConfig: ICustomFieldListElement[];
  isTemplate: boolean;

  @FilterConfig({
    type: ProjectCustomView
  })
  views: IProjectCustomView[];

  constructor(config?: Partial<MrfiktivProjectConfigurationViewModelGen | IProjectConfiguration>) {
    this.customFieldConfig =
      (config?.customFieldConfig as (ICustomFieldListElement | MrfiktivCustomFieldListElementViewModelGen)[])?.map(
        c => new CustomFieldListElement(c)
      ) ?? [];
    this.isTemplate = config?.isTemplate ?? false;
    this.views =
      (config?.views as (MrfiktivProjectCustomViewViewmodelGen | IProjectCustomView)[])?.map(
        (v: MrfiktivProjectCustomViewViewmodelGen | IProjectCustomView) => new ProjectCustomView(v)
      ) ?? [];
  }
}

type IProjectConfiguration = ProjectConfigurationBase;
const ProjectConfiguration = Filter.createForClass(ProjectConfigurationBase);

export { IProjectConfiguration, ProjectConfiguration };
