import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import deCountryCodeEnum from "../locales/country-code-enum/de.country-code-enum.json";
import de_chCountryCodeEnum from "../locales/country-code-enum/de_ch.country-code-enum.json";
import enCountryCodeEnum from "../locales/country-code-enum/en.country-code-enum.json";
import frCountryCodeEnum from "../locales/country-code-enum/fr.country-code-enum.json";
import plCountryCodeEnum from "../locales/country-code-enum/pl.country-code-enum.json";
import de from "../locales/de.json";
import de_ch from "../locales/de_ch.json";
import en from "../locales/en.json";
import fr from "../locales/fr.json";
import pl from "../locales/pl.json";

/**
 * Registered VueI18n for multiple languages.
 */
Vue.use(VueI18n);

/**
 * Currently supported locales via import.
 */
export const SUPPORT_LOCALES: LanguageCodeEnum[] = [
  LanguageCodeEnum.DE,
  LanguageCodeEnum.DE_CH,
  LanguageCodeEnum.EN,
  LanguageCodeEnum.FR,
  LanguageCodeEnum.PL
];

/**
 * Maps locale to @see LocaleMessages object from json files.
 *
 * TODO: Think about Lazy loading these via @see https://vue-i18n.intlify.dev/guide/advanced/lazy.html.
 *
 * @returns {LocaleMessages} localized messages that are imported from the respective json files.
 */
function loadLocaleMessages(): LocaleMessages {
  const messages: LocaleMessages = {
    en: {
      ...en,
      ...enCountryCodeEnum
    },
    de: {
      ...de,
      ...deCountryCodeEnum
    },
    de_ch: {
      ...de_ch,
      ...de_chCountryCodeEnum
    },
    fr: {
      ...fr,
      ...frCountryCodeEnum
    },
    pl: {
      ...pl,
      ...plCountryCodeEnum
    }
  };
  return messages;
}

/**
 * Exports the VuetifyI18n plugin.
 */
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || LanguageCodeEnum.DE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || LanguageCodeEnum.EN,
  messages: loadLocaleMessages()
});
