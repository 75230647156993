import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import {
  MrfiktivCreateProjectDtoGen,
  MrfiktivCustomFieldListElementDtoGen,
  MrfiktivProjectViewModelGen,
  MrfiktivUpdateProjectDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { IProjectConfiguration, ProjectConfiguration } from "./project-configuration.entity";
import { IShortUser, ShortUser } from "./short-user.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import projectService from "@/services/mrfiktiv/services/projectService";
import { handleError } from "@/lib/utility/handleError";
import { ProjectDataAccessLayer } from "@/store/modules/access-layers/project.access-layer";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { CustomViewEntity } from "@/lib/interfaces/custom-view-entity.interface";

@IsFilterable
class ProjectBase implements MrfiktivProjectViewModelGen, CustomViewEntity<IProject> {
  id: string;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.project.id"
  })
  get _id() {
    return this.id;
  }

  partnerId: string;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.project.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: (item: any) => {
        return { item };
      },
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId: string;

  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.project.title"
  })
  title: string;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.project.description"
  })
  description?: string | undefined;

  @FilterConfig({
    type: ProjectConfiguration
  })
  configuration: IProjectConfiguration;
  closedAt?: string | undefined;
  closedBy?: string | undefined;
  closedByDetails?: IShortUser | undefined;

  constructor(project?: Partial<MrfiktivProjectViewModelGen | IProject>) {
    this.id = project?.id ?? "";
    this.partnerId = project?.partnerId ?? "";
    this.userId = project?.userId ?? "";
    this.timestamp = new Timestamp(project?.timestamp);
    this.title = project?.title ?? "";
    this.description = project?.description;
    this.configuration = new ProjectConfiguration(project?.configuration);
    this.closedAt = project?.closedAt;
    this.closedBy = project?.closedBy;
    this.closedByDetails = project?.closedByDetails ? new ShortUser(project.closedByDetails) : undefined;
  }

  map(project: MrfiktivProjectViewModelGen) {
    this.id = project.id ?? "";
    this.partnerId = project.partnerId;
    this.userId = project.userId;
    this.timestamp = new Timestamp(project.timestamp);
    this.title = project.title;
    this.description = project.description;
    this.configuration = new ProjectConfiguration(project.configuration);
    this.closedAt = project.closedAt;
    this.closedBy = project.closedBy;
    this.closedByDetails = project.closedByDetails ? new ShortUser(project.closedByDetails) : undefined;
  }

  private get customFieldConfigDto(): MrfiktivCustomFieldListElementDtoGen[] {
    return this.configuration.customFieldConfig
      .filter(c => c.customField.id) // filter out deleted custom fields
      .map(c => {
        return {
          configuration: c.configuration,
          customField: c.customField.id
        };
      });
  }

  get createDto(): MrfiktivCreateProjectDtoGen {
    const dto: MrfiktivCreateProjectDtoGen = {
      title: this.title,
      description: this.description,
      configuration: {
        isTemplate: this.configuration.isTemplate,
        customFieldConfig: this.customFieldConfigDto,
        views: []
      }
    };

    return dto;
  }

  get updateDto(): MrfiktivUpdateProjectDtoGen {
    const dto: MrfiktivUpdateProjectDtoGen = {
      title: this.title,
      description: this.description,
      configuration: {
        isTemplate: this.configuration.isTemplate,
        customFieldConfig: this.customFieldConfigDto,
        views: this.configuration.views.map(v => v.dto)
      }
    };

    return dto;
  }

  async create() {
    const res = await projectService.create(this.partnerId, this.createDto);

    this.map(res);

    ProjectDataAccessLayer.set(this);

    return this;
  }

  async delete() {
    try {
      const res = await projectService.delete(this.partnerId, this.id);

      this.map(res);

      ProjectDataAccessLayer.delete(this);
    } catch (e) {
      handleError(e);
    }
  }

  async fetch() {
    try {
      const res = await projectService.getOne(this.partnerId, this.id);

      this.map(res);

      ProjectDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  fetchSync() {
    this.fetch();
    return this;
  }

  async update() {
    try {
      const res = await projectService.update(this.partnerId, this.id, this.updateDto);

      this.map(res);

      ProjectDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async updatePartial(updateDto: MrfiktivUpdateProjectDtoGen) {
    const res = await projectService.update(this.partnerId, this.id, updateDto);

    this.map(res);

    ProjectDataAccessLayer.set(this);

    return this;
  }
}

type IProject = ProjectBase;
const Project = Filter.createForClass(ProjectBase);

export { IProject, Project };
