import { PersonLabelEnum } from "@/lib/enum/person-label.enum";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { RulesEnum } from "@/lib/rules/rules.map";
import { $t } from "@/lib/utility/t";
import { MrfiktivPersonEmailViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { toast } from "@/lib/utility/toast";

@IsFormable
@IsFilterable
export class PersonEmailBase implements MrfiktivPersonEmailViewModelGen {
  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.email",
    searchKeywords: ["common.nouns.email", "objects.person.emails.email"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "common.nouns.email"
    },
    rules: [RulesEnum.REQUIRED_RULE, RulesEnum.EMAIL_RULE]
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.person.emails.email"
  })
  email: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.email",
    searchKeywords: ["common.nouns.email", "objects.person.emails.label"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      items: Object.values(PersonLabelEnum).map(v => {
        return {
          text: $t(`enums.PersonLabelEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value",
      label: "objects.person.emails.label"
    },
    clearable: true
  })
  label?: PersonLabelEnum;

  @FormConfig({
    category: "common.nouns.email",
    searchKeywords: ["common.nouns.email", "objects.person.emails.notes"],
    type: DetailFormComponentsEnum.TEXT_AREA,
    props: {
      label: "objects.person.emails.notes"
    },
    clearable: true
  })
  notes?: string;

  /**
   * Construct person
   */
  constructor(obj?: Partial<PersonEmailBase | MrfiktivPersonEmailViewModelGen>) {
    this.email = obj?.email ?? "";
    this.label = obj?.label as PersonLabelEnum;
    this.notes = obj?.notes;
  }

  copyToClipboard(): void {
    navigator.clipboard.writeText(this.email);
    toast.info($t("components.partner.PartnerReportInitializeCard.linkCopied"));
  }
}

type IPersonEmail = PersonEmailBase;
const PersonEmail = Form.createForClass(Filter.createForClass(PersonEmailBase));

export { PersonEmail, IPersonEmail };
