import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { PaginatedBaseStore } from "@/store/paginated-base.store";
import store from "@/store/VuexPlugin";
import { getModule, Module } from "vuex-module-decorators";
import { PaginationFilterListElement } from "./base-pagination.store";
import { ReportPaginationModule } from "./report-pagination.store";

import { IReport } from "@/models/report.entity";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";

import { ReportPageDataProvider } from "./page-data-provider/report.page-data-provider";
import { VehiclePageDataProvider } from "./page-data-provider/vehicle.page-data-provider";

import { ReportDataAccessLayer } from "./access-layers/report.access-layer";
import { VehicleAccessLayer } from "./access-layers/vehicle.access-layer";

import {
  MrfiktivPersonControllerGetParamsGen,
  MrfiktivReportControllerFindAllParamsGen,
  MrfiktivVehicleControllerGetAllParamsGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { BookingDataAccessLayer } from "./access-layers/booking.access-layer";
import { BookingPageDataProvider } from "./booking.store";
import { IBooking } from "@/models/booking.entity";
import { BookingBookingControllerFindAllByPartnerIdParamsGen } from "@/services/booking/v1/data-contracts";
import { PersonDataAccessLayer } from "./access-layers/person.access-layer";
import { PersonPageDataProvider } from "./page-data-provider/person.page-data-provider";
import { IPerson } from "@/models/person.entity";

@Module({
  dynamic: true,
  namespaced: true,
  name: "search-vehicle",
  store
})
class SearchVehicleStore extends PaginatedBaseStore<IVehicle, MrfiktivVehicleControllerGetAllParamsGen> {
  _data = VehicleAccessLayer;
  _pageProvider = VehiclePageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);
  filterOptions: PaginationFilterListElement[] = Vehicle.filterables;
}

/**
 * wraps the timed event endpoint into a makeshift event pagination @see RefTypeMap
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "search-report",
  store
})
class SearchReportStore extends PaginatedBaseStore<IReport, MrfiktivReportControllerFindAllParamsGen> {
  protected _data = ReportDataAccessLayer;
  protected _pageProvider = ReportPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = ReportPaginationModule.filterOptions;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "search-booking",
  store
})
class SearchBookingStore extends PaginatedBaseStore<IBooking, BookingBookingControllerFindAllByPartnerIdParamsGen> {
  protected _data = BookingDataAccessLayer;
  protected _pageProvider = BookingPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = ReportPaginationModule.filterOptions;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "search-person",
  store
})
class SearchPersonStore extends PaginatedBaseStore<IPerson, MrfiktivPersonControllerGetParamsGen> {
  protected _data = PersonDataAccessLayer;
  protected _pageProvider = PersonPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = ReportPaginationModule.filterOptions;
}

export const PersonSearchModule = getModule(SearchPersonStore);
export const BookingSearchModule = getModule(SearchBookingStore);
export const ReportSearchModule = getModule(SearchReportStore);
export const VehicleSearchModule = getModule(SearchVehicleStore);
