var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', _vm._g({}, _vm.hasClickListener ? {
    click: function click() {
      return _vm.$emit('click');
    }
  } : {}), [_vm._t("icon"), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-truncate"
  }, [_vm.hasOpenDetailListener ? _c('a', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.emitOpenDetail.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.titleReadable) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.item.titleReadable) + " ")])]), !_vm.small ? _c('v-list-item-subtitle', {
    staticClass: "text-truncate text-xs"
  }, [_vm.item.emails[0] ? _c('span', [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-email")]), _vm._v(" " + _vm._s(_vm.item.emails[0].email) + " ")], 1) : _vm._e(), _vm.item.phones[0] ? _c('span', [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-phone")]), _vm._v(" " + _vm._s(_vm.item.phones[0].phone) + " ")], 1) : _vm._e(), _vm.item.addresses[0] ? _c('span', [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-map-marker")]), _vm._v(" " + _vm._s(_vm.item.addresses[0].street) + ", " + _vm._s(_vm.item.addresses[0].zip) + " " + _vm._s(_vm.item.addresses[0].city) + " " + _vm._s(_vm.item.addresses[0].state) + " ")], 1) : _vm._e()]) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }