import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IGroup, Group } from "@/models/group.entity";

export const GroupDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IGroup> {
  getIdentifier(entity: IGroup): string {
    return entity.id;
  }

  protected mapEntity(entity: IGroup): IGroup {
    return new Group(entity);
  }
})();
