import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { IsFilterable, Filter, FilterTypes, FilterConfig } from "@/lib/filterable";
import { FormConfig, IsFormable, Form } from "@/lib/formable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { $t } from "@/lib/utility/t";
import { MrfiktivAddressGen, MrfiktivAddressWithGeoGen } from "@/services/mrfiktiv/v1/data-contracts";

@IsFormable
@IsFilterable
export class AddressPartialBase implements Partial<MrfiktivAddressGen> {
  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.street", "common.nouns.address"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.address.street"
    },
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.street",
    type: FilterTypes.STRING
  })
  street?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.zip", "common.nouns.address"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.address.zip"
    },
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.zip",
    type: FilterTypes.STRING
  })
  zip?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.city", "common.nouns.address"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.address.city"
    },
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.city",
    type: FilterTypes.STRING
  })
  city?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.state", "common.nouns.address"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.address.state"
    },
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.state",
    type: FilterTypes.STRING
  })
  state?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.address.countryCode", "common.nouns.address"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      required: false,
      label: "objects.address.countryCode",
      items: Object.values(CountryCodeEnum).map(v => {
        return {
          text: $t(`CountryCodeEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.countryCode",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(CountryCodeEnum).map(v => {
        return {
          text: $t(`CountryCodeEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  countryCode?: string | undefined;

  constructor(address?: Partial<MrfiktivAddressGen>) {
    this.street = address?.street;
    this.zip = address?.zip;
    this.city = address?.city;
    this.state = address?.state;
    this.countryCode = address?.countryCode;
  }
}
export class AddressWithGeoPartialBase extends AddressPartialBase implements Partial<MrfiktivAddressWithGeoGen> {
  geo: {
    lat: number;
    lng: number;
  };
  constructor(address?: Partial<MrfiktivAddressWithGeoGen>) {
    super(address);
    this.geo = address?.geo ?? { lat: 0, lng: 0 };
  }
}
type IAddressPartial = AddressPartialBase;
const AddressPartial = Form.createForClass(Filter.createForClass(AddressWithGeoPartialBase));
type IAddressWithGeoPartial = AddressPartialBase;
const AddressWithGeoPartial = Form.createForClass(Filter.createForClass(AddressWithGeoPartialBase));

export { AddressPartial, AddressWithGeoPartial, IAddressPartial, IAddressWithGeoPartial };
