import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import { NavigationRoute } from "../mapper/navigation.route";
import { INavigationConfiguration } from "../interface/navigation.configuration.interface";

function createCategoryMap(items: NavigationRoute[]): { category: string; items: NavigationRoute[] }[] {
  const categoryMap = new Map<string, NavigationRoute[]>();

  for (const item of items) {
    if (item.navigation?.category) {
      const entries = categoryMap.get(item.navigation.category);
      if (entries) {
        entries.push(item);
        categoryMap.set(item.navigation.category, entries);
      } else {
        categoryMap.set(item.navigation.category, [item]);
      }
    }
  }

  const link: { category: string; items: NavigationRoute[] }[] = [];
  for (const key of categoryMap.keys()) {
    link.push({ category: key, items: categoryMap.get(key) || [] });
  }

  return link;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "navigation",
  store
})
export class NavigationStore extends VuexModule {
  /**
   * Default set to false. Can be set on Appstart. If not set, navigation is not generated automatically
   */
  _navigationConfiguration: INavigationConfiguration = {
    isQuicklinks: false,
    isSidebarNavigation: false,
    isUserMenuNavigation: false
  };

  /**
   * The Configuration for the navigation
   */
  get navigationConfiguration(): INavigationConfiguration {
    return this._navigationConfiguration;
  }

  /**
   * Access router config during runtime (e.g. to build the menu, quicklinks, etc)
   */
  _routes: NavigationRoute[] = [];

  /**
   * All configured routes
   */
  get routes() {
    return this._routes;
  }

  /**
   * All available permitted routes
   * Checks if a user is permitted to see the content
   * Checks if the route is enabled
   */
  get permittedRoutes() {
    return this.routes
      .filter(item => item.isAccessAllowed && item.navigation?.isEnabled)
      .sort(function(a, b) {
        return (b.navigation?.priority || 0) - (a.navigation?.priority || 0);
      });
  }

  /**
   * All available Quicklinks
   * Checks if a user has acces to the route and if the route is configured as a quicklink
   */
  get quickLinks() {
    return this.permittedRoutes.filter(item => item.navigation?.isQuickLink);
  }

  /**
   * Return Configured Quicklinks with subheader
   */
  get quickLinksWithSubheader() {
    return createCategoryMap(this.quickLinks);
  }

  /**
   * All available categories in quicklinks
   */
  get quickLinkCategories() {
    const uniqueCategories = [...new Map(this.quickLinks.map(v => [v.navigation?.category, v])).values()];
    return uniqueCategories.map(item => item.navigation?.category).sort();
  }

  /**
   * All available side bar navigations
   * Checks if a user has acces to the route and if the route is configured as a side bar navigation
   */
  get sideBarNavigation() {
    return this.permittedRoutes.filter(item => item.navigation?.isSideBarNavigation);
  }

  get sideBarNavigationWithSubheader() {
    return createCategoryMap(this.sideBarNavigation);
  }

  /**
   * All available profileMenu navigations
   * Checks if a user has acces to the route and if the route is configured as a profile menu navigation
   */
  get profileMenu() {
    return this.permittedRoutes.filter(item => item.navigation?.isProfileMenu);
  }

  @Mutation
  _mutateRoutes(routes: NavigationRoute[]) {
    this._routes = routes;
  }

  @Action
  setRoutes(routes: NavigationRoute[]) {
    this.context.commit("_mutateRoutes", routes);
  }

  @Mutation
  _mutateNavigationConfiguration(navigationConfiguration: INavigationConfiguration) {
    this._navigationConfiguration = { ...this._navigationConfiguration, ...navigationConfiguration };
  }

  @Action
  setNavigationConfiguration(navigationConfiguration: Partial<INavigationConfiguration>) {
    this.context.commit("_mutateNavigationConfiguration", navigationConfiguration);
  }
}

export const NavigationModule = getModule(NavigationStore);
