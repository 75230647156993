var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "command-palette-transition"
    }
  }, [_c('v-dialog', {
    attrs: {
      "max-width": "600",
      "min-height": "500",
      "scrollable": ""
    },
    model: {
      value: _vm.isOpen,
      callback: function callback($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "py-2 px-3 d-flex align-center"
  }, [_c('v-text-field', {
    ref: "commandPaletteSearchFiled",
    attrs: {
      "placeholder": "".concat(_vm.$t('common.nouns.search'), " ..."),
      "hide-details": "",
      "solo": "",
      "flat": "",
      "prepend-inner-icon": "mdi-magnify",
      "loading": _vm.isLoading,
      "autofocus": ""
    },
    model: {
      value: _vm.searchPattern,
      callback: function callback($$v) {
        _vm.searchPattern = $$v;
      },
      expression: "searchPattern"
    }
  })], 1), _c('v-divider'), _vm.filteredItems.length ? _c('v-virtual-scroll', {
    attrs: {
      "items": _vm.filteredItems,
      "bench": 5,
      "height": "300",
      "item-height": "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item,
            index = _ref.index;
        return [item.item.type === _vm.BackendResourceEnum.REPORT ? _c('refs-report', {
          key: index,
          attrs: {
            "item": item.item.data
          },
          on: {
            "click": function click($event) {
              return _vm.selectItem(item.item);
            }
          },
          scopedSlots: _vm._u([{
            key: "icon",
            fn: function fn() {
              return [_c('v-list-item-avatar', [_c('tooltip', {
                attrs: {
                  "text": _vm.$t('common.nouns.report')
                }
              }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.RefTypeMap.get(item.item.type).icon) + " ")])], 1)], 1)];
            },
            proxy: true
          }], null, true)
        }) : _vm._e(), item.item.type === _vm.BackendResourceEnum.VEHICLE ? _c('refs-vehicle', {
          key: index,
          attrs: {
            "item": item.item.data
          },
          on: {
            "click": function click($event) {
              return _vm.selectItem(item.item);
            }
          },
          scopedSlots: _vm._u([{
            key: "icon",
            fn: function fn() {
              return [_c('v-list-item-avatar', [_c('tooltip', {
                attrs: {
                  "text": _vm.$t('common.nouns.vehicle')
                }
              }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.RefTypeMap.get(item.item.type).icon) + " ")])], 1)], 1)];
            },
            proxy: true
          }], null, true)
        }) : _vm._e(), item.item.type === _vm.BackendResourceEnum.PARTNER ? _c('refs-partner', {
          key: index,
          attrs: {
            "item": item.item.data
          },
          on: {
            "click": function click($event) {
              return _vm.selectItem(item.item);
            }
          },
          scopedSlots: _vm._u([{
            key: "icon",
            fn: function fn() {
              return [_c('v-list-item-avatar', [_c('tooltip', {
                attrs: {
                  "text": _vm.$t('common.nouns.partner')
                }
              }, [_c('v-icon', [_vm._v(" mdi-handshake-outline ")])], 1)], 1)];
            },
            proxy: true
          }], null, true)
        }) : _vm._e(), item.item.type === _vm.BackendResourceEnum.PERSON ? _c('refs-person', {
          key: index,
          attrs: {
            "item": item.item.data
          },
          on: {
            "click": function click($event) {
              return _vm.selectItem(item.item);
            }
          },
          scopedSlots: _vm._u([{
            key: "icon",
            fn: function fn() {
              return [_c('v-list-item-avatar', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.RefTypeMap.get(item.item.type).icon) + " ")])], 1)];
            },
            proxy: true
          }], null, true)
        }) : _vm._e(), item.item.type === 'link' ? _c('navigation-list-item', {
          key: index,
          attrs: {
            "navigationItem": item.item.data
          },
          on: {
            "click": function click($event) {
              return _vm.selectItem(item.item);
            }
          }
        }) : _vm._e()];
      }
    }], null, false, 684496877)
  }) : _vm.searchPattern ? _c('v-card-text', {
    staticClass: "pt-8 pb-0 text-center",
    attrs: {
      "flat": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "86"
    }
  }, [_vm._v("mdi-text-box-search-outline")]), _c('p', {
    staticClass: "caption overline  pt-2"
  }, [_vm._v(_vm._s(_vm.$t("sign.Sign.error.404")))]), _c('v-divider')], 1) : _vm._e(), !_vm.filteredItems.length ? _c('div', [_c('v-list', [_vm._l(_vm.favLinks, function (searchResult, idx) {
    return _c('navigation-list-item', {
      key: idx,
      attrs: {
        "navigationItem": searchResult
      }
    });
  }), _c('v-list-item', {
    staticClass: "pl-6",
    on: {
      "click": function click($event) {
        return _vm.goToQuickLinks();
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.QuickLinkList.allFunctions")) + " ")])], 1)], 1)], 2)], 1) : _vm._e(), _c('v-card-actions', [_c('small', {
    staticClass: "text-caption"
  }, [_c('span', {
    staticClass: "pr-3"
  }, [_vm._v("Navigieren "), _c('strong', {
    staticClass: "grey lighten-3 pa-1 rounded"
  }, [_vm._v("tab")])]), _c('span', {
    staticClass: "pr-3"
  }, [_vm._v("Öffnen "), _c('strong', {
    staticClass: "grey lighten-3 pa-1 rounded"
  }, [_vm._v("enter")])]), _c('span', {
    staticClass: "pr-3"
  }, [_vm._v("Schließen "), _c('strong', {
    staticClass: "grey lighten-3 pa-1 rounded"
  }, [_vm._v("esc")])])]), _c('v-spacer'), _c('small', {
    staticClass: "text-caption ml-2"
  }, [_c('span', [_vm._v(" Suche "), _c('strong', {
    staticClass: "grey lighten-3 pa-1 rounded"
  }, [_vm._v("⌘ + k")]), _vm._v(" oder "), _c('strong', {
    staticClass: "grey lighten-3 pa-1 rounded"
  }, [_vm._v("strg + k")])])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }