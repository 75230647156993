/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreatePartnerBankingDtoGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivPartnerBankingControllerGetParamsGen,
  MrfiktivPartnerBankingViewModelGen,
  MrfiktivUpdatePartnerBankingDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PartnerBanking<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags partner-banking
   * @name PartnerBankingControllerCreate
   * @summary Creates a banking for a given partner
   * @request POST:/partner/{partnerId}/partner-banking
   * @secure
   * @response `201` `MrfiktivPartnerBankingViewModelGen` PartnerBanking created
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerBankingControllerCreate = (
    partnerId: string,
    data: MrfiktivCreatePartnerBankingDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPartnerBankingViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/partner-banking`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-banking
   * @name PartnerBankingControllerGet
   * @summary Gets all bankings for a given partner
   * @request GET:/partner/{partnerId}/partner-banking
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivPartnerBankingViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerBankingControllerGet = (
    { partnerId, ...query }: MrfiktivPartnerBankingControllerGetParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivPartnerBankingViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/partner-banking`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-banking
   * @name PartnerBankingControllerUpdate
   * @summary Update banking for a given partner
   * @request PUT:/partner/{partnerId}/partner-banking/{id}
   * @secure
   * @response `200` `MrfiktivPartnerBankingViewModelGen` Updated banking document
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerBankingControllerUpdate = (
    partnerId: string,
    id: string,
    data: MrfiktivUpdatePartnerBankingDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPartnerBankingViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/partner-banking/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-banking
   * @name PartnerBankingControllerGetOne
   * @summary Gets a particular banking from a given partner
   * @request GET:/partner/{partnerId}/partner-banking/{id}
   * @secure
   * @response `200` `MrfiktivPartnerBankingViewModelGen` PartnerBanking returned
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerBankingControllerGetOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerBankingViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/partner-banking/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-banking
   * @name PartnerBankingControllerRemove
   * @summary Deletes a banking for a given partner
   * @request DELETE:/partner/{partnerId}/partner-banking/{id}
   * @secure
   * @response `200` `MrfiktivPartnerBankingViewModelGen` PartnerBanking deleted
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerBankingControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerBankingViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/partner-banking/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
