/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreatePersonDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivPersonControllerGetParamsGen,
  MrfiktivPersonViewModelGen,
  MrfiktivUpdatePersonDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Person<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags person
   * @name PersonControllerCreate
   * @summary (Person - CREATE) Create a Person
   * @request POST:/partner/{partnerId}/person
   * @secure
   * @response `201` `MrfiktivPersonViewModelGen` The created person
   */
  personControllerCreate = (partnerId: string, data: MrfiktivCreatePersonDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPersonViewModelGen, any>({
      path: `/partner/${partnerId}/person`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags person
   * @name PersonControllerGet
   * @summary (Person - READ) Get all companies
   * @request GET:/partner/{partnerId}/person
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivPersonViewModelGen)[] })`
   */
  personControllerGet = ({ partnerId, ...query }: MrfiktivPersonControllerGetParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivPersonViewModelGen[] }, any>({
      path: `/partner/${partnerId}/person`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags person
   * @name PersonControllerGetOne
   * @summary (Person - READ) Get a person
   * @request GET:/partner/{partnerId}/person/{personId}
   * @secure
   * @response `200` `MrfiktivPersonViewModelGen` The person
   */
  personControllerGetOne = (partnerId: string, personId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPersonViewModelGen, any>({
      path: `/partner/${partnerId}/person/${personId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags person
   * @name PersonControllerUpdate
   * @summary (Person - UPDATE) Updates a person
   * @request PATCH:/partner/{partnerId}/person/{personId}
   * @secure
   * @response `201` `MrfiktivPersonViewModelGen` The updated person
   */
  personControllerUpdate = (
    partnerId: string,
    personId: string,
    data: MrfiktivUpdatePersonDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPersonViewModelGen, any>({
      path: `/partner/${partnerId}/person/${personId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags person
   * @name PersonControllerDelete
   * @summary (Person - DELETE) Deletes a person
   * @request DELETE:/partner/{partnerId}/person/{personId}
   * @secure
   * @response `200` `MrfiktivPersonViewModelGen` The deleted person
   */
  personControllerDelete = (partnerId: string, personId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPersonViewModelGen, any>({
      path: `/partner/${partnerId}/person/${personId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags person
   * @name PersonControllerGetOneByMail
   * @summary (Person - READ) Get a person
   * @request GET:/partner/{partnerId}/person/{mail}/byMail
   * @secure
   * @response `200` `(MrfiktivPersonViewModelGen)[]` The person
   */
  personControllerGetOneByMail = (partnerId: string, mail: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPersonViewModelGen[], any>({
      path: `/partner/${partnerId}/person/${mail}/byMail`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
