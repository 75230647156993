import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import companyGroupService from "@/services/mrfiktiv/services/companyGroupService";
import {
  MrfiktivCompanyGroupViewModelGen,
  MrfiktivCreateCompanyGroupDtoGen,
  MrfiktivCustomFieldListElementDtoGen,
  MrfiktivUpdateCompanyGroupDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { CompanyGroupDataAccessLayer } from "@/store/modules/access-layers/company-group.access-layer";
import { IProjectConfiguration, ProjectConfiguration } from "./project-configuration.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import Vue from "vue";

@IsFilterable
export class CompanyGroupBase implements MrfiktivCompanyGroupViewModelGen, ICreateDto<ICompanyGroup> {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "id",
    config: {
      itemCallback: () => CompanyGroupDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-company-group"
    }
  })
  id: string;

  partnerId: string;

  configuration: IProjectConfiguration;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.companyGroup.title"
  })
  title: string;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.companyGroup.description"
  })
  description?: string | undefined;

  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  private get customFieldConfigDto(): MrfiktivCustomFieldListElementDtoGen[] {
    return this.configuration.customFieldConfig
      .filter(c => c.customField.id) // filter out deleted custom fields
      .map(c => {
        return {
          configuration: c.configuration,
          customField: c.customField.id
        };
      });
  }

  loading = false;

  /**
   * Construct companyGroup
   */
  constructor(companyGroup?: Partial<ICompanyGroup | MrfiktivCompanyGroupViewModelGen>) {
    this.id = companyGroup?.id ?? "";
    this.partnerId = companyGroup?.partnerId ?? "";
    this.configuration = new ProjectConfiguration(companyGroup?.configuration);
    this.title = companyGroup?.title ?? "";
    this.description = companyGroup?.description ?? "";
    this.timestamp = new Timestamp(companyGroup?.timestamp);
  }

  /**
   * map props from viewmodel to this
   */
  protected map(companyGroup?: MrfiktivCompanyGroupViewModelGen) {
    if (!companyGroup) return;
    this.id = companyGroup?.id ?? "";
    this.partnerId = companyGroup?.partnerId ?? "";
    this.configuration = new ProjectConfiguration(companyGroup?.configuration);
    this.title = companyGroup?.title ?? "";
    this.description = companyGroup?.description ?? "";
    this.timestamp = new Timestamp(companyGroup?.timestamp);
  }

  /**
   * fetch companyGroup
   */
  async fetch(): Promise<this> {
    try {
      this.loading = true;
      const res = await companyGroupService.getOne(this.partnerId, this.id);

      this.map(res);
      CompanyGroupDataAccessLayer.set(this);

      this.loading = false;

      return this;
    } catch (e) {
      Vue.$log.error(e);
      this.loading = false;
      throw e;
    }
  }

  /**
   * create fetch companyGroup
   */
  async create() {
    const data: MrfiktivCreateCompanyGroupDtoGen = {
      title: this.title,
      description: this.description || undefined,
      configuration: {
        isTemplate: this.configuration.isTemplate,
        customFieldConfig: this.customFieldConfigDto,
        views: []
      }
    };
    const res = await companyGroupService.create(this.partnerId, data);

    this.map(res);

    CompanyGroupDataAccessLayer.set(this);

    return this;
  }

  /**
   * delete companyGroup
   */
  async delete() {
    const res = await companyGroupService.delete(this.partnerId, this.id);

    this.map(res);
    CompanyGroupDataAccessLayer.delete(this);
  }

  /**
   * update companyGroup
   * @returns
   */
  async update() {
    const data: MrfiktivUpdateCompanyGroupDtoGen = {
      title: this.title,
      description: this.description,
      configuration: {
        isTemplate: this.configuration.isTemplate,
        customFieldConfig: this.customFieldConfigDto,
        views: this.configuration.views.map(v => v.dto)
      }
    };
    const res = await companyGroupService.update(this.partnerId, this.id, data);
    this.map(res);
    CompanyGroupDataAccessLayer.set(this);

    return this;
  }

  /**
   * update companyGroup via dto
   * @param dto
   * @returns
   */
  async updatePartial(dto: MrfiktivUpdateCompanyGroupDtoGen) {
    const res = await companyGroupService.update(this.partnerId, this.id, dto);

    this.map(res);

    CompanyGroupDataAccessLayer.set(this);

    return this;
  }
}

type ICompanyGroup = CompanyGroupBase;
const CompanyGroup = Filter.createForClass(CompanyGroupBase);

export { CompanyGroup, ICompanyGroup };
