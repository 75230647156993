import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivContractControllerGetParamsGen,
  MrfiktivCreateContractDtoGen,
  MrfiktivUpdateContractDtoGen
} from "../v1/data-contracts";
import { Contract } from "../v1/Contract";

/**
 * Communicate with contract API
 */
class ContractService {
  mrfiktivProxy: Contract;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Contract(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create ancontract
   */
  async create(partnerId: string, data: MrfiktivCreateContractDtoGen) {
    const contract = (await this.mrfiktivProxy.contractControllerCreate(partnerId, data)).data;

    return contract;
  }

  /**
   * Get allcontract
   */
  async get(data: MrfiktivContractControllerGetParamsGen) {
    const Contracts = (await this.mrfiktivProxy.contractControllerGet(data)).data;

    return Contracts;
  }

  /**
   * Get acontract
   */
  async getOne(partnerId: string, contractId: string) {
    const contract = (await this.mrfiktivProxy.contractControllerGetOne(partnerId, contractId)).data;

    return contract;
  }

  /**
   * Update acontract
   */
  async update(partnerId: string, contractId: string, data: MrfiktivUpdateContractDtoGen) {
    const contract = (await this.mrfiktivProxy.contractControllerUpdate(partnerId, contractId, data)).data;

    return contract;
  }

  /**
   * Delete acontract
   */
  async delete(partnerId: string, contractId: string) {
    const contract = (await this.mrfiktivProxy.contractControllerDelete(partnerId, contractId)).data;

    return contract;
  }
}

/**
 * Communicate withcontract API
 */
export default new ContractService(new MrfiktivHttpClientProvider());
