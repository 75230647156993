import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IGroup } from "@/models/group.entity";
import groupService from "@/services/mrfiktiv/services/group-service";
import { MrfiktivGroupControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

export const GroupPageDataProvider = new (class extends AbstractPageDataProvider<
  IGroup,
  MrfiktivGroupControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivGroupControllerGetParamsGen): Promise<IPageViewModel<IGroup>> {
    const res = await groupService.get(query);

    const meta = res.meta;
    const data = (res.data ?? []) as IGroup[];

    return { meta, data };
  }
})();
