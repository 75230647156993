/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import Vue from "vue";
import { ILocalDataAccessLayerWriter } from "./local-data-access-layer.interface";
import { IIndexDbAccessLayer } from "./local-index-db-access-layer.abstract";
import { IPageable } from "./pagable.interface";
import { IPageDataProvider } from "./page-data-provider.interface";
import { IPaginationParams } from "./pagination-params.interface";

/**
 * Page handler that stores paged data provided by the `IPageDataProvider` into a `ILocalDataAccessLayer`
 */
export class PageDataHandler<T extends object, Q extends IPaginationParams> implements IPageable {
  /**
   * the last known id of the handler
   */
  _lastKnownId: string | undefined;

  /**
   * Creates the handler
   * @param data the data layer
   * @param pager the pager providing the backend connection
   */
  constructor(
    private data: ILocalDataAccessLayerWriter<T>,
    private pager: IPageDataProvider<T, Q>,
    private db?: IIndexDbAccessLayer<T>
  ) {
    if (!data) {
      throw new Error(
        "Data layer is required. When implementing the PaginatedBaseStore make sure to initialize the data layer BEFORE the PageDataHandler (the order matters!)"
      );
    }

    if (!pager) {
      throw new Error(
        "Pager is required. When implementing the PaginatedBaseStore make sure to initialize the data layer BEFORE the PageDataHandler (the order matters!)"
      );
    }
  }

  /**
   * @inheritdoc
   */
  get isPageLoading() {
    return this.pager.isPageLoading;
  }

  /**
   * @inheritdoc
   */
  get itemsPerPage() {
    return this.pager.itemsPerPage;
  }

  /**
   * @inheritdoc
   */
  set itemsPerPage(itemsPerPage: number) {
    this.pager.itemsPerPage = itemsPerPage;
  }

  /**
   * @inheritdoc
   */
  get pageSizes() {
    return this.pager.pageSizes;
  }

  /**
   * @inheritdoc
   */
  get currentPage() {
    return this.pager.currentPage;
  }

  /**
   * @inheritdoc
   */
  set currentPage(currentPage: number) {
    this.pager.currentPage = currentPage;
  }

  /**
   * @inheritdoc
   */
  get totalPages() {
    return this.pager.totalPages;
  }

  /**
   * @inheritdoc
   */
  get totalItems() {
    return this.pager.totalItems;
  }

  /**
   * The last known id of the page. Is the `startId` in `Q`, to fetch a new page from a known last id.
   */
  get lastId() {
    return this._lastKnownId;
  }

  /**
   * Executes and sets the data on the data layer using the pager
   * @param q query send to the pager
   * @returns the received T
   */
  private async execute(q: Q): Promise<T[] | undefined> {
    const res = await this.pager.execute({
      ...q
    });

    res?.forEach(v => this.data.set(v));

    try {
      if (res && this.db) {
        for (const r of res) {
          await this.db.set(r);
        }
      }
    } catch (error) {
      Vue.$log.error(error);
    }

    if (res?.length) {
      this._lastKnownId = this.data.getIdentifier(res[res.length - 1]);
    }

    return res;
  }

  /**
   * Gets a pager by `pageNumber`
   * @param query the query to execute on tha pager, requiring the page number to fetch
   */
  async fetchPage(query: Q): Promise<T[] | undefined> {
    return await this.execute(query);
  }

  /**
   * Loads the first page and sets current page to 1
   */
  async fetchFirstPage(query: Q): Promise<T[] | undefined> {
    return await this.fetchPage({ ...query, currentPage: 1 });
  }

  /**
   * Loads the page that follows the current page by last known id (if known) that the pager is pointing to.
   *
   * Use last known id to avoid situations where documents are not returned by backend
   * If there are the documents 0, 1, 2, 3, 4, 5, 6 in the backend and we fetch (page1, size 3) the backend will return documents 0, 1, 2
   * if we delete document 2 and we fetch (page 2, size 3), we would get documents 4, 5, 6. by fetching (startId 2, size 3) we get 3, 4, 5
   */
  async fetchNextPage(query: Q): Promise<T[] | undefined> {
    if (this.lastId) {
      return await this.fetchPage({ ...query, startId: this.lastId });
    }

    return await this.fetchPage({ ...query, currentPage: this.currentPage + 1 });
  }
}
