import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { PartnerBanking, IPartnerBanking } from "@/models/partner-banking.entity";

export const PartnerBankingDataAccessLayer = new (class PartnerBankingDataAccessLayer extends AbstractLocalDataAccessLayer<
  IPartnerBanking
> {
  getIdentifier(entity: IPartnerBanking): string {
    return entity.id;
  }

  protected mapEntity(entity: IPartnerBanking): IPartnerBanking {
    return new PartnerBanking(entity);
  }
})();
