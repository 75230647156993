import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IPartnerImage } from "@/models/partner-image.entity";
import partnerImageService from "@/services/mrfiktiv/services/partnerImageService";
import { MrfiktivReportControllerFindAllParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

export const PartnerImagePageDataProvider = new (class extends AbstractPageDataProvider<
  IPartnerImage,
  MrfiktivReportControllerFindAllParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivReportControllerFindAllParamsGen): Promise<IPageViewModel<IPartnerImage>> {
    const res = await partnerImageService.get(query);

    return res as IPageViewModel<IPartnerImage>;
  }
})();
