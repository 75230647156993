import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { MrfiktivAttendeesDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CompanyInformation } from "./company-information.entity";

@IsFilterable
class AttendeeBase extends CompanyInformation implements MrfiktivAttendeesDtoGen {
  /**
   * @inheritdoc
   */
  @FilterConfig({ displayName: "objects.attendee.firstName", type: FilterTypes.STRING })
  firstName: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({ displayName: "objects.attendee.lastName", type: FilterTypes.STRING })
  lastName?: string;

  constructor(contact?: Partial<MrfiktivAttendeesDtoGen>) {
    super(contact);
    this.firstName = contact?.firstName ?? "";
    this.lastName = contact?.lastName ?? "";
  }
}

type IAttendee = AttendeeBase;
const Attendee = Filter.createForClass(AttendeeBase);

export { Attendee, IAttendee };
