import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { ContractTypeEnum } from "@/lib/enum/contract-type.enum";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { ICustomViewableEntity } from "@/lib/interfaces/custom-viewable-entity.interface";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { RulesEnum } from "@/lib/rules/rules.map";
import { ContractGoToHelper } from "@/lib/utility/contract.go-to-helper";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { formatYearsMonthDay } from "@/lib/utility/date-helper";
import { GroupGoToHelper } from "@/lib/utility/group.go-to-helper";
import { $t } from "@/lib/utility/t";
import contractService from "@/services/mrfiktiv/services/contract-service";
import { MrfiktivContractViewModelGen, MrfiktivUpdateContractDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { ContractDataAccessLayer } from "@/store/modules/access-layers/contract.access-layer";
import { GroupDataAccessLayer } from "@/store/modules/access-layers/group.access-layer";
import { SignDocumentDataAccessLayer } from "@/store/modules/access-layers/sign-document.access-layer";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import VueRouter from "vue-router";
import { ActivityLog } from "./activity-log.entity";
import { ClientReference } from "./client-reference.entity";
import { CustomFieldValue, ICustomFieldValue } from "./custom-field-value.entity";
import { ProviderReference } from "./provider-reference.entity";
import { IReference, Reference } from "./reference.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { UpdateDto } from "@/lib/utility/data/update-dto.interface";

@IsFormable
@IsFilterable
class ContractBase
  implements
    IEntity<IContract, MrfiktivUpdateContractDtoGen>,
    ICustomViewableEntity<IContract, MrfiktivUpdateContractDtoGen>,
    Omit<MrfiktivContractViewModelGen, "values">,
    UpdateDto<IContract> {
  /** Id of the contract object */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.contract.id"
  })
  id: string;

  /** The partnerId of the contract */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.contract.partnerId"
  })
  partnerId: string;

  @FilterConfig({
    displayName: "objects.company.assignees",
    type: FilterTypes.OBJECT_ID,
    width: "120",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  assignees?: string[];

  /** The creator */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.contract.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: (item: any) => {
        return { item };
      },
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId: string;

  /** Title of the contract object */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.contract.title", "common.nouns.general"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.contract.title"
    },
    rules: [],
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.contract.title"
  })
  title?: string;

  /** Internal description of the contract object */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.contract.description"
  })
  description?: string;

  /** the type of contract */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.contract.type", "common.nouns.general"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      items: Object.values(ContractTypeEnum).map(v => {
        return {
          text: $t(`enums.ContractTypeEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value",
      label: "objects.contract.type",
      disabled: true,
      style: "display: none"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  @FilterConfig({
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(ContractTypeEnum).map(v => {
        return {
          text: `enums.ContractTypeEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    displayName: "objects.contract.type"
  })
  type: ContractTypeEnum;

  /** Start Date in format for text-field. yyyy-mm-dd */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.contract.startDate", "common.nouns.general"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "date",
      label: "objects.contract.startDate"
    },
    rules: [],
    clearable: true
  })
  get startDateFormable(): string {
    if (!this.startDate) {
      return "";
    }
    return formatYearsMonthDay(new Date(this.startDate));
  }
  set startDateFormable(date: string) {
    if (date) {
      this.startDate = new Date(date).toISOString();
    }

    this.startDate = date;
  }

  @FilterConfig({
    type: FilterTypes.DATE,
    displayName: "objects.contract.startDate"
  })
  startDate?: string | undefined;

  /** End Date */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.contract.endDate", "common.nouns.general"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "date",
      label: "objects.contract.endDate"
    },
    rules: [],
    clearable: true
  })
  get endDateFormable(): string {
    if (!this.endDate) {
      return "";
    }
    return formatYearsMonthDay(new Date(this.endDate));
  }
  set endDateFormable(date: string) {
    if (date) {
      this.endDate = new Date(date).toISOString();
    }

    this.endDate = date;
  }

  @FilterConfig({
    type: FilterTypes.DATE,
    displayName: "objects.contract.endDate"
  })
  endDate?: string | undefined;

  /** providerIds */
  @FormConfig({
    category: "common.nouns.parties",
    searchKeywords: ["objects.contract.providerIds", "common.nouns.parties"],
    type: DetailFormComponentsEnum.REFS_SELECT,
    rules: [],
    clearable: false,
    props: {
      label: "objects.contract.providerIds",
      getPartnerId: () => PartnerModule.partner.id,
      small: true,
      categories: [BackendResourceEnum.COMPANY, BackendResourceEnum.PERSON]
    }
  })
  @FilterConfig({
    type: ProviderReference
  })
  providerIds: IReference[];

  /** clientIds */
  @FormConfig({
    category: "common.nouns.parties",
    searchKeywords: ["objects.contract.clientIds", "common.nouns.parties"],
    type: DetailFormComponentsEnum.REFS_SELECT,
    rules: [],
    clearable: false,
    props: {
      label: "objects.contract.clientIds",
      getPartnerId: () => PartnerModule.partner.id,
      small: true,
      categories: [BackendResourceEnum.COMPANY, BackendResourceEnum.PERSON]
    }
  })
  @FilterConfig({
    type: ClientReference
  })
  clientIds: IReference[];

  /** contract group */
  @FormConfig({
    category: "objects.contract.groupId",
    searchKeywords: ["objects.contract.groupId", "objects.contract.groupId"],
    type: DetailFormComponentsEnum.SELECT_ENTITY,
    props: {
      refType: BackendResourceEnum.GROUP,
      label: "objects.contract.groupId"
    },
    rules: [],
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.contract.groupId",
    config: {
      itemCallback: () => GroupDataAccessLayer.entities?.filter(e => e.type === BackendResourceEnum.CONTRACT) ?? [],
      mapItemToComponent: (item: any) => ({ item }),
      itemValue: "id",
      component: "refs-group"
    }
  })
  groupId?: string;

  /** values of the custom fields */
  @FilterConfig({ type: CustomFieldValue })
  values: ICustomFieldValue[] = [];

  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.contract.timestamp.created", "common.nouns.general"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.contract.timestamp.created",
      disabled: true,
      style: "display: none"
    },
    rules: []
  })
  get timestampCreatedReadable() {
    return this.timestamp.createdReadable;
  }
  set timestampCreatedReadable(_: string) {
    // do nothing
  }

  /** documentIds */
  @FormConfig({
    category: "common.nouns.attachments",
    searchKeywords: ["objects.contract.documentIds", "common.nouns.attachments"],
    type: DetailFormComponentsEnum.SELECT_ENTITIES,
    props: {
      refType: BackendResourceEnum.DOCUMENT,
      label: "objects.contract.documentIds"
    },
    rules: [],
    clearable: false
  })
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.contract.documentIds",
    config: {
      itemCallback: () => SignDocumentDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-document"
    }
  })
  documentIds: string[];

  /** refs */
  @FilterConfig({
    type: Reference
  })
  refs: IReference[];

  /** timestamp */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  isUpdateable = true;

  loading = false;

  get projectId() {
    return this.groupId;
  }

  get titleReadable() {
    if (!this.title) {
      return $t("contract.noTitle");
    }

    return this.title;
  }

  constructor(data?: Partial<MrfiktivContractViewModelGen> | IContract) {
    this.id = data?.id ?? "";
    this.partnerId = data?.partnerId ?? "";
    this.userId = data?.userId ?? "";
    this.title = data?.title ?? "";
    this.description = data?.description ?? "";
    this.groupId = data?.groupId;
    this.values = CustomFieldValue.buildCustomFieldValues(data?.values || []);
    this.type = data?.type as ContractTypeEnum;
    this.startDate = data?.startDate;
    this.endDate = data?.endDate;
    this.providerIds = Reference.filterDuplicates((data?.providerIds as IReference[])?.map(ref => new Reference(ref)));
    this.clientIds = Reference.filterDuplicates((data?.clientIds as IReference[])?.map(ref => new Reference(ref)));
    this.documentIds = data?.documentIds ?? [];
    this.refs = Reference.filterDuplicates((data?.refs ?? []).map(ref => new Reference(ref)));
    this.assignees = data?.assignees;
    this.timestamp = new Timestamp(data?.timestamp);
  }

  map(data: MrfiktivContractViewModelGen): void {
    this.id = data?.id ?? "";
    this.partnerId = data?.partnerId ?? "";
    this.userId = data?.userId ?? "";
    this.title = data?.title ?? "";
    this.description = data?.description ?? "";
    this.groupId = data?.groupId;
    this.values = CustomFieldValue.buildCustomFieldValues(data?.values || []);
    this.type = data?.type as ContractTypeEnum;
    this.startDate = data?.startDate;
    this.endDate = data?.endDate;
    this.providerIds = Reference.filterDuplicates((data?.providerIds as IReference[])?.map(ref => new Reference(ref)));
    this.clientIds = Reference.filterDuplicates((data?.clientIds as IReference[])?.map(ref => new Reference(ref)));
    this.documentIds = data?.documentIds ?? [];
    this.refs = Reference.filterDuplicates((data?.refs ?? []).map(ref => new Reference(ref)));
    this.assignees = data?.assignees;
    this.timestamp = new Timestamp(data?.timestamp);
  }

  async create(): Promise<this> {
    const res = await contractService.create(this.partnerId, {
      title: this.title || undefined,
      description: this.description || undefined,
      groupId: this.groupId || undefined,
      values: CustomFieldValue.buildCustomFieldValuesDto(this.values || []),
      type: this.type,
      clientIds: Reference.filterDuplicates((this.clientIds ?? []) as IReference[]),
      providerIds: Reference.filterDuplicates((this.providerIds ?? []) as IReference[]),
      documentIds: this.documentIds,
      startDate: this.startDate,
      endDate: this.endDate,
      assignees: this.assignees,
      refs: Reference.filterDuplicates((this.refs ?? []) as IReference[])
    });

    this.map(res);
    ContractDataAccessLayer.set(this);

    return this;
  }

  async fetch(): Promise<this> {
    this.loading = true;
    try {
      const res = await contractService.getOne(this.partnerId, this.id);
      this.map(res);
      ContractDataAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      ContractDataAccessLayer.delete(this);
      throw e;
    }
    this.loading = false;

    return this;
  }

  async updatePartial(dto: Partial<MrfiktivUpdateContractDtoGen>): Promise<this> {
    if (dto.values) {
      dto.values = CustomFieldValue.buildCustomFieldValuesDto((dto.values as ICustomFieldValue[]) || []);
    }

    const res = await contractService.update(this.partnerId, this.id, dto);

    this.map(res);
    ContractDataAccessLayer.set(this);

    return this;
  }

  async update(): Promise<this> {
    this.loading = true;
    try {
      await this.updatePartial({
        title: this.title,
        description: this.description,
        values: CustomFieldValue.buildCustomFieldValuesDto(this.values || []),
        clientIds: this.clientIds,
        providerIds: this.providerIds,
        documentIds: this.documentIds,
        startDate: this.startDate ? new Date(this.startDate).toISOString() : this.startDate,
        endDate: this.endDate ? new Date(this.endDate).toISOString() : this.startDate,
        groupId: this.groupId,
        assignees: this.assignees,
        refs: Reference.filterDuplicates((this.refs ?? []) as IReference[])
      });
    } catch (e) {
      this.loading = false;
      throw e;
    }
    this.loading = false;

    return this;
  }

  async delete(): Promise<void> {
    const res = await contractService.delete(this.partnerId, this.id);

    this.map(res);
    ContractDataAccessLayer.delete(this);
  }

  goTo(router: VueRouter) {
    return {
      customView: (newTab = false) => {
        if (this.groupId)
          new GroupGoToHelper(router).goToGroupTypeCustomView({
            partnerId: this.partnerId,
            type: BackendResourceEnum.CONTRACT,
            groupId: this.groupId,
            viewId: "0",
            newTab
          });
      },
      table: (newTab = false) =>
        new ContractGoToHelper(router).goToContractTable({
          partnerId: this.partnerId,
          newTab
        }),
      detail: (newTab = false) =>
        new ContractGoToHelper(router).goToContractDetail({
          partnerId: this.partnerId,
          contractId: this.id,
          newTab
        }),
      form: (newTab = false) =>
        new ContractGoToHelper(router).goToContractDetailForm({
          partnerId: this.partnerId,
          contractId: this.id,
          newTab
        })
    };
  }

  /**
   * Create an activity log for assignee changes
   * @param newAssignees The ids of the new assignees of the ticket
   * @param activityType Whether the assignee was added or removed
   */
  async createAssigneeActivity(
    activityType: ActivityTypeEnum.CREATE_ASSIGNEE | ActivityTypeEnum.DELETE_ASSIGNEE,
    newAssignees?: string[]
  ) {
    if (!newAssignees?.length) return;

    await new ActivityLog().createAssigneeActivity({
      partnerId: this.partnerId,
      source: {
        refType: ResourceEnum.CONTRACT,
        refId: this.id
      },
      newAssignees,
      activityType
    });
  }
}

type IContract = ContractBase;
const Contract = Form.createForClass(Filter.createForClass(ContractBase));

export { Contract, IContract };
