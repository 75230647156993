import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { $t } from "@/lib/utility/t";
import { toast } from "@/lib/utility/toast";
import { MrfiktivCompanyAddressViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { RulesEnum } from "@/lib/rules/rules.map";
import { AddressWithGeoPartialBase } from "./address-partial.entity";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";

@IsFormable
@IsFilterable
class CompanyAddressBase extends AddressWithGeoPartialBase implements Partial<MrfiktivCompanyAddressViewModelGen> {
  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["objects.company.addresses.isBilling", "common.nouns.address"],
    type: DetailFormComponentsEnum.CHECK_BOX,
    props: {
      type: "boolean",
      customField: {
        label: $t("objects.company.addresses.isBilling")
      },
      style: "width: 100%;"
    }
  })
  @FilterConfig({
    displayName: "objects.company.addresses.isBilling",
    type: FilterTypes.ENUM,
    config: {
      items: [
        {
          text: $t("yes"),
          value: true
        },
        {
          text: $t("no"),
          value: false
        }
      ],
      itemValue: "value"
    }
  })
  isBilling?: boolean;

  constructor(address?: Partial<MrfiktivCompanyAddressViewModelGen>) {
    super(address);

    this.isBilling = address?.isBilling ?? false;
  }

  copyToClipboard(): void {
    const item = [];
    if (this.street) {
      item.push(this.street);
    }
    if (this.zip) {
      item.push(this.zip);
    }
    if (this.city) {
      item.push(this.city);
    }
    if (this.countryCode) {
      item.push(this.countryCode);
    }
    if (this.isBilling) {
      item.push($t("objects.company.addresses.isBilling"));
    }

    navigator.clipboard.writeText(item.join(" "));
    toast.info($t("components.partner.PartnerReportInitializeCard.linkCopied"));
  }

  toString() {
    return `${this.street}, ${this.zip} ${this.city}`;
  }
}
type ICompanyAddress = CompanyAddressBase;
const CompanyAddress = Form.createForClass(Filter.createForClass(CompanyAddressBase));

export { CompanyAddress, ICompanyAddress };

/**
 * Like CompanyAddressBase but with required fields
 */
@IsFormable
@IsFilterable
class CompanyAddressDeliveryBase implements MrfiktivCompanyAddressViewModelGen {
  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.deliveryAddress",
    searchKeywords: ["objects.address.street", "common.nouns.address", "common.nouns.deliveryAddress"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.address.street"
    },
    rules: [],
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.street",
    type: FilterTypes.STRING
  })
  street?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.deliveryAddress",
    searchKeywords: ["objects.address.zip", "common.nouns.address", "common.nouns.deliveryAddress"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.address.zip"
    },
    rules: [],
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.zip",
    type: FilterTypes.STRING
  })
  zip?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.deliveryAddress",
    searchKeywords: ["objects.address.city", "common.nouns.address", "common.nouns.deliveryAddress"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.address.city"
    },

    rules: [],
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.city",
    type: FilterTypes.STRING
  })
  city?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.deliveryAddress",
    searchKeywords: ["objects.address.state", "common.nouns.address", "common.nouns.deliveryAddress"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.address.state"
    },
    rules: [],
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.state",
    type: FilterTypes.STRING
  })
  state?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.deliveryAddress",
    searchKeywords: ["objects.address.countryCode", "common.nouns.address", "common.nouns.deliveryAddress"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      required: false,
      label: "objects.address.countryCode",
      items: Object.values(CountryCodeEnum).map(v => {
        return {
          text: $t(`CountryCodeEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    rules: [],
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.address.countryCode",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(CountryCodeEnum).map(v => {
        return {
          text: `CountryCodeEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  countryCode?: string;

  geo?: {
    lat: number;
    lng: number;
  };

  @FilterConfig({
    displayName: "objects.company.addresses.isBilling",
    type: FilterTypes.ENUM,
    config: {
      items: [
        {
          text: $t("yes"),
          value: true
        },
        {
          text: $t("no"),
          value: false
        }
      ],
      itemValue: "value"
    }
  })
  isBilling?: boolean;

  constructor(address?: Partial<MrfiktivCompanyAddressViewModelGen>) {
    this.city = address?.city ?? "";
    this.countryCode = address?.countryCode ?? CountryCodeEnum.germany;
    this.state = address?.state ?? "";
    this.street = address?.street ?? "";
    this.zip = address?.zip ?? "";
    this.geo = address?.geo ?? { lat: 0, lng: 0 };
    this.isBilling = false;
  }

  copyToClipboard(): void {
    const item = [];
    if (this.street) {
      item.push(this.street);
    }
    if (this.zip) {
      item.push(this.zip);
    }
    if (this.city) {
      item.push(this.city);
    }
    if (this.countryCode) {
      item.push(this.countryCode);
    }
    if (this.isBilling) {
      item.push($t("objects.company.addresses.isBilling"));
    }

    navigator.clipboard.writeText(item.join(" "));
    toast.info($t("components.partner.PartnerReportInitializeCard.linkCopied"));
  }
}
type ICompanyAddressDelivery = CompanyAddressDeliveryBase;
const CompanyAddressDelivery = Form.createForClass(Filter.createForClass(CompanyAddressDeliveryBase));

export { CompanyAddressDelivery, ICompanyAddressDelivery };

/**
 * Like CompanyAddressBase but with required fields
 */
@IsFormable
@IsFilterable
class CompanyAddressBillingBase implements MrfiktivCompanyAddressViewModelGen {
  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.billingAddress",
    searchKeywords: ["objects.address.street", "common.nouns.address", "common.nouns.billingAddress"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.address.street"
    },
    rules: [RulesEnum.REQUIRED_RULE],
    clearable: false
  })
  @FilterConfig({
    displayName: "objects.address.street",
    type: FilterTypes.STRING
  })
  street: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.billingAddress",
    searchKeywords: ["objects.address.zip", "common.nouns.address", "common.nouns.billingAddress"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.address.zip"
    },
    rules: [RulesEnum.REQUIRED_RULE],
    clearable: false
  })
  @FilterConfig({
    displayName: "objects.address.zip",
    type: FilterTypes.STRING
  })
  zip: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.billingAddress",
    searchKeywords: ["objects.address.city", "common.nouns.address", "common.nouns.billingAddress"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.address.city"
    },

    rules: [RulesEnum.REQUIRED_RULE],
    clearable: false
  })
  @FilterConfig({
    displayName: "objects.address.city",
    type: FilterTypes.STRING
  })
  city: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.billingAddress",
    searchKeywords: ["objects.address.state", "common.nouns.address", "common.nouns.billingAddress"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.address.state"
    },

    rules: [RulesEnum.REQUIRED_RULE],
    clearable: false
  })
  @FilterConfig({
    displayName: "objects.address.state",
    type: FilterTypes.STRING
  })
  state: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.billingAddress",
    searchKeywords: ["objects.address.countryCode", "common.nouns.address", "common.nouns.billingAddress"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      required: false,
      label: "objects.address.countryCode",
      items: Object.values(CountryCodeEnum).map(v => {
        return {
          text: $t(`CountryCodeEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    },

    rules: [RulesEnum.REQUIRED_RULE],
    clearable: false
  })
  @FilterConfig({
    displayName: "objects.address.countryCode",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(CountryCodeEnum).map(v => {
        return {
          text: `CountryCodeEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  countryCode: string;

  geo: {
    lat: number;
    lng: number;
  };

  @FilterConfig({
    displayName: "objects.company.addresses.isBilling",
    type: FilterTypes.ENUM,
    config: {
      items: [
        {
          text: $t("yes"),
          value: true
        },
        {
          text: $t("no"),
          value: false
        }
      ],
      itemValue: "value"
    }
  })
  isBilling: boolean;

  constructor(address?: Partial<MrfiktivCompanyAddressViewModelGen>) {
    this.city = address?.city ?? "";
    this.countryCode = address?.countryCode ?? CountryCodeEnum.germany;
    this.state = address?.state ?? "";
    this.street = address?.street ?? "";
    this.zip = address?.zip ?? "";
    this.geo = address?.geo ?? { lat: 0, lng: 0 };
    this.isBilling = true;
  }
}
type ICompanyAddressBilling = CompanyAddressBillingBase;
const CompanyAddressBilling = Form.createForClass(Filter.createForClass(CompanyAddressBillingBase));

export { CompanyAddressBilling, ICompanyAddressBilling };
