import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { Filter, IsFilterable, FilterConfig } from "@/lib/filterable";
import {
  ThgBillingInfoGen,
  ThgMeterReadingGen,
  ThgPromotionConfigurationGen,
  ThgThgMeterReadingViewModelGen
} from "@/services/thg/v1/data-contracts";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { TimestampDocument, ITimestampDocument } from "./timestamp.entity";
import meterReadingAdminService from "@/services/thg/services/meterReadingAdminService";
import { ThgMeterReadingDataAccessLayer } from "@/store/modules/access-layers/thg-meter-reading.access-layer";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { PayoutOptionEnum } from "@/views/thg/enum/payout-option.enum";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";

@IsFilterable
class ThgMeterReadingBase implements IEntity<ThgThgMeterReadingViewModelGen> {
  @FilterConfig({
    type: PageFilterTypes.OBJECT_ID,
    displayName: "objects.thgMeterReading.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: (item: any) => {
        return { item };
      },
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId: string;

  @FilterConfig({
    type: PageFilterTypes.OBJECT_ID,
    displayName: "objects.thgMeterReading.partnerId"
  })
  partnerId: string;

  meterReading: ThgMeterReadingGen;

  @FilterConfig({
    type: PageFilterTypes.ENUM,
    displayName: "objects.thgMeterReading.status",
    config: { items: Object.values(ThgStatusEnum) }
  })
  status: ThgStatusEnum;

  @FilterConfig({
    type: PageFilterTypes.ENUM,
    displayName: "objects.thgMeterReading.impactType",
    config: { items: Object.values(ImpactTypeEnum) }
  })
  impactType: ImpactTypeEnum;

  @FilterConfig({
    type: PageFilterTypes.NUMBER,
    displayName: "objects.thgMeterReading.impactFactor"
  })
  impactFactor: number;

  payoutOption: PayoutOptionEnum = PayoutOptionEnum.REGULAR;

  @FilterConfig({
    type: TimestampDocument
  })
  timestamp: ITimestampDocument;

  @FilterConfig({
    type: PageFilterTypes.STRING,
    displayName: "objects.thgMeterReading.code"
  })
  code?: string | undefined;

  @FilterConfig({
    type: PageFilterTypes.STRING,
    displayName: "objects.thgMeterReading.campaign"
  })
  campaign: string;

  @FilterConfig({
    type: PageFilterTypes.NUMBER,
    displayName: "objects.thgMeterReading.year"
  })
  year: number;
  payoutConfiguration: any;
  purchasingPriceId?: string | undefined;
  promotionConfiguration?: ThgPromotionConfigurationGen | undefined;
  promotionId?: string | undefined;

  @FilterConfig({
    type: PageFilterTypes.BOOLEAN,
    displayName: "objects.thgMeterReading.isBillingInformationComplete"
  })
  isBillingInformationComplete: boolean;

  @FilterConfig({
    displayName: "objects.thgMeterReading.countryCode",
    type: PageFilterTypes.ENUM,
    config: {
      items: Object.values(CountryCodeEnum).map(v => {
        return {
          text: `CountryCodeEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  countryCode?: CountryCodeEnum | undefined;
  billings?: ThgBillingInfoGen[] | undefined;
  externalId?: string | undefined;

  id: string;

  @FilterConfig({
    type: PageFilterTypes.OBJECT_ID,
    displayName: "objects.thgMeterReading.id"
  })
  get _id() {
    return this.id;
  }

  constructor(thg?: Partial<IThgMeterReading | ThgThgMeterReadingViewModelGen>) {
    this.billings = thg?.billings ?? [];
    this.code = thg?.code;
    this.countryCode = thg?.countryCode as CountryCodeEnum;
    this.impactFactor = thg?.impactFactor ?? 0;
    this.impactType = (thg?.impactType as ImpactTypeEnum) ?? "";
    this.payoutOption = thg?.payoutOption as PayoutOptionEnum;
    this.meterReading = {
      chargingStationId: thg?.meterReading?.chargingStationId ?? "",
      startDate: thg?.meterReading?.startDate ?? "",
      endDate: thg?.meterReading?.endDate ?? "",
      amountInKwh: thg?.meterReading?.amountInKwh ?? 0,
      proof: thg?.meterReading?.proof ?? []
    };
    this.payoutConfiguration = thg?.payoutConfiguration;
    this.promotionConfiguration = thg?.promotionConfiguration;
    this.status = (thg?.status as ThgStatusEnum) ?? "";
    this.timestamp = new TimestampDocument(thg?.timestamp);
    this.year = thg?.year ?? 0;
    this.id = thg?.id ?? "";
    this.campaign = thg?.campaign ?? "";
    this.isBillingInformationComplete = thg?.isBillingInformationComplete ?? false;
    this.partnerId = thg?.partnerId ?? "";
    this.promotionId = thg?.promotionId;
    this.purchasingPriceId = thg?.purchasingPriceId;
    this.userId = thg?.userId ?? "";
  }

  map(thg?: Partial<IThgMeterReading | ThgThgMeterReadingViewModelGen>) {
    this.billings = thg?.billings ?? [];
    this.code = thg?.code;
    this.countryCode = thg?.countryCode as CountryCodeEnum;
    this.impactFactor = thg?.impactFactor ?? 0;
    this.impactType = (thg?.impactType as ImpactTypeEnum) ?? "";
    this.payoutOption = thg?.payoutOption as PayoutOptionEnum;
    this.meterReading = {
      chargingStationId: thg?.meterReading?.chargingStationId ?? "",
      startDate: thg?.meterReading?.startDate ?? "",
      endDate: thg?.meterReading?.endDate ?? "",
      amountInKwh: thg?.meterReading?.amountInKwh ?? 0,
      proof: thg?.meterReading?.proof ?? []
    };
    this.payoutConfiguration = thg?.payoutConfiguration;
    this.promotionConfiguration = thg?.promotionConfiguration;
    this.status = (thg?.status as ThgStatusEnum) ?? "";
    this.timestamp = new TimestampDocument(thg?.timestamp);
    this.year = thg?.year ?? 0;
    this.id = thg?.id ?? "";
    this.campaign = thg?.campaign ?? "";
    this.isBillingInformationComplete = thg?.isBillingInformationComplete ?? false;
    this.partnerId = thg?.partnerId ?? "";
    this.promotionId = thg?.promotionId;
    this.purchasingPriceId = thg?.purchasingPriceId;
    this.userId = thg?.userId ?? "";
  }

  async fetch(): Promise<this> {
    const selectedThg = await meterReadingAdminService.get(this.id);
    this.map(selectedThg);
    ThgMeterReadingDataAccessLayer.set(this);
    return this;
  }
}

type IThgMeterReading = ThgMeterReadingBase;
const ThgMeterReading = Filter.createForClass(ThgMeterReadingBase);

export { IThgMeterReading, ThgMeterReading };
