import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivGroupControllerGetParamsGen,
  MrfiktivCreateGroupDtoGen,
  MrfiktivUpdateGroupDtoGen
} from "../v1/data-contracts";
import { Group } from "../v1/Group";

/**
 * Communicate withgroup API
 */
class GroupService {
  mrfiktivProxy: Group;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Group(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create angroup
   */
  async create(partnerId: string, data: MrfiktivCreateGroupDtoGen) {
    const group = (await this.mrfiktivProxy.groupControllerCreate(partnerId, data)).data;

    return group;
  }

  /**
   * Get allgroup
   */
  async get(data: MrfiktivGroupControllerGetParamsGen) {
    const Groups = (await this.mrfiktivProxy.groupControllerGet(data)).data;

    return Groups;
  }

  /**
   * Get agroup
   */
  async getOne(partnerId: string, GroupId: string) {
    const group = (await this.mrfiktivProxy.groupControllerGetOne(partnerId, GroupId)).data;

    return group;
  }

  /**
   * Update agroup
   */
  async update(partnerId: string, GroupId: string, data: MrfiktivUpdateGroupDtoGen) {
    const group = (await this.mrfiktivProxy.groupControllerUpdate(partnerId, GroupId, data)).data;

    return group;
  }

  /**
   * Delete agroup
   */
  async delete(partnerId: string, GroupId: string) {
    const group = (await this.mrfiktivProxy.groupControllerDelete(partnerId, GroupId)).data;

    return group;
  }
}

/**
 * Communicate withgroup API
 */
export default new GroupService(new MrfiktivHttpClientProvider());
