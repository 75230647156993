/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BookingBookingControllerDeleteOneParamsGen,
  BookingBookingControllerFindAllByPartnerIdParamsGen,
  BookingBookingControllerUpdateParamsGen,
  BookingBookingDetailViewModelGen,
  BookingBookingViewModelGen,
  BookingCreateBookingDtoGen,
  BookingExceptionViewmodelGen,
  BookingPageViewModelGen,
  BookingUpdateBookingDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Booking<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags booking
   * @name BookingControllerFindAllByPartnerId
   * @summary Gets bookings for a partner
   * @request GET:/partner/{partnerId}/booking
   * @secure
   * @response `200` `(BookingPageViewModelGen & { data?: (BookingBookingViewModelGen)[] })`
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  bookingControllerFindAllByPartnerId = (
    { partnerId, ...query }: BookingBookingControllerFindAllByPartnerIdParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<BookingPageViewModelGen & { data?: BookingBookingViewModelGen[] }, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/booking`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags booking
   * @name BookingControllerFindOneByPartnerId
   * @summary Gets a particular booking from a given partner
   * @request GET:/partner/{partnerId}/booking/{id}
   * @secure
   * @response `200` `BookingBookingDetailViewModelGen` Booking returned
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  bookingControllerFindOneByPartnerId = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<BookingBookingDetailViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/booking/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags booking
   * @name BookingControllerDeleteOne
   * @summary Deletes a booking from a given partner
   * @request DELETE:/partner/{partnerId}/booking/{id}
   * @secure
   * @response `200` `BookingBookingViewModelGen` Booking returned
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  bookingControllerDeleteOne = (
    { partnerId, id, ...query }: BookingBookingControllerDeleteOneParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<BookingBookingViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/booking/${id}`,
      method: "DELETE",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags booking
   * @name BookingControllerUpdate
   * @summary Partially updates a booking from a given partner
   * @request PATCH:/partner/{partnerId}/booking/{id}
   * @secure
   * @response `200` `BookingBookingViewModelGen` Booking returned
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  bookingControllerUpdate = (
    { partnerId, id, ...query }: BookingBookingControllerUpdateParamsGen,
    data: BookingUpdateBookingDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<BookingBookingViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/booking/${id}`,
      method: "PATCH",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags booking
   * @name ResourceBookingControllerCreate
   * @summary Creates a booking on a given resource for a partner
   * @request POST:/partner/{partnerId}/resource/{resourceId}/booking
   * @secure
   * @response `201` `BookingBookingViewModelGen` Booking created
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  resourceBookingControllerCreate = (
    partnerId: string,
    resourceId: string,
    data: BookingCreateBookingDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<BookingBookingViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/resource/${resourceId}/booking`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
