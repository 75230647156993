import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { RulesEnum } from "@/lib/rules/rules.map";
import { MrfiktivBillingProfileViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";

@IsFilterable
@IsFormable
class BillingProfileBase implements MrfiktivBillingProfileViewModelGen {
  @FormConfig({
    category: "partnerBanking.partnerBanking",
    searchKeywords: ["objects.person.billingProfile.note", "partnerBanking.partnerBanking"],
    type: DetailFormComponentsEnum.TEXT_AREA,
    props: {
      label: "objects.person.billingProfile.note"
    }
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.person.billingProfile.note"
  })
  note?: string | undefined;

  @FormConfig({
    category: "partnerBanking.partnerBanking",
    searchKeywords: ["objects.person.billingProfile.paymentTerm", "partnerBanking.partnerBanking"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.person.billingProfile.paymentTerm"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.person.billingProfile.paymentTerm"
  })
  paymentTerm: number;

  get dto() {
    return {
      note: this.note,
      paymentTerm: this.paymentTerm
    };
  }

  readonly isUpdateable = true;

  constructor(data?: Partial<MrfiktivBillingProfileViewModelGen>) {
    this.note = data?.note;
    this.paymentTerm = data?.paymentTerm ?? 0;
  }
}
type IBillingProfile = BillingProfileBase;
const BillingProfile = Form.createForClass(Filter.createForClass(BillingProfileBase));

export { BillingProfile, IBillingProfile };
