import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { Person } from "../v1/Person";
import {
  MrfiktivCreatePersonDtoGen,
  MrfiktivPersonControllerGetParamsGen,
  MrfiktivUpdatePersonDtoGen
} from "../v1/data-contracts";

/**
 * Communicate with person API
 */
class PersonService {
  mrfiktivProxy: Person;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Person(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create an person
   */
  async create(partnerId: string, data: MrfiktivCreatePersonDtoGen) {
    const person = (await this.mrfiktivProxy.personControllerCreate(partnerId, data)).data;

    return person;
  }

  /**
   * Get all person
   *
   */
  async get(data: MrfiktivPersonControllerGetParamsGen) {
    const companies = (await this.mrfiktivProxy.personControllerGet(data)).data;

    return companies;
  }

  /**
   * Get a person
   */
  async getOne(partnerId: string, personId: string) {
    const person = (await this.mrfiktivProxy.personControllerGetOne(partnerId, personId)).data;

    return person;
  }

  /**
   * Update a person
   */
  async update(partnerId: string, personId: string, data: MrfiktivUpdatePersonDtoGen) {
    const person = (await this.mrfiktivProxy.personControllerUpdate(partnerId, personId, data)).data;

    return person;
  }

  /**
   * Delete a person
   */
  async delete(partnerId: string, personId: string) {
    const person = (await this.mrfiktivProxy.personControllerDelete(partnerId, personId)).data;

    return person;
  }
}

/**
 * Communicate with person API
 */
export default new PersonService(new MrfiktivHttpClientProvider());
