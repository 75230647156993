/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Country codes
 * TypeScript enum Country Codes ISO 3166
 *
 * @see https://gist.github.com/kyranjamie/646386d5edc174e8b549111572897f81
 */
export enum CountryCodeEnum {
  germany = "DE",
  austria = "AT",
  france = "FR",
  poland = "PL",
  unitedKingdom = "GB",
  afghanistan = "AF",
  alandIslands = "AX",
  albania = "AL",
  algeria = "DZ",
  americanSamoa = "AS",
  andorra = "AD",
  angola = "AO",
  anguilla = "AI",
  antarctica = "AQ",
  antiguaAndBarbuda = "AG",
  argentina = "AR",
  armenia = "AM",
  aruba = "AW",
  australia = "AU",
  azerbaijan = "AZ",
  bahamas = "BS",
  bahrain = "BH",
  bangladesh = "BD",
  barbados = "BB",
  belarus = "BY",
  belgium = "BE",
  belize = "BZ",
  benin = "BJ",
  bermuda = "BM",
  bhutan = "BT",
  bolivia = "BO",
  bonaireSintEustatiusSaba = "BQ",
  bosniaAndHerzegovina = "BA",
  botswana = "BW",
  bouvetIsland = "BV",
  brazil = "BR",
  britishIndianOceanTerritory = "IO",
  bruneiDarussalam = "BN",
  bulgaria = "BG",
  burkinaFaso = "BF",
  burundi = "BI",
  cambodia = "KH",
  cameroon = "CM",
  canada = "CA",
  capeVerde = "CV",
  caymanIslands = "KY",
  centralAfricanRepublic = "CF",
  chad = "TD",
  chile = "CL",
  china = "CN",
  christmasIsland = "CX",
  cocosKeelingIslands = "CC",
  colombia = "CO",
  comoros = "KM",
  congo = "CG",
  congoDemocraticRepublic = "CD",
  cookIslands = "CK",
  costaRica = "CR",
  coteDIvoire = "CI",
  croatia = "HR",
  cuba = "CU",
  curaçao = "CW",
  cyprus = "CY",
  czechRepublic = "CZ",
  denmark = "DK",
  djibouti = "DJ",
  dominica = "DM",
  dominicanRepublic = "DO",
  ecuador = "EC",
  egypt = "EG",
  elSalvador = "SV",
  equatorialGuinea = "GQ",
  eritrea = "ER",
  estonia = "EE",
  ethiopia = "ET",
  falklandIslands = "FK",
  faroeIslands = "FO",
  fiji = "FJ",
  finland = "FI",
  frenchGuiana = "GF",
  frenchPolynesia = "PF",
  frenchSouthernTerritories = "TF",
  gabon = "GA",
  gambia = "GM",
  georgia = "GE",
  ghana = "GH",
  gibraltar = "GI",
  greece = "GR",
  greenland = "GL",
  grenada = "GD",
  guadeloupe = "GP",
  guam = "GU",
  guatemala = "GT",
  guernsey = "GG",
  guinea = "GN",
  guineaBissau = "GW",
  guyana = "GY",
  haiti = "HT",
  heardIslandMcdonaldIslands = "HM",
  holySeeVaticanCityState = "VA",
  honduras = "HN",
  hongKong = "HK",
  hungary = "HU",
  iceland = "IS",
  india = "IN",
  indonesia = "ID",
  iran = "IR",
  iraq = "IQ",
  ireland = "IE",
  isleOfMan = "IM",
  israel = "IL",
  italy = "IT",
  jamaica = "JM",
  japan = "JP",
  jersey = "JE",
  jordan = "JO",
  kazakhstan = "KZ",
  kenya = "KE",
  kiribati = "KI",
  korea = "KR",
  koreaDemocraticPeoplesRepublic = "KP",
  kuwait = "KW",
  kyrgyzstan = "KG",
  laoPeoplesDemocraticRepublic = "LA",
  latvia = "LV",
  lebanon = "LB",
  lesotho = "LS",
  liberia = "LR",
  libyanArabJamahiriya = "LY",
  liechtenstein = "LI",
  lithuania = "LT",
  luxembourg = "LU",
  macao = "MO",
  macedonia = "MK",
  madagascar = "MG",
  malawi = "MW",
  malaysia = "MY",
  maldives = "MV",
  mali = "ML",
  malta = "MT",
  marshallIslands = "MH",
  martinique = "MQ",
  mauritania = "MR",
  mauritius = "MU",
  mayotte = "YT",
  mexico = "MX",
  micronesia = "FM",
  moldova = "MD",
  monaco = "MC",
  mongolia = "MN",
  montenegro = "ME",
  montserrat = "MS",
  morocco = "MA",
  mozambique = "MZ",
  myanmar = "MM",
  namibia = "NA",
  nauru = "NR",
  nepal = "NP",
  netherlands = "NL",
  newCaledonia = "NC",
  newZealand = "NZ",
  nicaragua = "NI",
  niger = "NE",
  nigeria = "NG",
  niue = "NU",
  norfolkIsland = "NF",
  northernMarianaIslands = "MP",
  norway = "NO",
  oman = "OM",
  pakistan = "PK",
  palau = "PW",
  palestinianTerritory = "PS",
  panama = "PA",
  papuaNewGuinea = "PG",
  paraguay = "PY",
  peru = "PE",
  philippines = "PH",
  pitcairn = "PN",
  portugal = "PT",
  puertoRico = "PR",
  qatar = "QA",
  reunion = "RE",
  romania = "RO",
  russianFederation = "RU",
  rwanda = "RW",
  saintBarthelemy = "BL",
  saintHelena = "SH",
  saintKittsAndNevis = "KN",
  saintLucia = "LC",
  saintMartin = "MF",
  saintPierreAndMiquelon = "PM",
  saintVincentAndGrenadines = "VC",
  samoa = "WS",
  sanMarino = "SM",
  saoTomeAndPrincipe = "ST",
  saudiArabia = "SA",
  senegal = "SN",
  serbia = "RS",
  seychelles = "SC",
  sierraLeone = "SL",
  singapore = "SG",
  sintMaarten = "SX",
  slovakia = "SK",
  slovenia = "SI",
  solomonIslands = "SB",
  somalia = "SO",
  southAfrica = "ZA",
  southGeorgiaAndSandwichIsl = "GS",
  southSudan = "SS",
  spain = "ES",
  sriLanka = "LK",
  sudan = "SD",
  suriname = "SR",
  svalbardAndJanMayen = "SJ",
  swaziland = "SZ",
  sweden = "SE",
  switzerland = "CH",
  syrianArabRepublic = "SY",
  taiwan = "TW",
  tajikistan = "TJ",
  tanzania = "TZ",
  thailand = "TH",
  timorLeste = "TL",
  togo = "TG",
  tokelau = "TK",
  tonga = "TO",
  trinidadAndTobago = "TT",
  tunisia = "TN",
  turkey = "TR",
  turkmenistan = "TM",
  turksAndCaicosIslands = "TC",
  tuvalu = "TV",
  uganda = "UG",
  ukraine = "UA",
  unitedArabEmirates = "AE",
  unitedStates = "US",
  unitedStatesOutlyingIslands = "UM",
  uruguay = "UY",
  uzbekistan = "UZ",
  vanuatu = "VU",
  venezuela = "VE",
  vietnam = "VN",
  virginIslandsBritish = "VG",
  virginIslandsUS = "VI",
  wallisAndFutuna = "WF",
  westernSahara = "EH",
  yemen = "YE",
  zambia = "ZM",
  zimbabwe = "ZW"
}
