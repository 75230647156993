import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IPersonGroup, PersonGroup } from "@/models/person-group.entity";

export const PersonGroupDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IPersonGroup, ""> {
  getIdentifier(entity: IPersonGroup): string {
    return (entity as IPersonGroup).id;
  }

  mapEntity(entity: IPersonGroup): IPersonGroup {
    return new PersonGroup(entity);
  }
})();
