import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import companyService from "@/services/mrfiktiv/services/companyService";
import fleetService from "@/services/mrfiktiv/services/fleetService";
import vehicleService from "@/services/mrfiktiv/services/vehicleService";
import {
  MrfiktivCreateFleetDtoGen,
  MrfiktivFleetViewModelGen,
  MrfiktivReferenceGen,
  MrfiktivSimpleFleetViewModelGen,
  MrfiktivUpdateFleetDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { FleetDataAccessLayer } from "@/store/modules/access-layers/fleet.access-layer";
import { Company, ICompany } from "./company.entity";
import { PageFilterElement } from "./page-filter-element.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { VehicleReference } from "./vehicle-reference.entity";
import { IReference, Reference } from "./reference.entity";
import { IVehicle, Vehicle } from "./vehicle.entity";
import { IProjectConfiguration, ProjectConfiguration } from "./project-configuration.entity";
import { FormConfig } from "@/lib/formable";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { CompanyDataAccessLayer } from "@/store/modules/access-layers/company.access-layer";
import { PartnerUserAccessLayer } from "@/store/modules/access-layers/partner-user.access-layer";

export type FleetNode = {
  id: string;
  name: string;
  type: "partner" | "company" | "fleet";
  children: FleetNode[];

  obj?: IFleet;

  icon?: string;
  vehicles?: IVehicle[];
  isVehicleLoading?: boolean;
};

export type FleetVehicleNode = FleetNode & {};

export function buildFleetTree(fleets: IFleet[]): FleetNode[] {
  const idToNodeMap: Record<string, FleetNode> = {};

  for (const fleet of fleets) {
    idToNodeMap[fleet.id] = {
      id: fleet.id,
      name: fleet.title,
      type: fleet.companyId ? "company" : "fleet",
      icon: fleet.isRoot ? "mdi-domain" : fleet.companyId ? "mdi-home" : "mdi-map-marker",
      children: [],
      obj: fleet
    };
  }

  const roots: FleetNode[] = [];

  for (const fleet of fleets) {
    const node = idToNodeMap[fleet.id];

    if (!fleet.parentId) {
      roots.push(node);
    } else {
      const parent = idToNodeMap[fleet.parentId];
      parent.children.push(node);
    }
  }

  return roots;
}

/**
 * findPathById
 * Sucht in einem (Teil-)Baum nach dem Knoten mit 'targetId'
 * und gibt den Pfad vom aktuellen Root bis dorthin als Array zurück.
 * Falls nicht gefunden, return null.
 */
export function findPathById(root: FleetNode, targetId: string): FleetNode[] | null {
  if (root.id === targetId) {
    return [root];
  }
  for (const child of root.children) {
    const subPath = findPathById(child, targetId);
    if (subPath) {
      // Pfad gefunden -> current root + gefundener subPath
      return [root, ...subPath];
    }
  }
  return null;
}

export function findPathInForest(roots: FleetNode[], targetId: string): FleetNode[] | null {
  for (const root of roots) {
    const path = findPathById(root, targetId);
    if (path) {
      return path; // sobald gefunden -> zurück
    }
  }
  return null; // nichts gefunden
}

@IsFilterable
export class FleetBase implements MrfiktivSimpleFleetViewModelGen, ICreateDto<IFleet> {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.fleet.id",
    config: {
      itemCallback: () => FleetDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-fleet"
    }
  })
  id: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.fleet.partnerId"
  })
  partnerId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.fleet.title"
  })
  title: string;

  /** Internal description of the fleet object */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.fleet.description", "common.nouns.general"],
    type: DetailFormComponentsEnum.TEXT_AREA,
    props: {
      label: "objects.fleet.description"
    },
    rules: [],
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.fleet.description"
  })
  description?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  @FilterConfig({
    displayName: "objects.fleet.assignees",
    type: FilterTypes.OBJECT_ID,
    width: "120",
    config: {
      itemCallback: () => PartnerUserAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  assignees: string[];

  @FilterConfig({
    type: VehicleReference
  })
  refs?: MrfiktivReferenceGen[] | undefined;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.ticket.userId",
    config: {
      itemCallback: () => PartnerUserAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId: string;

  @FilterConfig({
    type: ProjectConfiguration
  })
  configuration?: IProjectConfiguration;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.company",
    config: {
      itemCallback: () => CompanyDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-company"
    }
  })
  companyId?: string | undefined;

  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.fleet.isRoot"
  })
  isRoot: boolean;

  // TODO: IMPLEMENT THIS
  rootId?: string | undefined;
  parentId?: string | undefined;
  ancestorIds: string[];

  /**
   * Fetched company
   */
  _company?: ICompany;

  /**
   * List of vehicles
   */
  _vehicles?: IVehicle[];

  _isLoadingCompany = false;
  _isLoadingVehicles = false;

  /**
   * Construct object
   */
  constructor(obj?: Partial<FleetBase | MrfiktivFleetViewModelGen | MrfiktivSimpleFleetViewModelGen>) {
    this.id = obj?.id ?? "";
    this.partnerId = obj?.partnerId ?? "";
    this.userId = obj?.userId ?? "";

    this.isRoot = obj?.isRoot ?? false;
    this.ancestorIds = obj?.ancestorIds ?? [];
    this.parentId = obj?.parentId;

    this.companyId = obj?.companyId;
    if (this.companyId) {
      this._company = new Company({ id: obj?.companyId });
    }

    this.title = obj?.title ?? "";
    this.description = obj?.description ?? "";

    this.assignees = obj?.assignees || [];
    this.refs = Reference.filterDuplicates((obj?.refs ?? []).map(ref => new Reference(ref)));

    this.timestamp = new Timestamp(obj?.timestamp);

    const withConfig = obj as MrfiktivFleetViewModelGen;
    if (withConfig.configuration) {
      this.configuration = new ProjectConfiguration(withConfig?.configuration);
    }
  }

  get company() {
    return this._company;
  }

  get vehicles() {
    return this._vehicles;
  }

  /**
   * fetch object
   */
  async fetch(): Promise<this> {
    const res = await fleetService.findOne(this.partnerId, this.id);

    this.map(res);
    FleetDataAccessLayer.set(this);

    return this;
  }

  /**
   * map props from viewmodel to this
   */
  protected map(obj?: MrfiktivFleetViewModelGen | MrfiktivSimpleFleetViewModelGen) {
    if (!obj) return;
    this.id = obj?.id ?? "";
    this.partnerId = obj?.partnerId ?? "";
    this.userId = obj?.userId ?? "";

    this.isRoot = obj?.isRoot ?? false;
    this.ancestorIds = obj?.ancestorIds ?? [];
    this.parentId = obj?.parentId;

    this.companyId = obj?.companyId;

    this.title = obj?.title ?? "";
    this.description = obj?.description ?? "";

    this.assignees = obj?.assignees || [];
    this.refs = obj?.refs || [];

    this.timestamp = new Timestamp(obj?.timestamp);

    const withConfig = obj as MrfiktivFleetViewModelGen;
    if (withConfig.configuration) {
      this.configuration = new ProjectConfiguration(withConfig?.configuration);
    }
  }

  /**
   * create object
   */
  async create() {
    const data: MrfiktivCreateFleetDtoGen = {
      title: this.title,
      description: this.description,
      assignees: this.assignees,
      parentId: this.parentId,
      companyId: this.companyId,
      refs: Reference.filterDuplicates((this.refs ?? []) as IReference[])
    };
    const res = await fleetService.create(this.partnerId, data);

    this.map(res);

    FleetDataAccessLayer.set(this);

    return this;
  }

  /**
   * delete object
   */
  async delete() {
    const res = await fleetService.remove(this.partnerId, this.id);

    this.map(res);
    FleetDataAccessLayer.delete(this);
  }

  /**
   * update object
   * @returns
   */
  async update() {
    const data: MrfiktivUpdateFleetDtoGen = {
      title: this.title,
      description: this.description,
      assignees: this.assignees,
      parentId: this.parentId,
      companyId: this.companyId,
      refs: Reference.filterDuplicates((this.refs ?? []) as IReference[])
    };
    const res = await fleetService.update(this.partnerId, this.id, data);
    this.map(res);
    FleetDataAccessLayer.set(this);

    return this;
  }

  /**
   * update object via dto
   * @param dto
   * @returns
   */
  async updatePartial(dto: MrfiktivUpdateFleetDtoGen) {
    const res = await fleetService.update(this.partnerId, this.id, dto);

    this.map(res);

    FleetDataAccessLayer.set(this);

    return this;
  }

  async fetchCompany() {
    if (!this.companyId || !this.partnerId) {
      return;
    }

    this._isLoadingCompany = true;

    await companyService
      .getOne(this.partnerId, this.companyId)
      .then(r => (this._company = new Company(r)))
      .finally(() => (this._isLoadingCompany = false));
  }

  async fetchVehicles() {
    if (!this.partnerId) {
      return;
    }

    this._isLoadingVehicles = true;

    const res = await vehicleService
      .getAll({
        partnerId: this.partnerId,
        filter: [new PageFilterElement({ key: "groupId", operation: "$eq", value: this.id })]
      })
      .finally(() => (this._isLoadingVehicles = false));

    if (!res.data) {
      return;
    }

    this._vehicles = res.data.map(v => new Vehicle(v));
  }
}

type IFleet = FleetBase;
const Fleet = Filter.createForClass(FleetBase);

export { Fleet, IFleet };
