/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateFleetDtoGen,
  MrfiktivFleetControllerFindAllParamsGen,
  MrfiktivFleetViewModelGen,
  MrfiktivPageViewModelGen,
  MrfiktivSimpleFleetViewModelGen,
  MrfiktivUpdateFleetDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Fleet<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Fleet
   * @name FleetControllerCreate
   * @summary (Fleet - CREATE) Create a fleet
   * @request POST:/partner/{partnerId}/fleet
   * @secure
   * @response `201` `MrfiktivFleetViewModelGen` The created fleet
   */
  fleetControllerCreate = (partnerId: string, data: MrfiktivCreateFleetDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivFleetViewModelGen, any>({
      path: `/partner/${partnerId}/fleet`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Fleet
   * @name FleetControllerFindAll
   * @summary (Fleet - READ) Get all fleet
   * @request GET:/partner/{partnerId}/fleet
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivSimpleFleetViewModelGen)[] })`
   */
  fleetControllerFindAll = (
    { partnerId, ...query }: MrfiktivFleetControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivSimpleFleetViewModelGen[] }, any>({
      path: `/partner/${partnerId}/fleet`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Fleet
   * @name FleetControllerFindOne
   * @summary (Fleet - READ) Get a fleet
   * @request GET:/partner/{partnerId}/fleet/{fleetId}
   * @secure
   * @response `200` `MrfiktivFleetViewModelGen` The fleet
   */
  fleetControllerFindOne = (partnerId: string, fleetId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivFleetViewModelGen, any>({
      path: `/partner/${partnerId}/fleet/${fleetId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Fleet
   * @name FleetControllerUpdate
   * @summary (Fleet - UPDATE) Updates a fleet
   * @request PATCH:/partner/{partnerId}/fleet/{fleetId}
   * @secure
   * @response `201` `MrfiktivFleetViewModelGen` The updated fleet
   */
  fleetControllerUpdate = (
    partnerId: string,
    fleetId: string,
    data: MrfiktivUpdateFleetDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivFleetViewModelGen, any>({
      path: `/partner/${partnerId}/fleet/${fleetId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Fleet
   * @name FleetControllerDelete
   * @summary (Fleet - DELETE) Deletes a fleet
   * @request DELETE:/partner/{partnerId}/fleet/{fleetId}
   * @secure
   * @response `200` `MrfiktivSimpleFleetViewModelGen` The deleted fleet
   */
  fleetControllerDelete = (partnerId: string, fleetId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSimpleFleetViewModelGen, any>({
      path: `/partner/${partnerId}/fleet/${fleetId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Fleet
   * @name FleetControllerFindAllRelatedForFleet
   * @summary (Fleet - READ) Get all related nodes of a fleet
   * @request GET:/partner/{partnerId}/fleet/{fleetId}/all
   * @secure
   * @response `200` `(MrfiktivSimpleFleetViewModelGen)[]` The fleet
   */
  fleetControllerFindAllRelatedForFleet = (partnerId: string, fleetId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSimpleFleetViewModelGen[], any>({
      path: `/partner/${partnerId}/fleet/${fleetId}/all`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Fleet
   * @name FleetControllerFindAllAncestorsForFleet
   * @summary (Fleet - READ) Get all ancestors nodes of a given fleet
   * @request GET:/partner/{partnerId}/fleet/{fleetId}/ancestors
   * @secure
   * @response `200` `(MrfiktivSimpleFleetViewModelGen)[]` The fleet
   */
  fleetControllerFindAllAncestorsForFleet = (partnerId: string, fleetId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSimpleFleetViewModelGen[], any>({
      path: `/partner/${partnerId}/fleet/${fleetId}/ancestors`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
