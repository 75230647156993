import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { RulesEnum } from "@/lib/rules/rules.map";
import { MrfiktivContactDocumentGen } from "@/services/mrfiktiv/v1/data-contracts";

@IsFormable
@IsFilterable
class ContactBase implements MrfiktivContactDocumentGen {
  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.contact",
    searchKeywords: ["objects.contact.email", "common.nouns.contact"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.contact.email"
    },
    rules: [RulesEnum.REQUIRED_RULE, RulesEnum.EMAIL_RULE]
  })
  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.contact.email" })
  email: string;
  /**
   * @inheritdoc
   */

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.contact",
    searchKeywords: ["objects.contact.phone", "common.nouns.contact"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: { label: "objects.contact.phone" },
    rules: [RulesEnum.REQUIRED_RULE, RulesEnum.PHONE_RULE]
  })
  @FilterConfig({ type: FilterTypes.STRING, displayName: "common.nouns.contact" })
  phone: string;

  constructor(contact?: Partial<MrfiktivContactDocumentGen>) {
    this.email = contact?.email ?? "";
    this.phone = contact?.phone ?? "";
  }
}

type IContact = ContactBase;
const Contact = Form.createForClass(Filter.createForClass(ContactBase));

export { Contact, IContact };
