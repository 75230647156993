export enum DetailFormComponentsEnum {
  CHECK_BOX = "custom-field-boolean",
  SELECT_FIELD = "v-select",
  AUTO_COMPLETE = "v-autocomplete",
  TEXT_FIELD = "v-text-field",
  TEXT_AREA = "v-textarea",
  CUSTOM_FIELD = "custom-field",
  PARTNER_SINGLE_IMAGE_UPLOAD = "partner-single-image-upload",
  REFS_SELECT = "refs-select-form",
  CUSTOM_FIELD_DATE_TIME = "custom-field-date-time",
  SELECT_ENTITY = "select-entity",
  SELECT_ENTITIES = "select-entities",
  SELECT_ASSIGNEES = "select-assignees",
  ARRAY = "m-detail-form-array-form",
  GEO_MAP = "geo-coordinate-map"
}
