import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IContract, Contract } from "@/models/contract.entity";

export const ContractDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IContract> {
  getIdentifier(entity: IContract): string {
    return entity.id;
  }

  protected mapEntity(entity: IContract): IContract {
    return new Contract(entity);
  }
})();
