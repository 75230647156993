import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import Vue from "vue";
import VueRouter from "vue-router";
const Setting = () => import(/* webpackChunkName: "setting" */ "@/views/Setting.vue");

Vue.use(VueRouter);

export const SettingRoutes: ICustomRouteConfig[] = [
  {
    path: "/",
    name: "Settings",
    component: Setting,
    navigation: {
      category: NavigationCategoryEnum.HELP,
      icon: "mdi-tune-variant",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 20
    }
  }
];
