import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import personGroupService from "@/services/mrfiktiv/services/person-group.service";
import {
  MrfiktivPersonGroupViewModelGen,
  MrfiktivCreatePersonGroupDtoGen,
  MrfiktivCustomFieldListElementDtoGen,
  MrfiktivUpdatePersonGroupDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { PersonGroupDataAccessLayer } from "@/store/modules/access-layers/person-group.access-layer";
import { IProjectConfiguration, ProjectConfiguration } from "./project-configuration.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import Vue from "vue";

@IsFilterable
export class PersonGroupBase implements MrfiktivPersonGroupViewModelGen, ICreateDto<IPersonGroup> {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "id",
    config: {
      itemCallback: () => PersonGroupDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-person-group"
    }
  })
  id: string;

  partnerId: string;

  configuration: IProjectConfiguration;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.personGroup.title"
  })
  title: string;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.personGroup.description"
  })
  description?: string | undefined;

  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  private get customFieldConfigDto(): MrfiktivCustomFieldListElementDtoGen[] {
    return this.configuration.customFieldConfig
      .filter(c => c.customField.id) // filter out deleted custom fields
      .map(c => {
        return {
          configuration: c.configuration,
          customField: c.customField.id
        };
      });
  }

  loading = false;

  /**
   * Construct personGroup
   */
  constructor(personGroup?: Partial<IPersonGroup | MrfiktivPersonGroupViewModelGen>) {
    this.id = personGroup?.id ?? "";
    this.partnerId = personGroup?.partnerId ?? "";
    this.configuration = new ProjectConfiguration(personGroup?.configuration);
    this.title = personGroup?.title ?? "";
    this.description = personGroup?.description ?? "";
    this.timestamp = new Timestamp(personGroup?.timestamp);
  }

  /**
   * map props from viewmodel to this
   */
  protected map(personGroup?: MrfiktivPersonGroupViewModelGen) {
    if (!personGroup) return;
    this.id = personGroup?.id ?? "";
    this.partnerId = personGroup?.partnerId ?? "";
    this.configuration = new ProjectConfiguration(personGroup?.configuration);
    this.title = personGroup?.title ?? "";
    this.description = personGroup?.description ?? "";
    this.timestamp = new Timestamp(personGroup?.timestamp);
  }

  /**
   * fetch personGroup
   */
  async fetch(): Promise<this> {
    try {
      this.loading = true;
      const res = await personGroupService.getOne(this.partnerId, this.id);

      this.map(res);
      PersonGroupDataAccessLayer.set(this);

      this.loading = false;

      return this;
    } catch (e) {
      Vue.$log.error(e);
      this.loading = false;
      throw e;
    }
  }

  /**
   * create fetch personGroup
   */
  async create() {
    const data: MrfiktivCreatePersonGroupDtoGen = {
      title: this.title,
      description: this.description || undefined,
      configuration: {
        isTemplate: this.configuration.isTemplate,
        customFieldConfig: this.customFieldConfigDto,
        views: []
      }
    };
    const res = await personGroupService.create(this.partnerId, data);

    this.map(res);

    PersonGroupDataAccessLayer.set(this);

    return this;
  }

  /**
   * delete personGroup
   */
  async delete() {
    const res = await personGroupService.delete(this.partnerId, this.id);

    this.map(res);
    PersonGroupDataAccessLayer.delete(this);
  }

  /**
   * update personGroup
   * @returns
   */
  async update() {
    const data: MrfiktivUpdatePersonGroupDtoGen = {
      title: this.title,
      description: this.description,
      configuration: {
        isTemplate: this.configuration.isTemplate,
        customFieldConfig: this.customFieldConfigDto,
        views: this.configuration.views.map(v => v.dto)
      }
    };
    const res = await personGroupService.update(this.partnerId, this.id, data);
    this.map(res);
    PersonGroupDataAccessLayer.set(this);

    return this;
  }

  /**
   * update personGroup via dto
   * @param dto
   * @returns
   */
  async updatePartial(dto: MrfiktivUpdatePersonGroupDtoGen) {
    const res = await personGroupService.update(this.partnerId, this.id, dto);

    this.map(res);

    PersonGroupDataAccessLayer.set(this);

    return this;
  }
}

type IPersonGroup = PersonGroupBase;
const PersonGroup = Filter.createForClass(PersonGroupBase);

export { PersonGroup, IPersonGroup };
