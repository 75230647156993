












import { Component, Vue } from "vue-property-decorator";
import QuickLinkList from "./QuickLinkList.vue";

@Component({
  components: { QuickLinkList }
})
export default class QuickLinkMenu extends Vue {
  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }
}
