import { AbstractPageDataProvider, PageDefaults } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { Fleet, IFleet } from "@/models/fleet.entity";
import fleetService from "@/services/mrfiktiv/services/fleetService";
import { MrfiktivFleetControllerFindAllParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

export const FleetPageDataProvider = new (class extends AbstractPageDataProvider<
  IFleet,
  MrfiktivFleetControllerFindAllParamsGen
> {
  itemsPerPage = PageDefaults.DEFAULT_ITEMS_PER_PAGE;

  async getPage(query: MrfiktivFleetControllerFindAllParamsGen): Promise<IPageViewModel<IFleet>> {
    const res = await fleetService.findAll(query);

    const meta = res.meta;
    const data = (res.data ?? []).map(fleet => new Fleet(fleet));

    return { meta, data };
  }
})();
