import VueRouter from "vue-router";
import { BaseGoToHelper } from "./goToHelper";
import { $t } from "./t";

export enum PersonRouteNames {
  PERSON_TABLE = "PERSON_TABLE",
  PERSON_IMPORT = "PERSON_IMPORT",
  PERSON_DETAIL = "PERSON_DETAIL",
  PERSON_DETAIL_FORM = "PERSON_DETAIL_FORM",
  PERSON_GROUP_DETAIL = "PERSON_GROUP_DETAIL",
  PERSON_GROUP_TABLE = "PERSON_GROUP_TABLE",
  PERSON_GROUP_CUSTOM_VIEW = "PERSON_GROUP_CUSTOM_VIEW"
}

type Tab = { newTab?: boolean };
type Partner = { partnerId: string };
type Person = { personId: string };
type PersonGroup = { personGroupId: string };
type View = { viewId: string };
type Query = { query?: Record<string, string> };

export class PersonGoToHelper extends BaseGoToHelper {
  static get breadCrumbs() {
    const locations = PersonGoToHelper.locations;

    return {
      PersonTable: (partnerId: string) => {
        return {
          text: $t("person.people").toString(),
          exact: true,
          to: locations.personTable({ partnerId })
        };
      },
      PersonImport: (partnerId: string) => {
        return {
          text: $t("import").toString(),
          exact: true,
          to: locations.personImport({ partnerId })
        };
      },
      PersonDetail: (partnerId: string, personId: string) => {
        return {
          text: $t("person.person").toString(),
          exact: true,
          to: locations.personDetail({ partnerId, personId })
        };
      },
      PersonDetailForm: (partnerId: string, personId: string) => {
        return {
          text: $t("common.verbs.edit").toString(),
          exact: true,
          to: locations.personDetailForm({ partnerId, personId })
        };
      },
      PersonGroupTable: (partnerId: string) => {
        return {
          text: $t("person.personGroups").toString(),
          exact: true,
          to: locations.personGroupTable({ partnerId })
        };
      },
      PersonGroupCustomView: (partnerId: string, personGroupId: string, viewId?: string) => {
        return {
          text: $t("person.personGroup").toString(),
          exact: true,
          to: locations.personGroupCustomView({ partnerId, personGroupId, viewId: viewId ?? "0" })
        };
      },
      PersonGroupDetail: (partnerId: string, personGroupId: string) => {
        return {
          text: $t("common.verbs.edit").toString(),
          exact: true,
          to: locations.personGroupDetail({ partnerId, personGroupId })
        };
      }
    };
  }

  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  static locations = {
    personTable: (d: Partner & Query) => {
      return {
        name: PersonRouteNames.PERSON_TABLE,
        params: { partnerId: d.partnerId },
        query: d.query
      };
    },
    personImport: (d: Partner & Query) => {
      return {
        name: PersonRouteNames.PERSON_IMPORT,
        params: { partnerId: d.partnerId },
        query: d.query
      };
    },
    personDetail: (d: Partner & Person & Query) => {
      return {
        name: PersonRouteNames.PERSON_DETAIL,
        params: { partnerId: d.partnerId, personId: d.personId },
        query: d.query
      };
    },
    personDetailForm: (d: Partner & Person & Query) => {
      return {
        name: PersonRouteNames.PERSON_DETAIL_FORM,
        params: { partnerId: d.partnerId, personId: d.personId },
        query: d.query
      };
    },
    personGroupDetail: (d: Partner & PersonGroup & Query) => {
      return {
        name: PersonRouteNames.PERSON_GROUP_DETAIL,
        params: { partnerId: d.partnerId, personGroupId: d.personGroupId },
        query: d.query
      };
    },
    personGroupTable: (d: Partner & Query) => {
      return {
        name: PersonRouteNames.PERSON_GROUP_TABLE,
        params: { partnerId: d.partnerId },
        query: d.query
      };
    },
    personGroupCustomView: (d: Partner & PersonGroup & View & Query) => {
      return {
        name: PersonRouteNames.PERSON_GROUP_CUSTOM_VIEW,
        params: { partnerId: d.partnerId, personGroupId: d.personGroupId, viewId: d.viewId },
        query: d.query
      };
    }
  };

  goToPersonTable(d: Tab & Partner) {
    this.go(PersonGoToHelper.locations.personTable(d), d.newTab);
  }

  goToPersonImport(d: Tab & Partner) {
    this.go(PersonGoToHelper.locations.personImport(d), d.newTab);
  }

  goToPersonDetail(d: Tab & Partner & Person) {
    this.go(PersonGoToHelper.locations.personDetail(d), d.newTab);
  }

  goToPersonDetailForm(d: Tab & Partner & Person) {
    this.go(PersonGoToHelper.locations.personDetailForm(d), d.newTab);
  }

  goToPersonGroupTable(d: Tab & Partner) {
    this.go(PersonGoToHelper.locations.personGroupTable(d), d.newTab);
  }

  goToPersonGroupDetail(d: Tab & Partner & PersonGroup) {
    this.go(PersonGoToHelper.locations.personGroupDetail(d), d.newTab);
  }

  goToPersonGroupCustomView(d: Tab & Partner & PersonGroup & View) {
    this.go(PersonGoToHelper.locations.personGroupCustomView(d), d.newTab);
  }
}
