import { AbstractPageDataProvider, PageDefaults } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { ISignDocument } from "@/models/sign-document.entity";
import documentService from "@/services/sign/services/document.service";
import { SignDocumentControllerFindAllParamsGen } from "@/services/sign/v1/data-contracts";

export const SignDocumentPageDataProvider = new (class extends AbstractPageDataProvider<
  ISignDocument,
  SignDocumentControllerFindAllParamsGen
> {
  itemsPerPage = PageDefaults.DEFAULT_PAGE_SIZES[3];

  async getPage(query: SignDocumentControllerFindAllParamsGen): Promise<IPageViewModel<ISignDocument>> {
    const res = await documentService.getAllForPartner(query);

    const meta = res.meta;
    const data = (res.data ?? []) as ISignDocument[];

    return { meta, data };
  }
})();
