import { ExportReportMetaTypeEnum } from "@/lib/enum/export-report-meta-type.enum";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { CoverageEnum } from "@/lib/enum/liability.enum";
import { NotFoundException } from "@/lib/exceptions/http";
import { uniqueImageName } from "@/lib/files/ImageHelper";
import { IKsrData } from "@/lib/interfaces/ksr/ksr-data.interface";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { IUser } from "@/models/User/IUser";
import { PartnerEntity } from "@/models/partnerEntity";
import { IPreExistingDamageDetails, PreExistingDamageDetails } from "@/models/pre-existing-damage.entity";
import VueI18n from "@/plugins/I18nPlugin";
import partnerService from "@/services/mrfiktiv/services/partnerService";
import { default as ReportService, default as reportService } from "@/services/mrfiktiv/services/reportService";
import {
  MrfiktivCreateAccidentDetailsDtoGen,
  MrfiktivCreateInsuranceDtoGen,
  MrfiktivCreatePoliceDetailsDtoGen,
  MrfiktivCreateReportAsPartnerDtoGen,
  MrfiktivCreateReportWittnesDtoGen,
  MrfiktivExportReportMetaDtoGen,
  MrfiktivLeasingDetailsGen,
  MrfiktivPartnerViewModelGen,
  MrfiktivRegistrationDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import { CountryCodeEnum } from "../../lib/enum/country-code.enum";
import { IAddress } from "../../models/User/IAddress";
import { IContact } from "../../models/User/IContact";
import { ReportScreenEnum } from "../enum/partner/report-screen.enum";
import { ReportType } from "../enum/reportType";
import { Address } from "../models/mrfiktiv/address";
import { CreateContactDto } from "../models/mrfiktiv/contact";
import { CreateReport, ICreateReport } from "../models/mrfiktiv/report";
import { User } from "../models/mrfiktiv/user";
import { CustomerAccountMeModule } from "./customer-account-me.store";
import { ErrorLogModule } from "./error-log";
import { ExportModule } from "./export.store";
import { FleetAggregationModule } from "./fleet-aggregation.store";
import { UserModule } from "./me-user.store";

@Module({
  dynamic: true,
  namespaced: true,
  name: "report",
  store
})
export class ReportStore extends VuexModule {
  get reportDto(): MrfiktivCreateReportAsPartnerDtoGen {
    let accidentDetails = undefined;
    if (this.accidentDetails.date) {
      accidentDetails = this.accidentDetails;
    }

    let customerContact = undefined;
    if (this.contact.email && this.contact.phone) {
      customerContact = this.contact;
    }

    let leasing = undefined;
    if (this.leasing) {
      leasing = {
        isLeasing: this.leasing.isLeasing,
        claimNumber: this.leasing.claimNumber,
        company: this.leasing.company
      };
    }

    const wittnessList: MrfiktivCreateReportWittnesDtoGen[] = [...this.wittnessList];
    if (this.liableWitness) {
      wittnessList.push(this.liableWitness);
    }

    return {
      customerAddress: { ...this.address },
      customerContact: customerContact,
      customerName: `${this.user?.firstName} ${this.user?.lastName}`,
      firstName: this.user?.firstName,
      lastName: this.user?.lastName,
      isCompany: this.isCompany,
      companyName: this.companyName,
      isTaxDeductible: this.isTaxDeductible,
      taxnumber: this.taxnumber,
      externalId: this.externalId,
      damage: this.inCreationReport.damageLocation as any,
      datePreference: this.inCreationReport.datePreference,
      images: {
        cockpits: this.cockpits.map(x => x.uploadId),
        damages: this.damages.map(x => x.uploadId),
        damagesDetail: this.damagesDetail.map(x => x.uploadId),
        overviews: [
          ...this.overviews,
          ...this.overviewsFrontLeft,
          ...this.overviewsFrontRight,
          ...this.overviewsRearLeft,
          ...this.overviewsRearRight
        ].map(x => x.uploadId),
        registrations: this.registrations.map(x => x.uploadId)
      },
      message: this.inCreationReport.message,
      marketingOptIn: this.inCreationReport.isMarketingOptIn,
      numberplate: this.numberplate,
      reportType: this.reportType,
      wittness: wittnessList,
      insurances: this.insuranceList,
      policeDetails: this.policeDetails,
      accidentDetails: accidentDetails,
      preExistingDamageDetails: this.preExistingDamageDetails,
      registrationResults: this.registration,
      leasing: leasing,
      language: VueI18n.locale as LanguageCodeEnum
    };
  }

  private _taxnumber = "";

  get taxnumber() {
    return this._taxnumber;
  }

  @Mutation
  updateTaxnumber(taxnumber: string) {
    this._taxnumber = taxnumber;
  }

  @Action
  setTaxnumber(taxnumber: string) {
    this.context.commit("updateTaxnumber", taxnumber);
  }

  get MAX_STEP() {
    return this.screenOder.length;
  }

  get screenOder(): ReportScreenEnum[] {
    if (
      this.partner?.settings?.reportSettings?.screenOrder &&
      this.partner?.settings?.reportSettings?.screenOrder.length !== 0
    ) {
      return skipScreensIfAutehnticated(
        this.partner?.settings?.reportSettings?.screenOrder as any,
        this.skipScreensIfAuthenticated
      );
    } else {
      return this.defaultScreenorder;
    }
  }

  get nextScreen(): ReportScreenEnum {
    let nextScreen: ReportScreenEnum = undefined as any;
    const currentScreenIndex = this.screenOder.indexOf(this.currentStep);
    if (currentScreenIndex != undefined) {
      nextScreen = this.screenOder[currentScreenIndex + 1];
    }
    return nextScreen;
  }

  get prevScreen(): ReportScreenEnum {
    let prevScreen: ReportScreenEnum = undefined as any;
    const currentScreenIndex = this.screenOder.indexOf(this.currentStep);
    if (currentScreenIndex != undefined) {
      prevScreen = this.screenOder[currentScreenIndex - 1];
    }
    return prevScreen;
  }

  get firstScreen(): ReportScreenEnum {
    return this.screenOder[0];
  }

  defaultScreenorder = [
    ReportScreenEnum.welcome,
    ReportScreenEnum.registration,
    ReportScreenEnum.damagelocator,
    ReportScreenEnum.overviewimage,
    ReportScreenEnum.componentimage,
    ReportScreenEnum.damagedetail,
    ReportScreenEnum.mileage,
    ReportScreenEnum.message,
    ReportScreenEnum.daterequest,
    ReportScreenEnum.contactdetails,
    ReportScreenEnum.closing
  ];

  skipScreensIfAuthenticated = [
    ReportScreenEnum.thgpassword,
    ReportScreenEnum.thgcustomerdetails,
    ReportScreenEnum.thgIsCompanyRequired
  ];

  currentStep = ReportScreenEnum.welcome;
  disableNext = false;

  partnerName = "";
  _loading = true;

  partner: PartnerEntity = {} as PartnerEntity;

  get partnerCountryCode(): CountryCodeEnum {
    return (this.partner?.countryCode as CountryCodeEnum) || CountryCodeEnum.germany;
  }

  inCreationReport: ICreateReport = new CreateReport();

  cockpits: IImageUploaded[] = [];
  damages: IImageUploaded[] = [];
  damagesDetail: IImageUploaded[] = [];

  overviews: IImageUploaded[] = [];
  overviewsFrontLeft: IImageUploaded[] = [];
  overviewsFrontRight: IImageUploaded[] = [];
  overviewsRearLeft: IImageUploaded[] = [];
  overviewsRearRight: IImageUploaded[] = [];

  registrations: IImageUploaded[] = [];
  preExistingDamages: IImageUploaded[] = [];

  user = new User();

  contact = new CreateContactDto();

  address = new Address();

  numberplate = "";

  uploadErrorMessage = "Bild Upload fehlgeschlagen.";
  uploadSuccessMessage = "Bild erfolgreich hochgeladen.";
  deleteErrorMessage = "Bild löschen fehlgeschlagen.";

  private _ksrData: IKsrData = {
    jobId: undefined,
    customerIdentityToken: "",
    numberPlate: "",
    addressId: undefined,
    contactId: undefined
  };

  get ksrData(): IKsrData {
    return this._ksrData;
  }

  private _isTaxDeductible = false;

  get isTaxDeductible(): boolean {
    return this._isTaxDeductible;
  }

  @Mutation
  updateIsTaxDeductible(isTaxDeductible: boolean) {
    this._isTaxDeductible = isTaxDeductible;
  }

  @Action
  setIsTaxDeductible(isTaxDeductible: boolean) {
    this.context.commit("updateIsTaxDeductible", isTaxDeductible);
  }

  private _isCompany = false;

  get isCompany(): boolean {
    return this._isCompany;
  }

  @Mutation
  updateIsCompany(isCompany: boolean) {
    this._isCompany = isCompany;
  }

  @Action
  setIsCompany(isCompany: boolean) {
    this.context.commit("updateIsCompany", isCompany);
  }

  _reportType = ReportType.CAR;

  get reportType(): ReportType {
    return this._reportType;
  }

  /**
   * Information if the accident was recorded by police
   */
  private _policeDetails: MrfiktivCreatePoliceDetailsDtoGen = {
    isRecorded: false,
    department: "",
    fileReference: ""
  };

  get policeDetails(): MrfiktivCreatePoliceDetailsDtoGen {
    return this._policeDetails;
  }

  _leasing: MrfiktivLeasingDetailsGen | undefined = undefined;

  get leasing(): MrfiktivLeasingDetailsGen | undefined {
    return this._leasing;
  }

  /**
   * Information about the accident
   */
  private _accidentDetails: MrfiktivCreateAccidentDetailsDtoGen = {
    date: "",
    comment: "",
    role: "unclear",
    address: {
      street: "",
      zip: "",
      city: "",
      state: "",
      countryCode: CountryCodeEnum.germany,
      geo: {
        lat: 0,
        lng: 0
      }
    }
  };

  get accidentDetails(): MrfiktivCreateAccidentDetailsDtoGen {
    return this._accidentDetails;
  }

  private _preExistingDamageDetails = new PreExistingDamageDetails();

  get preExistingDamageDetails(): IPreExistingDamageDetails {
    return this._preExistingDamageDetails;
  }

  @Action
  setPreExistingDamageDetails(preExistingDamageDetails: IPreExistingDamageDetails) {
    this.context.commit("updatePreExistingDamageDetails", preExistingDamageDetails);
  }

  @Mutation
  updatePreExistingDamageDetails(preExistingDamageDetails: IPreExistingDamageDetails) {
    this._preExistingDamageDetails = preExistingDamageDetails;
  }

  /**
   * registration informations
   */
  private _registration: MrfiktivRegistrationDtoGen = {
    firstname: "",
    name: "",
    street: "",
    zipCode: "",
    city: "",
    huYear: "",
    huMonth: "",
    manufacturerNameCode: "",
    manufacturerTypeCode: "",
    driveTyp: "",
    identificationnumber: "",
    numberplate: "",
    firstregistrationDay: "",
    firstregistrationMonth: "",
    firstregistrationYear: "",
    manufacturerName: "",
    manufacturerType: "",
    vehicleClass: ""
  };

  get registration(): MrfiktivRegistrationDtoGen {
    return this._registration;
  }

  set registration(registration: MrfiktivRegistrationDtoGen) {
    this.mutateRegistrationResults(registration);
  }

  /**
   * Companyname of the customer
   */
  private _companyName = "";

  get companyName(): string {
    return this._companyName;
  }

  /**
   * An external id that our clients wants to store to the report
   */
  private _externalId = "";

  get externalId(): string {
    return this._externalId;
  }

  _activeReportTypes = [
    ReportType.CAR,
    ReportType.CONTAINER,
    ReportType.MOTORBIKE,
    ReportType.CRANE,
    ReportType.TRUCK,
    ReportType.TRACTOR_UNIT,
    ReportType.TRAILER,
    ReportType.TRUCK_TRAILER,
    ReportType.SEMITRAILER,
    ReportType.BICYCLE,
    ReportType.OTHER
  ];

  get activeReportTypes(): ReportType[] {
    return this._activeReportTypes;
  }

  get defaulActiveReportType(): ReportType {
    if (this.activeReportTypes.length) {
      return this.activeReportTypes[0];
    }
    return ReportType.CAR;
  }

  /**
   * Is Damage
   */
  _isDamage = false;

  get isDamage(): boolean {
    return this._isDamage;
  }

  @Action
  setIsDamage(isDamage: boolean) {
    this.context.commit("updateIsDamage", isDamage);
  }

  @Mutation
  updateIsDamage(isDamage: boolean) {
    this._isDamage = isDamage;
  }

  /**
   * Customer is still at accident place
   */
  _isAtAccidentPlace: boolean | number = 0;

  get isAtAccidentPlace(): boolean | number {
    return this._isAtAccidentPlace;
  }

  @Action
  setIsAtAccidentPlace(isAtAccidentPlace: boolean | number) {
    this.context.commit("updateIsAtAccidentPlace", isAtAccidentPlace);
  }

  @Mutation
  updateIsAtAccidentPlace(isAtAccidentPlace: boolean | number) {
    this._isAtAccidentPlace = isAtAccidentPlace;
  }

  /**
   * Customer know the current position
   */
  _isAddress = true;

  get isAddress(): boolean {
    return this._isAddress;
  }

  @Action
  setIsAddress(isAddress: boolean) {
    this.context.commit("updateIsAddress", isAddress);
  }

  @Mutation
  updateIsAddress(isAddress: boolean) {
    this._isAddress = isAddress;
  }

  /**
   * Is E-mail adress set in closing alternative
   */
  _isEmail = false;

  get isEmail(): boolean {
    return this._isEmail;
  }

  @Action
  setIsEmail(isEmail: boolean) {
    this.context.commit("updateIsEmail", isEmail);
  }

  @Mutation
  updateIsEmail(isEmail: boolean) {
    this._isEmail = isEmail;
  }

  _wittnessList: MrfiktivCreateReportWittnesDtoGen[] = [];

  get wittnessList(): MrfiktivCreateReportWittnesDtoGen[] {
    return this._wittnessList;
  }

  @Action
  setWitnessList(witnesLists: MrfiktivCreateReportWittnesDtoGen[]) {
    this.context.commit("updateWitnessList", witnesLists);
  }

  @Mutation
  updateWitnessList(witnessLists: MrfiktivCreateReportWittnesDtoGen[]) {
    this._wittnessList = witnessLists;
  }

  _insuranceList: MrfiktivCreateInsuranceDtoGen[] = [];

  get insuranceList(): MrfiktivCreateInsuranceDtoGen[] {
    return this._insuranceList;
  }

  @Action
  setInsuranceList(list: MrfiktivCreateInsuranceDtoGen[]) {
    this.context.commit("updateInsuranceList", list);
  }

  @Mutation
  updateInsuranceList(list: MrfiktivCreateInsuranceDtoGen[]) {
    this._insuranceList = list;
  }

  _coverage: CoverageEnum | null = null;

  get coverage(): CoverageEnum | null {
    return this._coverage;
  }

  @Action
  setCoverage(coverage: CoverageEnum | null) {
    this.context.commit("updateCoverage", coverage);
  }

  @Mutation
  updateCoverage(coverage: CoverageEnum) {
    this._coverage = coverage;
  }

  _body: { [x: string]: any }[] = [];

  get body(): { [x: string]: any }[] {
    return this._body;
  }

  @Mutation
  updateOrInsertBodyObject(bodyObject: { [x: string]: any }) {
    const key = Object.keys(bodyObject)[0];

    const foundItems = this._body.filter(item => Object.keys(item)[0] == key);

    if (foundItems.length) {
      foundItems[0] = bodyObject;
    } else {
      this._body.push(bodyObject);
    }
  }

  _liableWitness?: MrfiktivCreateReportWittnesDtoGen | undefined = undefined;

  get liableWitness(): MrfiktivCreateReportWittnesDtoGen | undefined {
    return this._liableWitness;
  }

  @Action
  setLiableWitness(liableWitness?: MrfiktivCreateReportWittnesDtoGen | undefined) {
    this.context.commit("updateLiableWitness", liableWitness);
  }

  @Mutation
  updateLiableWitness(liableWitness?: MrfiktivCreateReportWittnesDtoGen) {
    this._liableWitness = liableWitness;
  }

  @Action
  setLeasing(leasing: MrfiktivLeasingDetailsGen | undefined) {
    this.context.commit("updateLeasing", leasing);
  }

  @Mutation
  updateLeasing(leasing: MrfiktivLeasingDetailsGen | undefined) {
    this._leasing = leasing;
  }

  @Action
  setReportType(reportType: ReportType) {
    this.context.commit("updateReportType", reportType);
  }

  @Mutation
  updateReportType(reportType: ReportType) {
    this._reportType = reportType;
  }

  @Action
  setActiveReportTypes(reportTypes: ReportType[]) {
    this.context.commit("updateActiveReportTypes", reportTypes);
  }

  @Mutation
  updateActiveReportTypes(reportTypes: ReportType[]) {
    this._activeReportTypes = reportTypes;
  }

  @Mutation
  _mutateKsrData(data: IKsrData) {
    if (data.customerIdentityToken) {
      this._ksrData.customerIdentityToken = data.customerIdentityToken;
    }
    if (data.numberPlate) {
      this._ksrData.numberPlate = data.numberPlate;
    }
    if (data.jobId) {
      this._ksrData.jobId = data.jobId;
    }
    if (data.contactId) {
      this._ksrData.contactId = data.contactId;
    }
    if (data.addressId) {
      this._ksrData.addressId = data.addressId;
    }
  }

  @Action
  setKsrData(data: IKsrData) {
    this.context.commit("_mutateKsrData", data);
  }

  @Action
  setCompanyName(companyName: string) {
    this.context.commit("updateCompanyName", companyName);
  }

  @Mutation
  updateCompanyName(companyName: string) {
    this._companyName = companyName;
  }

  @Action
  setNumberplate(numberplate: string) {
    this.context.commit("updateNumberplate", numberplate);
  }

  @Mutation
  updateNumberplate(numberplate: string) {
    this.numberplate = numberplate;
  }

  @Action
  setExternalId(id: string) {
    this.context.commit("updateExternalId", id);
  }

  @Mutation
  updateExternalId(id: string) {
    this._externalId = id;
  }

  @Action
  setDatePreference(date: string) {
    this.context.commit("updateDatePreference", date);
  }

  @Mutation
  updateDatePreference(date: string) {
    this.inCreationReport.datePreference = date;
  }

  @Action
  setReviewed(bool: boolean) {
    this.context.commit("updateReviewed", bool);
  }

  @Action
  setPrivacyCheck(bool: boolean) {
    this.context.commit("updatePrivacyCheck", bool);
  }

  @Action
  setMarketingContactAllowed(bool: boolean) {
    this.context.commit("updateMarketingContactAllowed", bool);
  }

  @Mutation
  updatePrivacyCheck(bool: boolean) {
    this.inCreationReport.isPrivacyChecked = bool;
  }

  @Mutation
  updateMarketingContactAllowed(bool: boolean) {
    this.inCreationReport.isMarketingOptIn = bool;
  }

  @Action
  setMessage(message: string) {
    this.inCreationReport.message = message;
  }

  @Action
  setUser(user: Partial<IUser>) {
    this.context.commit("updateUser", { ...this.user, ...user });
  }

  @Action
  setContact(contact: Partial<IContact>) {
    this.context.commit("updateContact", { ...this.contact, ...contact });
  }

  @Action
  setAddress(address: Partial<IAddress>) {
    this.context.commit("updateAddress", { ...this.address, ...address });
  }

  @Action
  setForwardedBy(partnerId: string) {
    this.context.commit("updateForwardedBy", partnerId);
  }

  @Mutation
  updateForwardedBy(partnerId: string) {
    this.inCreationReport.forwardedBy = partnerId;
  }

  @Mutation
  updateUser(user: IUser) {
    this.user = user;
  }

  @Mutation
  updateAddress(address: IAddress) {
    this.address = address;
  }

  @Mutation
  updateContact(contact: IContact) {
    this.contact = contact;
  }

  @Mutation
  mutateRegistrationResults(registration: MrfiktivRegistrationDtoGen) {
    this.context.commit("updateRegistrationResults", registration);
  }

  @Action
  setRegistrationResults(registration: MrfiktivRegistrationDtoGen) {
    this.context.commit("updateRegistrationResults", registration);
  }

  @Mutation
  updateRegistrationResults(registration: MrfiktivRegistrationDtoGen) {
    this._registration = registration;
  }

  @Mutation
  mutateAccidentDetails(accidentDetails: MrfiktivCreateAccidentDetailsDtoGen) {
    this.context.commit("updateAccidentDetails", accidentDetails);
  }

  @Action
  setAccidentDetails(accidentDetails: MrfiktivCreateAccidentDetailsDtoGen) {
    this.context.commit("updateAccidentDetails", accidentDetails);
  }

  @Mutation
  updateAccidentDetails(accidentDetails: MrfiktivCreateAccidentDetailsDtoGen) {
    this._accidentDetails = accidentDetails;
  }

  @Mutation
  mutatePoliceDetails(details: MrfiktivCreatePoliceDetailsDtoGen) {
    this.context.commit("updatePoliceDetails", details);
  }

  @Action
  setPoliceDetails(details: MrfiktivCreatePoliceDetailsDtoGen) {
    this.context.commit("updatePoliceDetails", details);
  }

  @Mutation
  updatePoliceDetails(details: MrfiktivCreatePoliceDetailsDtoGen) {
    this._policeDetails = details;
  }

  @Action
  pushDamageLocation(location: string) {
    // this.inCreationReport.damage.push(location as any);
    const existingDamageLocation = this.inCreationReport.damageLocation;
    existingDamageLocation.push(location as any);
    this.updateReport({ damageLocation: existingDamageLocation });
  }

  @Action
  popDamageLocation(location: string) {
    const index = this.inCreationReport.damageLocation.indexOf(location as any, 0);
    if (index > -1) {
      this.inCreationReport.damageLocation.splice(index, 1);
    }
    this.updateReport({ damageLocation: this.inCreationReport.damageLocation });
  }

  @Action
  clearDamageLocation() {
    const length = this.inCreationReport.damageLocation.length;
    this.inCreationReport.damageLocation.splice(0, length);
    this.updateReport({ damageLocation: this.inCreationReport.damageLocation });
  }

  @Action
  async addRegistration(file: File) {
    const imageName = uniqueImageName(file, this.registrations);
    file = new File([file], imageName, { type: file.type });

    const imageResult = await ReportService.addImage(file, ReportImageType.registration, this.partnerName);
    this.context.commit("addToRegistrations", imageResult);
  }

  @Action
  async removeRegistration(file: File): Promise<boolean> {
    this.context.commit("removeFromRegistrations", file);
    return true;
  }

  @Action
  async addCockpit(file: File) {
    const imageName = uniqueImageName(file, this.cockpits);
    file = new File([file], imageName, { type: file.type });

    const imageResult = await ReportService.addImage(file, ReportImageType.cockpit, this.partnerName);
    this.context.commit("addToCockpits", imageResult);
  }

  @Action
  async removeCockpit(file: File): Promise<boolean> {
    this.context.commit("removeFromCockpits", file);
    return true;
  }

  @Action
  async addDamage(file: File) {
    const imageName = uniqueImageName(file, this.damages);
    file = new File([file], imageName, { type: file.type });

    const imageResult = await ReportService.addImage(file, ReportImageType.damage, this.partnerName);
    this.context.commit("addToDamages", imageResult);
  }

  @Action
  async removeDamage(file: File): Promise<boolean> {
    this.context.commit("removeFromDamages", file);
    return true;
  }

  @Action
  async addDamageDetail(file: File) {
    const imageName = uniqueImageName(file, this.damagesDetail);
    file = new File([file], imageName, { type: file.type });

    const imageResult = await ReportService.addImage(file, ReportImageType.damageDetail, this.partnerName);
    this.context.commit("addToDamagesDetail", imageResult);
  }

  @Action
  async removeDamageDetail(file: File): Promise<boolean> {
    this.context.commit("removeFromDamagesDetail", file);
    return true;
  }

  @Action
  async addOverview(file: File) {
    const imageName = uniqueImageName(file, this.overviews);
    file = new File([file], imageName, { type: file.type });

    const imageResult = await ReportService.addImage(file, ReportImageType.overview, this.partnerName);
    this.context.commit("addToOverviews", imageResult);
  }

  /**
   * Uploads a file as an image with given type and pushes it into the overviews
   *
   * @param data.file the file to upload
   * @param data.type the type of image uploaded
   * @param data.type the type of image uploaded
   */
  @Action
  async addImage(data: { file: File; type: ReportImageType; categoryKey?: string }): Promise<IImageUploaded> {
    const imageName = uniqueImageName(data.file, this.overviews);
    data.file = new File([data.file], imageName, { type: data.file.type });

    const imageResult = await ReportService.addImage(data.file, data.type, this.partnerName, data.categoryKey);
    this.context.commit("addToOverviews", imageResult);
    return imageResult;
  }

  /**
   * Removes a file from the overviews depending on the file and the type
   *
   * @param data.file the file to remove from overviews
   * @param data.type the type of image to remove from overview
   */
  @Action
  async removeImage(data: { file: File; type: ReportImageType }) {
    const removed: IImageUploaded[] = [];
    const filtered = this.overviews.filter(x => {
      const canStay = !(x.file.name === data.file.name && x.type === data.type);

      if (!canStay) {
        removed.push(x);
      }

      return canStay;
    });

    this.context.commit("removeFromOverviewsByType", filtered);

    return removed;
  }

  @Action
  async removeOverview(file: File): Promise<boolean> {
    this.context.commit("removeFromOverviews", file);
    return true;
  }

  @Action
  async addOverviewFrontLeft(file: File) {
    const imageName = uniqueImageName(file, this.overviews);
    file = new File([file], imageName, { type: file.type });

    const imageResult = await ReportService.addImage(file, ReportImageType.overview, this.partnerName);
    this.context.commit("addToOverviewsFrontLeft", imageResult);
  }

  @Action
  async removeOverviewFrontLeft(file: File): Promise<boolean> {
    this.context.commit("removeFromOverviewsFrontLeft", file);
    return true;
  }

  @Action
  async addOverviewRearLeft(file: File) {
    const imageName = uniqueImageName(file, this.overviews);
    file = new File([file], imageName, { type: file.type });

    const imageResult = await ReportService.addImage(file, ReportImageType.overview, this.partnerName);
    this.context.commit("addToOverviewsRearLeft", imageResult);
  }

  @Action
  async removeOverviewRearLeft(file: File): Promise<boolean> {
    this.context.commit("removeFromOverviewsRearLeft", file);
    return true;
  }

  @Action
  async addOverviewFrontRight(file: File) {
    const imageName = uniqueImageName(file, this.overviews);
    file = new File([file], imageName, { type: file.type });

    const imageResult = await ReportService.addImage(file, ReportImageType.overview, this.partnerName);
    this.context.commit("addToOverviewsFrontRight", imageResult);
  }

  @Action
  async removeOverviewFrontRight(file: File): Promise<boolean> {
    this.context.commit("removeFromOverviewsFrontRight", file);
    return true;
  }

  @Action
  async addOverviewRearRight(file: File) {
    const imageName = uniqueImageName(file, this.overviews);
    file = new File([file], imageName, { type: file.type });

    const imageResult = await ReportService.addImage(file, ReportImageType.overview, this.partnerName);
    this.context.commit("addToOverviewsRearRight", imageResult);
  }

  @Action
  async removeOverviewRearRight(file: File): Promise<boolean> {
    this.context.commit("removeFromOverviewsRearRight", file);
    return true;
  }

  @Mutation
  updateRegistration(file: IImageUploaded[]) {
    this.registrations = file;
  }

  @Mutation
  addToRegistrations(file: IImageUploaded) {
    this.registrations.push(file);
  }

  @Mutation
  removeFromRegistrations(file: File) {
    Vue.$log.info(file);
    const filtered = this.registrations.filter(x => x.file.name !== file.name);
    this.registrations = filtered;
  }

  @Mutation
  updateCockpits(file: IImageUploaded[]) {
    this.cockpits = file;
  }

  @Mutation
  addToCockpits(file: IImageUploaded) {
    this.cockpits.push(file);
  }

  @Mutation
  removeFromCockpits(file: File) {
    const filtered = this.cockpits.filter(x => x.file.name !== file.name);
    this.cockpits = filtered;
  }

  @Mutation
  updateDamages(file: IImageUploaded[]) {
    this.damages = file;
  }

  @Mutation
  addToDamages(file: IImageUploaded) {
    this.damages.push(file);
  }

  @Mutation
  removeFromDamages(file: File) {
    const filtered = this.damages.filter(x => x.file.name !== file.name);
    this.damages = filtered;
  }

  @Mutation
  updateDamagesDetail(file: IImageUploaded[]) {
    this.damagesDetail = file;
  }

  @Mutation
  addToDamagesDetail(file: IImageUploaded) {
    this.damagesDetail.push(file);
  }

  @Mutation
  removeFromDamagesDetail(file: File) {
    const filtered = this.damagesDetail.filter(x => x.file.name !== file.name);
    this.damagesDetail = filtered;
  }

  @Mutation
  updateOverviews(file: IImageUploaded[]) {
    this.overviews = file;
  }

  @Mutation
  addToOverviews(file: IImageUploaded) {
    this.overviews.push(file);
  }

  @Mutation
  removeFromOverviews(file: File) {
    const filtered = this.overviews.filter(x => x.file.name !== file.name);
    this.overviews = filtered;
  }

  @Mutation
  removeFromOverviewsByType(filtered: IImageUploaded[]) {
    this.overviews.splice(0, this.overviews.length, ...filtered);
  }

  @Mutation
  addToOverviewsFrontLeft(file: IImageUploaded) {
    this.overviewsFrontLeft.push(file);
  }

  @Mutation
  removeFromOverviewsFrontLeft(file: File) {
    const filtered = this.overviewsFrontLeft.filter(x => x.file.name !== file.name);
    this.overviewsFrontLeft = filtered;
  }

  @Mutation
  updateOverviewsFrontLeft(file: IImageUploaded[]) {
    this.overviewsFrontLeft = file;
  }

  @Mutation
  addToOverviewsRearLeft(file: IImageUploaded) {
    this.overviewsRearLeft.push(file);
  }

  @Mutation
  removeFromOverviewsRearLeft(file: File) {
    const filtered = this.overviewsRearLeft.filter(x => x.file.name !== file.name);
    this.overviewsRearLeft = filtered;
  }

  @Mutation
  updateOverviewsRearLeft(file: IImageUploaded[]) {
    this.overviewsRearLeft = file;
  }

  @Mutation
  updateOverviewsFrontRight(file: IImageUploaded[]) {
    this.overviewsFrontRight = file;
  }

  @Mutation
  addToOverviewsFrontRight(file: IImageUploaded) {
    this.overviewsFrontRight.push(file);
  }

  @Mutation
  removeFromOverviewsFrontRight(file: File) {
    const filtered = this.overviewsFrontRight.filter(x => x.file.name !== file.name);
    this.overviewsFrontRight = filtered;
  }

  @Mutation
  addToOverviewsRearRight(file: IImageUploaded) {
    this.overviewsRearRight.push(file);
  }

  @Mutation
  removeFromOverviewsRearRight(file: File) {
    const filtered = this.overviewsRearRight.filter(x => x.file.name !== file.name);
    this.overviewsRearRight = filtered;
  }

  @Mutation
  updateOverviewsRearRight(file: IImageUploaded[]) {
    this.overviewsRearRight = file;
  }

  @Action
  async submit() {
    if (!ReportService) {
      ErrorLogModule.addErrorLog({
        name: "Sende Fehler",
        message: "Keine Verbindug erstellt, bitte Webseite neu laden"
      });
      throw "Error";
    }

    if (!this.inCreationReport.isPrivacyChecked) {
      Vue.$toast.warning("Bitte Datenschutzerklärung bestätigen.");
      throw "Datenschutzerklärung not approved.";
    }

    if (!this.inCreationReport) {
      Vue.$toast.warning("Schadensmeldung ist nicht vollständig ausgefüllt.");
      throw "CreateReport incomplete.";
    }

    this.inCreationReport.registrations = this.registrations;
    this.inCreationReport.cockpits = this.cockpits;
    this.inCreationReport.damages = this.damages;
    this.inCreationReport.damagesDetail = this.damagesDetail;

    if (this.inCreationReport.datePreference) {
      try {
        this.inCreationReport.datePreference = new Date(this.inCreationReport.datePreference).toISOString();
      } catch (error) {
        Vue.$log.error(error);
        throw new Error("DatePreference is not valid");
      }
    }

    this.inCreationReport.overviews = this.overviews;
    this.inCreationReport.overviews = this.inCreationReport.overviews.concat(this.overviewsFrontLeft);
    this.inCreationReport.overviews = this.inCreationReport.overviews.concat(this.overviewsFrontRight);
    this.inCreationReport.overviews = this.inCreationReport.overviews.concat(this.overviewsRearLeft);
    this.inCreationReport.overviews = this.inCreationReport.overviews.concat(this.overviewsRearRight);

    let accidentDetails = undefined;
    let policeDetails = undefined;
    let leasing = undefined;

    if (this.accidentDetails.date) {
      accidentDetails = this.accidentDetails;
    }

    if (this.policeDetails.isRecorded) {
      policeDetails = this.policeDetails;
    }

    if (this.leasing) {
      leasing = this.leasing;
    }

    const wittnessList: MrfiktivCreateReportWittnesDtoGen[] = [...this.wittnessList];
    if (this.liableWitness) {
      wittnessList.push(this.liableWitness);
    }

    const exports: MrfiktivExportReportMetaDtoGen[] = [];
    if (this.ksrData.jobId || this.ksrData.customerIdentityToken) {
      let exportReportMetaType = ExportReportMetaTypeEnum.KSR_NEW;
      if (this.ksrData.jobId) {
        exportReportMetaType = ExportReportMetaTypeEnum.KSR_EXISTING;
      }

      exports.push({
        exportReportMetaType: exportReportMetaType,
        meta: {
          customerIdentityToken: this.ksrData.customerIdentityToken,
          jobId: Number(this.ksrData.jobId),
          numberPlate: this.ksrData.numberPlate || this.numberplate,
          contactId: this.ksrData.contactId,
          addressId: this.ksrData.addressId
        }
      });
    }

    if (ExportModule.daMetaData.serviceEventId) {
      exports.push({
        exportReportMetaType: ExportReportMetaTypeEnum.DA_EXISTING,
        meta: ExportModule.daMetaData
      });
    }

    let createdReport: any;
    try {
      createdReport = await ReportService.create(
        this.inCreationReport,
        this.user,
        this.address,
        this.contact,
        this.numberplate,
        this.partnerName,
        this.reportType,
        wittnessList,
        this.insuranceList,
        this.body,
        this.isCompany,
        this.companyName,
        this.isTaxDeductible,
        this.taxnumber,
        this.externalId,
        VueI18n.locale as LanguageCodeEnum,
        policeDetails,
        accidentDetails,
        this.registration,
        leasing,
        this.preExistingDamageDetails,
        this.coverage,
        exports
      );
      Vue.$log.info(createdReport);
    } catch (error) {
      Vue.$log.error(error);

      throw error;
    }

    ExportModule.clearDaExportMeta();
    this.setKsrData({ jobId: undefined });

    try {
      // Create customer account.
      await CustomerAccountMeModule.create({});
    } catch (error) {
      Vue.$log.error(error);
    }

    this.reset();
  }

  @Action
  reset() {
    this.setStep(ReportScreenEnum.welcome);
    // CreateReport
    this.context.commit("updateInCreationReport", new CreateReport());

    // User
    this.context.commit("updateUser", new User());
    this.context.commit("updateAddress", new Address());
    this.context.commit("updateContact", new CreateContactDto());

    // Images
    this.context.commit("updateRegistration", []);
    this.context.commit("updateCockpits", []);
    this.context.commit("updateDamages", []);
    this.context.commit("updateDamagesDetail", []);
    this.context.commit("updateOverviews", []);
    this.context.commit("updateOverviewsFrontLeft", []);
    this.context.commit("updateOverviewsRearLeft", []);
    this.context.commit("updateOverviewsFrontRight", []);
    this.context.commit("updateOverviewsRearRight", []);
    this.context.commit("updateWitnessList", []);
    this.context.commit("updateInsuranceList", []);
    this.context.commit("updateIsCompany", false);
    this.context.commit("updateIsTaxDeductible", false);
    this.context.commit("updateTaxnumber", "");
    this.context.commit("updatePoliceDetails", {
      isRecorded: false,
      department: "",
      fileReference: ""
    });
    this.context.commit("updateLiableWitness", undefined);
    this.context.commit("updateLeasing", undefined);
    this.context.commit("updateIsDamage", false);
    this.context.commit("updateIsAddress", true);
    this.context.commit("updateIsAtAccidentPlace", 0);
    this.context.commit("updateExternalId", "");
    this.context.commit("updateCompanyName", "");
    this.context.commit("updateReportType", this.defaulActiveReportType);
    this.context.commit("updateCoverage", null);
    this.context.commit("updatePreExistingDamageDetails", new PreExistingDamageDetails());
    this.context.commit("updateAccidentDetails", {
      date: "",
      comment: "",
      role: "unclear",
      address: {
        street: "",
        zip: "",
        city: "",
        state: "",
        countryCode: CountryCodeEnum.germany,
        geo: {
          lat: 0,
          lng: 0
        }
      }
    });

    // Others
    this.setNumberplate("");
    this.setDatePreference("");
    this.setKsrData({
      jobId: undefined,
      customerIdentityToken: "",
      numberPlate: "",
      contactId: undefined,
      addressId: undefined
    });
  }

  @Action
  updateReport(report: Partial<ICreateReport>) {
    const reportMap = { ...this.inCreationReport, ...report };
    this.context.commit("updateInCreationReport", reportMap);
  }

  @Mutation
  updateInCreationReport(report: ICreateReport) {
    this.inCreationReport = report;
  }

  @Action
  setPartnerName(partnerName: string) {
    this.context.commit("updatePartnerName", partnerName);
  }

  @Action
  setPartnerByName(partner: MrfiktivPartnerViewModelGen) {
    this.context.commit("setPartner", partner);
    this.context.commit("updatePartnerName", partner.companyUsername);
  }

  @Action
  async getPartnerByName(partnerName: string) {
    if (!partnerName) {
      Vue.$toast.error("Fehler beim Laden des Partners.");
      throw new Error("Fehler beim Laden des Partners.");
    }
    try {
      const response = await partnerService.getPartnerByName(partnerName);
      const partnerData = response.data as PartnerEntity;
      this.context.commit("setPartner", partnerData);
      this.context.commit("updatePartnerName", partnerData.companyUsername);
    } catch (e) {
      if (e instanceof NotFoundException) {
        Vue.$toast.error(`Partner ${partnerName} nicht gefunden.`);
      }
      throw e;
    }
  }

  @Action
  async getPartnerById(partnerId: string) {
    if (!partnerId) {
      Vue.$toast.error("Fehler beim Laden des Partners.");
      throw new Error("Fehler beim Laden des Partners.");
    }
    try {
      const response = await partnerService.getPartnerById(partnerId);
      const partnerData = response.data as PartnerEntity;
      this.context.commit("setPartner", partnerData);
      this.context.commit("updatePartnerName", partnerData.companyUsername);
    } catch (e) {
      Vue.$toast.error(`Partner ${partnerId} nicht gefunden.`);
      throw e;
    }
  }

  @Action
  async getPartnerByHost(host: string) {
    if (!host) {
      Vue.$toast.error("Fehler beim Laden des Partners.");
      throw new Error("Fehler beim Laden des Partners.");
    }
    try {
      const response = await partnerService.getPartnerByHost(host);
      const partnerData = response.data as PartnerEntity;
      this.context.commit("setPartner", partnerData);
      this.context.commit("updatePartnerName", partnerData.companyUsername);
    } catch (e) {
      Vue.$toast.error(`Partner ${host} nicht gefunden.`);
      throw e;
    }
  }

  @Mutation
  setPartner(partner: PartnerEntity) {
    this.partner = partner;
    this._loading = false;
  }

  @Mutation
  updatePartnerName(partnerName: string) {
    this.partnerName = partnerName;
  }

  @Action
  setLoading(loading: boolean) {
    this.context.commit("updateLoading", loading);
  }

  @Action
  setPartnerDetails(partner: PartnerEntity) {
    this.context.commit("updatePartnerDetails", partner);
  }

  @Mutation
  updatePartnerDetails(partner: PartnerEntity) {
    this.partner = partner;
  }

  @Mutation
  updateLoading(loading: boolean) {
    this._loading = loading;
  }

  get loading() {
    return this._loading;
  }

  @Action
  setStep(screen: ReportScreenEnum) {
    this.context.commit("updateCurrentStep", screen);
  }

  @Mutation
  updateCurrentStep(screen: ReportScreenEnum) {
    this.currentStep = screen;
  }

  @Action
  async archiveManyReports(data: { partnerId: string; ids: string[] }) {
    const reports = await reportService.archiveMany(data.partnerId, data.ids);

    for (const report of reports) {
      FleetAggregationModule.replaceReport(report);
    }
  }
}

function skipScreensIfAutehnticated(screenOrder: ReportScreenEnum[], skipScreensIfAutehnticated: ReportScreenEnum[]) {
  if (UserModule.isAuthenticated) {
    for (const screenToSkip of skipScreensIfAutehnticated) {
      screenOrder = screenOrder.filter(e => e !== screenToSkip);
    }
  }

  return screenOrder;
}

export const ReportModule = getModule(ReportStore);
