import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { MrfiktivCustomFieldValueGen } from "@/services/mrfiktiv/v1/data-contracts";

@IsFilterable
class CustomFieldValueBase implements MrfiktivCustomFieldValueGen {
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.customField.id"
  })
  id: string;

  /**
   * The value of a custom field.
   * List of strings for multiple custom fields
   */
  @FilterConfig({
    type: FilterTypes.CUSTOM_FIELD_VALUE,
    displayName: "objects.customField.value"
  })
  value?: string | string[];

  timezone?: string | undefined;

  constructor(config?: Partial<ICustomFieldValue | ICustomFieldValue>) {
    this.id = config?.id ?? "";
    this.value = config?.value ?? "";
    this.timezone = config?.timezone;
  }

  /**
   * Multifield values are stored as individual objects in the backend and aggregated into a single object in the frontend
   * @param values
   */
  static buildCustomFieldValues(values: (MrfiktivCustomFieldValueGen | ICustomFieldValue)[]) {
    const mapped: Map<string, ICustomFieldValue[]> = new Map();

    for (const value of values) {
      const gotten = mapped.get(value.id ?? "") ?? [];
      gotten.push(new CustomFieldValueBase(value));
      mapped.set(value.id ?? "", gotten);
    }

    const res: ICustomFieldValue[] = [];

    for (const [id, fields] of mapped.entries()) {
      if (fields.length === 1) {
        res.push(fields[0]);
      } else {
        // map values of multi_field to a single value object
        const values: string[] = [];

        for (const field of fields) {
          if (!field.value) {
            continue;
          }

          if (typeof field.value === "string") {
            values.push(field.value as string);
          } else {
            values.push(...field.value);
          }
        }
        const customField = new CustomFieldValue({ id: id, value: values });
        res.push(customField);
      }
    }

    return res;
  }

  /**
   * Multifield values are stored as individual objects in the backend and aggregated into a single object in the frontend
   * @param values
   */
  static buildCustomFieldValuesDto(customFieldValues: ICustomFieldValue[]): MrfiktivCustomFieldValueGen[] {
    const dtos: MrfiktivCustomFieldValueGen[] = [];

    for (const customFieldValue of customFieldValues) {
      if (!customFieldValue.value) {
        continue;
      }

      if (typeof customFieldValue.value === "string") {
        dtos.push({
          id: customFieldValue.id,
          timezone: customFieldValue.timezone,
          value: customFieldValue.value
        });
      } else if (typeof customFieldValue.value === "boolean") {
        dtos.push({
          id: customFieldValue.id,
          timezone: customFieldValue.timezone,
          value: customFieldValue.value
        });
      } else {
        for (const value of customFieldValue.value) {
          dtos.push({
            id: customFieldValue.id,
            timezone: customFieldValue.timezone,
            value: value
          });
        }
      }
    }

    return dtos;
  }
}

type ICustomFieldValue = CustomFieldValueBase;
const CustomFieldValue = Filter.createForClass(CustomFieldValueBase);

export { ICustomFieldValue, CustomFieldValue };
