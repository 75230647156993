import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { IsCustomViewable } from "@/lib/interfaces/is-custom-viewable.interface";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { RulesEnum } from "@/lib/rules/rules.map";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import { IUpdateDto } from "@/lib/utility/data/update-dto.interface";
import { $t } from "@/lib/utility/t";
import personService from "@/services/mrfiktiv/services/person.service";
import {
  MrfiktivCompanyAddressViewModelGen,
  MrfiktivPersonEmailViewModelGen,
  MrfiktivPersonPhoneViewModelGen,
  MrfiktivPersonViewModelGen,
  MrfiktivCreatePersonDtoGen,
  MrfiktivUpdatePersonDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { PersonDataAccessLayer } from "@/store/modules/access-layers/person.access-layer";
import { ConfigModule } from "@/store/modules/config";
import Vue from "vue";
import { ActivityLog } from "./activity-log.entity";
import { CustomFieldValue, ICustomFieldValue } from "./custom-field-value.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { PersonEmail, IPersonEmail } from "./person-email.entity";
import { PersonPhone, IPersonPhone } from "./person-phone.entity";
import { CompanyAddress, ICompanyAddress } from "./company-address.entity";
import { PersonGroupModule } from "@/store/modules/person-group.store";
import { IReference, Reference } from "./reference.entity";
import { CompanyReference } from "./company-reference.entity";
import { BillingProfile, IBillingProfile } from "./banking-information.entity";
import VueRouter from "vue-router";
import { PersonGoToHelper } from "@/lib/utility/person.go-to-helper";
import { PersonLabelEnum } from "@/lib/enum/person-label.enum";

@IsFormable
@IsFilterable
export class PersonBase
  implements Omit<MrfiktivPersonViewModelGen, "values">, ICreateDto<IPerson>, IsCustomViewable, IUpdateDto<IPerson> {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.id",
    config: {
      itemCallback: () => PersonDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-person"
    }
  })
  id: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.person.partnerId"
  })
  partnerId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.person.description"
  })
  description?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "person.person",
    searchKeywords: ["objects.person.firstName", "person.person"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.person.firstName"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.person.firstName"
  })
  firstName?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "person.person",
    searchKeywords: ["objects.person.lastName", "person.person"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.person.lastName"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.person.lastName"
  })
  lastName?: string;

  @FormConfig({
    category: "person.person",
    searchKeywords: ["objects.person.language", "person.person"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      items: ConfigModule.availableLanguages.map(v => {
        return {
          text: $t(`enums.LanguageCodeEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value",
      label: "objects.person.language"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  @FilterConfig({
    displayName: "objects.person.language",
    type: FilterTypes.ENUM,
    config: {
      items: ConfigModule.availableLanguages.map(v => {
        return {
          text: $t(`enums.LanguageCodeEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  language: LanguageCodeEnum;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "person.personGroup",
    searchKeywords: ["objects.person.groupId", "person.personGroup"],
    type: DetailFormComponentsEnum.SELECT_ENTITY,
    props: {
      refType: BackendResourceEnum.PERSON_GROUP,
      label: "objects.person.groupId"
    },
    rules: [],
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.person.groupId",
    config: {
      itemCallback: () => PersonGroupModule.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-person-group"
    }
  })
  groupId?: string;

  @FilterConfig({ type: CustomFieldValue })
  values: ICustomFieldValue[] = [];

  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["common.nouns.address"],
    type: CompanyAddress,
    isArray: true,
    props: { label: "common.nouns.address" }
  })
  @FilterConfig({
    type: CompanyAddress
  })
  addresses: ICompanyAddress[];

  @FormConfig({
    category: "common.nouns.email",
    searchKeywords: ["common.nouns.email"],
    type: PersonEmail,
    isArray: true,
    props: { label: "common.nouns.email" }
  })
  @FilterConfig({
    type: PersonEmail
  })
  emails: IPersonEmail[];

  @FormConfig({
    category: "common.nouns.phone",
    searchKeywords: ["common.nouns.phone"],
    type: PersonPhone,
    isArray: true,
    props: { label: "common.nouns.phone" }
  })
  @FilterConfig({
    type: PersonPhone
  })
  phones: IPersonPhone[];

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  get titleReadable() {
    if (!this.firstName && !this.lastName) {
      return $t("person.noName");
    }

    return `${this.firstName ?? ""} ${this.lastName ?? ""}`;
  }

  loading = false;

  @FilterConfig({
    type: CompanyReference
  })
  @FilterConfig({
    type: Reference
  })
  refs: IReference[];

  bankingIds: string[];
  billingProfile: IBillingProfile;

  get primaryEmail() {
    if (!this.emails || this.emails.length === 0) {
      return "";
    }

    const mains = this.emails.find(e => e.label === PersonLabelEnum.MAIN);

    if (mains) {
      return mains.email;
    }

    return this.emails[0].email;
  }

  get primaryPhone() {
    if (!this.phones || this.phones.length === 0) {
      return "";
    }

    const mains = this.phones.find(e => e.label === PersonLabelEnum.MAIN);

    if (mains) {
      return mains.phone;
    }

    return this.phones[0].phone;
  }

  /**
   * Construct person
   */
  constructor(obj?: Partial<PersonBase | MrfiktivPersonViewModelGen>) {
    this.id = obj?.id ?? "";
    this.partnerId = obj?.partnerId ?? "";
    this.description = obj?.description;
    this.firstName = obj?.firstName;
    this.lastName = obj?.lastName;
    this.language = (obj?.language as LanguageCodeEnum) ?? LanguageCodeEnum.DE;
    this.groupId = obj?.groupId;
    this.values = CustomFieldValue.buildCustomFieldValues(obj?.values || []);
    this.addresses = (obj?.addresses as MrfiktivCompanyAddressViewModelGen[])?.map(a => new CompanyAddress(a));
    this.emails = (obj?.emails as MrfiktivPersonEmailViewModelGen[])?.map(a => new PersonEmail(a));
    this.phones = (obj?.phones as MrfiktivPersonPhoneViewModelGen[])?.map(a => new PersonPhone(a));
    this.refs = Reference.filterDuplicates((obj?.refs ?? []).map(ref => new Reference(ref)));
    this.bankingIds = obj?.bankingIds ?? [];
    this.billingProfile = new BillingProfile(obj?.billingProfile);
    this.timestamp = new Timestamp(obj?.timestamp);
  }

  /**
   * fetch person
   */
  async fetch(): Promise<this> {
    this.loading = true;

    try {
      const res = await personService.getOne(this.partnerId, this.id);

      this.map(res);
      PersonDataAccessLayer.set(this);
    } catch (e) {
      Vue.$log.error(e);
      this.loading = false;
    } finally {
      this.loading = false;
    }

    return this;
  }

  /**
   * map props from viewmodel to this
   */
  map(obj?: MrfiktivPersonViewModelGen) {
    if (!obj) return;

    this.id = obj.id ?? "";
    this.partnerId = obj.partnerId ?? "";
    this.description = obj.description;
    this.firstName = obj.firstName;
    this.lastName = obj.lastName;
    this.language = obj.language as LanguageCodeEnum;
    this.groupId = obj.groupId;
    this.values = CustomFieldValue.buildCustomFieldValues(obj?.values || []);
    this.addresses = (obj?.addresses as MrfiktivCompanyAddressViewModelGen[])?.map(a => new CompanyAddress(a));
    this.emails = (obj?.emails as MrfiktivPersonEmailViewModelGen[])?.map(a => new PersonEmail(a));
    this.phones = (obj?.phones as MrfiktivPersonPhoneViewModelGen[])?.map(a => new PersonPhone(a));
    this.refs = Reference.filterDuplicates((obj?.refs ?? []).map(ref => new Reference(ref)));
    this.bankingIds = obj?.bankingIds ?? [];
    this.billingProfile = new BillingProfile(obj?.billingProfile);
    this.timestamp = new Timestamp(obj.timestamp);
  }

  /**
   * create fetch person
   */
  async create() {
    const data: MrfiktivCreatePersonDtoGen = {
      description: this.description || undefined,
      firstName: this.firstName || undefined,
      lastName: this.lastName || undefined,
      language: this.language || undefined,
      groupId: this.groupId,
      values: CustomFieldValue.buildCustomFieldValuesDto(this.values || []),
      addresses: this.addresses || undefined,
      emails: this.emails || undefined,
      phones: this.phones || undefined,
      bankingIds: this.bankingIds || [],
      billingProfile: this.billingProfile.dto,
      refs: Reference.filterDuplicates(this.refs)
    };
    const res = await personService.create(this.partnerId, data);

    this.map(res);

    PersonDataAccessLayer.set(this);

    return this;
  }

  /**
   * delete person
   */
  async delete() {
    const res = await personService.delete(this.partnerId, this.id);

    this.map(res);
    PersonDataAccessLayer.delete(this);
  }

  /**
   * update person
   * @returns
   */
  async update() {
    const data: MrfiktivUpdatePersonDtoGen = {
      description: this.description || undefined,
      firstName: this.firstName || undefined,
      lastName: this.lastName || undefined,
      language: this.language || undefined,
      groupId: this.groupId,
      values: CustomFieldValue.buildCustomFieldValuesDto(this.values || []),
      addresses: this.addresses || undefined,
      emails: this.emails || undefined,
      phones: this.phones || undefined,
      bankingIds: this.bankingIds || [],
      billingProfile: this.billingProfile.dto,
      refs: Reference.filterDuplicates(this.refs)
    };
    const res = await personService.update(this.partnerId, this.id, data);
    this.map(res);
    PersonDataAccessLayer.set(this);

    return this;
  }

  /**
   * update person via dto
   * @param dto
   * @returns
   */
  async updatePartial(dto: MrfiktivUpdatePersonDtoGen) {
    const res = await personService.update(this.partnerId, this.id, dto);

    this.map(res);

    PersonDataAccessLayer.set(this);

    return this;
  }

  get isUpdateable() {
    return true;
  }

  /**
   * Create an activity log for assignee changes
   * @param newAssignees The ids of the new assignees of the ticket
   * @param activityType Whether the assignee was added or removed
   */
  async createAssigneeActivity(
    activityType: ActivityTypeEnum.CREATE_ASSIGNEE | ActivityTypeEnum.DELETE_ASSIGNEE,
    newAssignees?: string[]
  ) {
    if (!newAssignees?.length) return;

    await new ActivityLog().createAssigneeActivity({
      partnerId: this.partnerId,
      source: {
        refType: ResourceEnum.PERSON,
        refId: this.id
      },
      newAssignees,
      activityType
    });
  }

  goTo(router: VueRouter) {
    return {
      table: () =>
        new PersonGoToHelper(router).goToPersonTable({
          partnerId: this.partnerId
        }),
      detail: () =>
        new PersonGoToHelper(router).goToPersonDetail({
          partnerId: this.partnerId,
          personId: this.id
        }),
      form: () =>
        new PersonGoToHelper(router).goToPersonDetailForm({
          partnerId: this.partnerId,
          personId: this.id
        })
    };
  }
}

type IPerson = PersonBase;
const Person = Form.createForClass(Filter.createForClass(PersonBase));

export { Person, IPerson };
