import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { CompanyGroup } from "../v1/CompanyGroup";
import {
  MrfiktivCompanyGroupControllerGetParamsGen,
  MrfiktivCreateCompanyGroupDtoGen,
  MrfiktivUpdateCompanyGroupDtoGen
} from "../v1/data-contracts";

/**
 * Communicate with companyGroup API
 */
class CompanyGroupService {
  mrfiktivProxy: CompanyGroup;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new CompanyGroup(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create an companyGroup
   */
  async create(partnerId: string, data: MrfiktivCreateCompanyGroupDtoGen) {
    const companyGroup = (await this.mrfiktivProxy.companyGroupControllerCreate(partnerId, data)).data;

    return companyGroup;
  }

  /**
   * Get all companyGroup
   */
  async get(data: MrfiktivCompanyGroupControllerGetParamsGen) {
    const companyGroups = (await this.mrfiktivProxy.companyGroupControllerGet(data)).data;

    return companyGroups;
  }

  /**
   * Get a cucompanyGroupstom
   */
  async getOne(partnerId: string, companyGroupId: string) {
    const companyGroup = (await this.mrfiktivProxy.companyGroupControllerGetOne(partnerId, companyGroupId)).data;

    return companyGroup;
  }

  /**
   * Update a companyGroup
   */
  async update(partnerId: string, companyGroupId: string, data: MrfiktivUpdateCompanyGroupDtoGen) {
    const companyGroup = (await this.mrfiktivProxy.companyGroupControllerUpdate(partnerId, companyGroupId, data)).data;

    return companyGroup;
  }

  /**
   * Delete a companyGroup
   */
  async delete(partnerId: string, companyGroupId: string) {
    const companyGroup = (await this.mrfiktivProxy.companyGroupControllerDelete(partnerId, companyGroupId)).data;

    return companyGroup;
  }
}

/**
 * Communicate with companyGroup API
 */
export default new CompanyGroupService(new MrfiktivHttpClientProvider());
