import { MrfiktivVehicleRegistrationViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { RegistrationBase } from "./registration.entity";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { $t } from "@/lib/utility/t";
import { TransmissionTypeEnum } from "@/lib/enum/transmission.enum";
import { Filter, IsFilterable } from "@/lib/filterable";

@IsFilterable
@IsFormable
class VehicleRegistrationBase extends RegistrationBase implements MrfiktivVehicleRegistrationViewModelGen {
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.model", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.model"
    },
    clearable: true
  })
  model?: string = "";

  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.transmission", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.SELECT_FIELD,
    props: {
      items: [
        { text: $t("automatic"), value: TransmissionTypeEnum.AUTOMATIC },
        { text: $t("manual"), value: TransmissionTypeEnum.MANUAL }
      ],
      itemValue: "value",
      label: "objects.vehicle.registration.transmission"
    },
    clearable: true
  })
  transmission?: "automatic" | "manual" | "" = "";

  constructor(registration?: Partial<IVehicleRegistration>) {
    super(registration);

    this.transmission = registration?.transmission;
    this.model = registration?.model;
  }
}

type IVehicleRegistration = VehicleRegistrationBase;
const VehicleRegistration = Form.createForClass(Filter.createForClass(VehicleRegistrationBase));

export { IVehicleRegistration, VehicleRegistration };
