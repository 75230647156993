/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateGroupDtoGen,
  MrfiktivGroupControllerGetParamsGen,
  MrfiktivGroupViewModelGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateGroupDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Group<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags group
   * @name GroupControllerCreate
   * @summary (Group - CREATE) Create a Group
   * @request POST:/partner/{partnerId}/group
   * @secure
   * @response `201` `MrfiktivGroupViewModelGen` The created group
   */
  groupControllerCreate = (partnerId: string, data: MrfiktivCreateGroupDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivGroupViewModelGen, any>({
      path: `/partner/${partnerId}/group`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags group
   * @name GroupControllerGet
   * @summary (Group - READ) Get all companies
   * @request GET:/partner/{partnerId}/group
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivGroupViewModelGen)[] })`
   */
  groupControllerGet = ({ partnerId, ...query }: MrfiktivGroupControllerGetParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivGroupViewModelGen[] }, any>({
      path: `/partner/${partnerId}/group`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags group
   * @name GroupControllerGetOne
   * @summary (Group - READ) Get a group
   * @request GET:/partner/{partnerId}/group/{groupId}
   * @secure
   * @response `200` `MrfiktivGroupViewModelGen` The group
   */
  groupControllerGetOne = (partnerId: string, groupId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivGroupViewModelGen, any>({
      path: `/partner/${partnerId}/group/${groupId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags group
   * @name GroupControllerUpdate
   * @summary (Group - UPDATE) Updates a group
   * @request PATCH:/partner/{partnerId}/group/{groupId}
   * @secure
   * @response `201` `MrfiktivGroupViewModelGen` The updated group
   */
  groupControllerUpdate = (
    partnerId: string,
    groupId: string,
    data: MrfiktivUpdateGroupDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivGroupViewModelGen, any>({
      path: `/partner/${partnerId}/group/${groupId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags group
   * @name GroupControllerDelete
   * @summary (Group - DELETE) Deletes a group
   * @request DELETE:/partner/{partnerId}/group/{groupId}
   * @secure
   * @response `200` `MrfiktivGroupViewModelGen` The deleted group
   */
  groupControllerDelete = (partnerId: string, groupId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivGroupViewModelGen, any>({
      path: `/partner/${partnerId}/group/${groupId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
