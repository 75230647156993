import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { PersonGroup } from "../v1/PersonGroup";
import {
  MrfiktivPersonGroupControllerGetParamsGen,
  MrfiktivCreatePersonGroupDtoGen,
  MrfiktivUpdatePersonGroupDtoGen
} from "../v1/data-contracts";

/**
 * Communicate with personGroup API
 */
class PersonGroupService {
  mrfiktivProxy: PersonGroup;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new PersonGroup(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create an personGroup
   */
  async create(partnerId: string, data: MrfiktivCreatePersonGroupDtoGen) {
    const personGroup = (await this.mrfiktivProxy.personGroupControllerCreate(partnerId, data)).data;

    return personGroup;
  }

  /**
   * Get all personGroup
   */
  async get(data: MrfiktivPersonGroupControllerGetParamsGen) {
    const personGroups = (await this.mrfiktivProxy.personGroupControllerGet(data)).data;

    return personGroups;
  }

  /**
   * Get a cupersonGroupstom
   */
  async getOne(partnerId: string, personGroupId: string) {
    const personGroup = (await this.mrfiktivProxy.personGroupControllerGetOne(partnerId, personGroupId)).data;

    return personGroup;
  }

  /**
   * Update a personGroup
   */
  async update(partnerId: string, personGroupId: string, data: MrfiktivUpdatePersonGroupDtoGen) {
    const personGroup = (await this.mrfiktivProxy.personGroupControllerUpdate(partnerId, personGroupId, data)).data;

    return personGroup;
  }

  /**
   * Delete a personGroup
   */
  async delete(partnerId: string, personGroupId: string) {
    const personGroup = (await this.mrfiktivProxy.personGroupControllerDelete(partnerId, personGroupId)).data;

    return personGroup;
  }
}

/**
 * Communicate with personGroup API
 */
export default new PersonGroupService(new MrfiktivHttpClientProvider());
