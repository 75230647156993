import { ConfigModule } from "@/store/modules/config";
import { UserModule } from "@/store/modules/me-user.store";
import { ApplicationInsights, IConfig, IConfiguration } from "@microsoft/applicationinsights-web";
import VueRouter from "vue-router";

/**
 * Simple options interface to configure application insights.
 */
interface IAppInsightsOptions {
  /**
   * The actual ApplicationInsights instance.
   */
  appInsights: ApplicationInsights;

  /**
   * The app insights configuration to overwrite.
   */
  appInsightsConfig?: IConfiguration & IConfig;

  /**
   * The instrumentation key as a fallback, if not provided via appInsightsConfig.
   * Takes precedence over url but not over any provided appInsightsConfig.
   * Sets `instrumentationKey`.
   */
  id?: string;

  /**
   * The url to fetch the instrumentation key from.
   */
  url?: string;

  /**
   * Wether or not to use cookies via `disableCookiesUsage`.
   */
  cookie: boolean;

  /**
   * After script loaded callback.
   */
  onAfterScriptLoaded: (appInsights: ApplicationInsights) => void;

  /**
   * Router to watch for route changes.
   */
  router: VueRouter;

  /**
   * Track initial page view option.
   */
  trackInitialPageView: boolean;

  /**
   * Name to log in application insights as a base.
   */
  baseName: string;
}

/**
 * Updates user ID to make requests traceable and help with troubleshooting
 * To see this id in the logs look for dependencies.user_AccountId
 *
 * @param Vue
 * @param userId
 */
export function updateApplicationInsightsUserId(Vue: any, userId: string) {
  if (Vue.$appInsights) {
    Vue.$appInsights.context.user.accountId = userId;
  }
}

/**
 * Install function passed to Vue.use() show documentation on vue.js website.
 *
 * @see https://github.com/latelierco/vue-application-insights
 * @see https://github.com/microsoft/ApplicationInsights-JS
 * @see https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript
 * @param Vue - TODO: Not sure what this should be, PluginFunction or PluginObject?
 * @param options - The options to configure the plugin.
 */
async function install(Vue: any, options: IAppInsightsOptions) {
  // Default values for app insights passed directly to client.
  const config = options.appInsightsConfig || {};
  config.instrumentationKey = config.instrumentationKey || options.id;
  config.disableCookiesUsage = !options.cookie; // disable if cookie is set to false

  // If we provide no ID but url we fetch it from the backend.
  if (options.url) {
    await fetch(options.url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(data => {
        config.instrumentationKey = data.applicationInsightsKey;
      })
      .catch(error => {
        console.error(error);
      });
  }

  Vue.$log.info(options);
  Vue.$log.info(config);

  if (options.appInsights) {
    Vue.$appInsights = options.appInsights;
  } else {
    Vue.$appInsights = new ApplicationInsights({ config });
    Vue.$appInsights.loadAppInsights();
    Vue.$appInsights.context.application.ver = ConfigModule.appVersion;
    updateApplicationInsightsUserId(Vue, UserModule.userId);

    if (typeof options.onAfterScriptLoaded === "function") {
      options.onAfterScriptLoaded(Vue.$appInsights);
    }
  }

  const router = options.router;

  // Watch route event if router option is defined.
  if (router) {
    if (options.trackInitialPageView !== false) {
      setupPageTracking(options, Vue);
    } else {
      router.onReady(() => setupPageTracking(options, Vue));
    }
  }

  Object.defineProperty(Vue.prototype, "$appInsights", {
    get: () => Vue.$appInsights
  });
}

/**
 * Track route changes as page views with AppInsights
 * @param options
 */
function setupPageTracking(options: IAppInsightsOptions, Vue: Vue) {
  const router = options.router;

  const baseName = options.baseName || "(Vue App)";

  router.beforeEach((route, from, next) => {
    const name = baseName + " / " + route.name;
    Vue.$appInsights.startTrackPage(name);
    next();
  });

  router.afterEach(route => {
    const name = baseName + " / " + route.name;
    const url = location.protocol + "//" + location.host + route.fullPath;
    Vue.$appInsights.stopTrackPage(name, url);
    Vue.$appInsights.flush();
  });
}

// auto install for navigator
if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use(install);
}

export default install;
