/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivContractControllerGetParamsGen,
  MrfiktivContractViewModelGen,
  MrfiktivCreateContractDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateContractDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Contract<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags contract
   * @name ContractControllerCreate
   * @summary (Contract - CREATE) Create a Contract
   * @request POST:/partner/{partnerId}/contract
   * @secure
   * @response `201` `MrfiktivContractViewModelGen` The created contract
   */
  contractControllerCreate = (partnerId: string, data: MrfiktivCreateContractDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivContractViewModelGen, any>({
      path: `/partner/${partnerId}/contract`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags contract
   * @name ContractControllerGet
   * @summary (Contract - READ) Get all companies
   * @request GET:/partner/{partnerId}/contract
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivContractViewModelGen)[] })`
   */
  contractControllerGet = (
    { partnerId, ...query }: MrfiktivContractControllerGetParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivContractViewModelGen[] }, any>({
      path: `/partner/${partnerId}/contract`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags contract
   * @name ContractControllerGetOne
   * @summary (Contract - READ) Get a contract
   * @request GET:/partner/{partnerId}/contract/{contractId}
   * @secure
   * @response `200` `MrfiktivContractViewModelGen` The contract
   */
  contractControllerGetOne = (partnerId: string, contractId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivContractViewModelGen, any>({
      path: `/partner/${partnerId}/contract/${contractId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags contract
   * @name ContractControllerUpdate
   * @summary (Contract - UPDATE) Updates a contract
   * @request PATCH:/partner/{partnerId}/contract/{contractId}
   * @secure
   * @response `201` `MrfiktivContractViewModelGen` The updated contract
   */
  contractControllerUpdate = (
    partnerId: string,
    contractId: string,
    data: MrfiktivUpdateContractDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivContractViewModelGen, any>({
      path: `/partner/${partnerId}/contract/${contractId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags contract
   * @name ContractControllerDelete
   * @summary (Contract - DELETE) Deletes a contract
   * @request DELETE:/partner/{partnerId}/contract/{contractId}
   * @secure
   * @response `200` `MrfiktivContractViewModelGen` The deleted contract
   */
  contractControllerDelete = (partnerId: string, contractId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivContractViewModelGen, any>({
      path: `/partner/${partnerId}/contract/${contractId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
