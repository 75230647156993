/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgBankingViewModelGen,
  ThgCalculateEmissionSavingsDtoGen,
  ThgChargingStationProofDtoGen,
  ThgCreateAnonymousThgDtoGen,
  ThgCreateThgAndUserDtoGen,
  ThgCreateThgImageDtoGen,
  ThgCreateThgMeDtoGen,
  ThgCreateThgPartnerQuickCheckoutDtoGen,
  ThgCreateThgVehicleDtoGen,
  ThgDecommissionThgDtoGen,
  ThgDuplicateThgViewModelGen,
  ThgEditThgImageDataDtoGen,
  ThgExceptionViewmodelGen,
  ThgImageIdViewmodelGen,
  ThgOperationIdViewModelGen,
  ThgPageViewModelGen,
  ThgRegistrationDtoGen,
  ThgThgBonusViewmodelGen,
  ThgThgControllerAddThgParamsGen,
  ThgThgControllerCreateAnonymousParamsGen,
  ThgThgControllerFindAllAsAdminParamsGen,
  ThgThgControllerFindAllPaginatedParamsGen,
  ThgThgControllerFindAllParamsGen,
  ThgThgControllerFindByVinParamsGen,
  ThgThgControllerFindDupVinParamsGen,
  ThgThgControllerFindManyParamsGen,
  ThgThgControllerGetWorkItemParamsGen,
  ThgThgControllerQuickCheckoutAsAdminParamsGen,
  ThgThgControllerUpdateStatusAsyncParamsGen,
  ThgThgControllerUpdateStatusParamsGen,
  ThgThgControllerUploadImageQueryParamsGen,
  ThgThgMeViewModelGen,
  ThgThgViewModelGen,
  ThgThgViewModelPopulatedGen,
  ThgTransferThgDtoGen,
  ThgUpdateThgAdminDtoGen,
  ThgUpdateThgDtoGen,
  ThgUpdateThgRegistrationImagesDtoGen,
  ThgUpdateThgStatusDtoGen,
  ThgUpdateThgStatusOfThgIdsDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Thg<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description This is "anonymous", as we do not need a user session. <br/> The user is used by the username (by e-mail), or a new user is invited to the service. <br/><br/> The endpoint processes four steps in order : Lookup Partner > Create User > Create Banking > Create THG <br/> <ol> <li>Partner - checks if partner is valid</li> <li> User - checks if user exists, if not invite to platform.</li> <li>Banking (optional) - checks if user has banking information, if not create new</li> <li>THG - creates a new THG for the user</li> </li>
   *
   * @tags thg
   * @name ThgControllerCreateAnonymous
   * @summary Creates (a User with Banking and) a THG by user e-mail
   * @request POST:/partner/{partnerId}/thg/anonymous
   * @secure
   * @response `200` `ThgThgViewModelGen` The created THG
   * @response `400` `void` General error if request is not working.
   * @response `404` `void` Error if partner is not found.
   * @response `429` `void` Error if too many requests are run.
   * @response `500` `void` Server error if something went wrong on our side. Please reach out.
   */
  thgControllerCreateAnonymous = (
    { partnerId, ...query }: ThgThgControllerCreateAnonymousParamsGen,
    data: ThgCreateAnonymousThgDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/partner/${partnerId}/thg/anonymous`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerBonus
   * @summary Get the Thg Bonus.
   * @request GET:/thg/bonus
   * @response `200` `ThgThgBonusViewmodelGen` The Thg Bonus
   * @response `400` `void` General error if request is not working.
   */
  thgControllerBonus = (params: RequestParams = {}) =>
    this.http.request<ThgThgBonusViewmodelGen, void>({
      path: `/thg/bonus`,
      method: "GET",
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerFindMyThgs
   * @summary Get my registered Thgs.
   * @request GET:/thg/me
   * @secure
   * @response `200` `(ThgThgMeViewModelGen)[]` The id of the image
   * @response `400` `void` General error if request is not working.
   */
  thgControllerFindMyThgs = (params: RequestParams = {}) =>
    this.http.request<ThgThgMeViewModelGen[], void>({
      path: `/thg/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerCreateMyThg
   * @summary Create a thg from an existing thg.
   * @request POST:/thg/me/{thgId}
   * @secure
   * @response `200` `ThgThgViewModelGen` The id of the image
   * @response `400` `void` General error if request is not working.
   */
  thgControllerCreateMyThg = (thgId: string, data: ThgCreateThgMeDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/thg/me/${thgId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerGetMyThgById
   * @summary Update a specifc THG Document of my documents
   * @request GET:/thg/me/{thgId}
   * @secure
   * @response `200` `ThgThgViewModelGen` The updated THG Document
   * @response `400` `void` General error if request is not working.
   */
  thgControllerGetMyThgById = (thgId: string, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/thg/me/${thgId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerUpdateMyThg
   * @summary Update a specifc THG Document of my documents
   * @request PATCH:/thg/me/{thgId}
   * @secure
   * @response `200` `ThgThgViewModelGen` The updated THG Document
   * @response `400` `void` General error if request is not working.
   */
  thgControllerUpdateMyThg = (thgId: string, data: ThgUpdateThgDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/thg/me/${thgId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerDecommissionMyThg
   * @summary Decommission a vehicle (THG Document)
   * @request PATCH:/thg/me/{thgId}/decommission
   * @secure
   * @response `200` `ThgThgViewModelGen` The updated THG Document
   * @response `400` `void` General error if request is not working.
   */
  thgControllerDecommissionMyThg = (thgId: string, data: ThgDecommissionThgDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/thg/me/${thgId}/decommission`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerCalculateMyEmissionSavings
   * @summary Calculate Emission Savings for ghg
   * @request PATCH:/thg/me/{thgId}/calculate-emission-savings
   * @secure
   * @response `200` `ThgThgViewModelGen` The updated THG Document
   * @response `400` `void` General error if request is not working.
   */
  thgControllerCalculateMyEmissionSavings = (
    thgId: string,
    data: ThgCalculateEmissionSavingsDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/thg/me/${thgId}/calculate-emission-savings`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerRestoreTimestamp
   * @summary (ADMIN - TEMPORARY): Route to fix timestamp of thg. creates new timestamp where created date is derived from id
   * @request PATCH:/thg/{thgId}/admin-restore-timestamp
   * @secure
   * @response `200` `void`
   */
  thgControllerRestoreTimestamp = (thgId: string, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/thg/${thgId}/admin-restore-timestamp`,
      method: "PATCH",
      secure: true,
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerAddRegistrationToMyThg
   * @summary Update Registration Informations of my THG Document
   * @request POST:/thg/me/{thgId}/registration
   * @secure
   * @response `200` `ThgThgViewModelGen` The updatet THG Document
   * @response `400` `void` General error if request is not working.
   */
  thgControllerAddRegistrationToMyThg = (thgId: string, data: ThgRegistrationDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/thg/me/${thgId}/registration`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerUpdateMyRegistrationImages
   * @summary Update Registration Images of the Thg Document
   * @request PATCH:/thg/me/{thgId}/registrationImages
   * @secure
   * @response `200` `ThgThgViewModelGen` The updated thg document
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` Not Found.
   * @response `409` `ThgExceptionViewmodelGen` Conflict, e.g if image is already used or updating registration images is not allowed due to current status.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgControllerUpdateMyRegistrationImages = (
    thgId: string,
    data: ThgUpdateThgRegistrationImagesDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgViewModelGen, ThgExceptionViewmodelGen>({
      path: `/thg/me/${thgId}/registrationImages`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerUploadImageProof
   * @summary Submit a file proof for my thg
   * @request POST:/thg/me/{thgId}/proof
   * @secure
   * @response `400` `void` General error if request is not working.
   */
  thgControllerUploadImageProof = (thgId: string, data: ThgChargingStationProofDtoGen, params: RequestParams = {}) =>
    this.http.request<any, void>({
      path: `/thg/me/${thgId}/proof`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerUpdateStatusAsync
   * @summary Update Status of batch
   * @request PATCH:/thg/status/async
   * @secure
   * @response `200` `ThgOperationIdViewModelGen` The id of the operation
   */
  thgControllerUpdateStatusAsync = (
    query: ThgThgControllerUpdateStatusAsyncParamsGen,
    data: ThgUpdateThgStatusOfThgIdsDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgOperationIdViewModelGen, any>({
      path: `/thg/status/async`,
      method: "PATCH",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerEditRegistrationImages
   * @summary Update Registration Images of the Thg Document with crop and rotation information
   * @request PATCH:/partner/{partnerId}/thg/{thgId}/registrationImages/edit
   * @secure
   * @response `200` `ThgThgViewModelGen` The updated thg document
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` Not Found.
   * @response `409` `ThgExceptionViewmodelGen` Conflict, e.g if image is already used or updating registration images is not allowed due to current status.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgControllerEditRegistrationImages = (
    partnerId: string,
    thgId: string,
    data: ThgEditThgImageDataDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/thg/${thgId}/registrationImages/edit`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerFindMany
   * @summary (ADMIN) Get many THGs
   * @request GET:/thg/many
   * @secure
   * @response `200` `(ThgThgViewModelGen)[]` The found records
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgControllerFindMany = (query: ThgThgControllerFindManyParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen[], ThgExceptionViewmodelGen>({
      path: `/thg/many`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerFindAllAsAdmin
   * @summary Gets all Thgs (ADMIN Route)
   * @request GET:/thg/all
   * @secure
   * @response `200` `(ThgThgViewModelGen)[]` The found records
   */
  thgControllerFindAllAsAdmin = (query: ThgThgControllerFindAllAsAdminParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen[], any>({
      path: `/thg/all`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerGetWorkItem
   * @summary Get a Workitem to work on
   * @request GET:/thg/nextworkitem
   * @secure
   * @response `200` `ThgThgViewModelGen` The id of the image
   * @response `400` `void` General error if request is not working.
   */
  thgControllerGetWorkItem = (query: ThgThgControllerGetWorkItemParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/thg/nextworkitem`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerNewThg
   * @summary Register a User and a thg.
   * @request POST:/partner/{partnerId}/thg/new
   * @response `200` `ThgThgViewModelGen` The record has been successfully created
   * @response `403` `void` Forbidden.
   * @response `409` `void` User already has an account
   */
  thgControllerNewThg = (partnerId: string, data: ThgCreateThgAndUserDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/partner/${partnerId}/thg/new`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerAddThg
   * @summary Register an additional thg
   * @request POST:/partner/{partnerId}/thg
   * @secure
   * @response `200` `ThgThgViewModelGen` The record has been successfully created
   * @response `403` `void` Forbidden.
   */
  thgControllerAddThg = (
    { partnerId, ...query }: ThgThgControllerAddThgParamsGen,
    data: ThgCreateThgVehicleDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/partner/${partnerId}/thg`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerFindAll
   * @summary Gets all THGs paginated for a given partner
   * @request GET:/partner/{partnerId}/thg
   * @secure
   * @response `200` `((ThgPageViewModelGen & { data?: (ThgThgViewModelPopulatedGen)[] }) | (ThgThgViewModelGen)[])`
   * @response `403` `void` Forbidden.
   */
  thgControllerFindAll = ({ partnerId, ...query }: ThgThgControllerFindAllParamsGen, params: RequestParams = {}) =>
    this.http.request<(ThgPageViewModelGen & { data?: ThgThgViewModelPopulatedGen[] }) | ThgThgViewModelGen[], void>({
      path: `/partner/${partnerId}/thg`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerUploadImageQuery
   * @summary Submit image for an registration
   * @request POST:/partner/{partnerId}/thg/addimage
   * @response `200` `ThgImageIdViewmodelGen` The id of the image
   * @response `400` `void` General error if request is not working.
   * @response `404` `void` No partner found.
   */
  thgControllerUploadImageQuery = (
    { partnerId, ...query }: ThgThgControllerUploadImageQueryParamsGen,
    data: ThgCreateThgImageDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgImageIdViewmodelGen, void>({
      path: `/partner/${partnerId}/thg/addimage`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerFindAllPaginated
   * @summary (ADMIN) Gets all thgs for all partners paginated
   * @request GET:/thg
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgThgViewModelPopulatedGen)[] })`
   * @response `403` `void` Forbidden.
   */
  thgControllerFindAllPaginated = (query: ThgThgControllerFindAllPaginatedParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgThgViewModelPopulatedGen[] }, void>({
      path: `/thg`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerFindDupVin
   * @summary (ADMIN) Returns vins with duplicate count filtered by year and conversion status
   * @request GET:/thg/dup
   * @secure
   * @response `200` `(ThgDuplicateThgViewModelGen)[]` A list of vins to count
   * @response `403` `void` Forbidden.
   */
  thgControllerFindDupVin = (query: ThgThgControllerFindDupVinParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgDuplicateThgViewModelGen[], void>({
      path: `/thg/dup`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerFindByVin
   * @summary (ADMIN) Gets a thg by vin
   * @request GET:/thg/{vin}
   * @secure
   * @response `200` `(ThgThgViewModelGen)[]` The found records
   * @response `403` `void` Forbidden.
   */
  thgControllerFindByVin = ({ vin, ...query }: ThgThgControllerFindByVinParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen[], void>({
      path: `/thg/${vin}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerFind
   * @summary Gets a specific Thg
   * @request GET:/partner/{partnerId}/thg/{thgId}
   * @secure
   * @response `200` `ThgThgViewModelGen` The requested thg document
   * @response `403` `void` Forbidden.
   */
  thgControllerFind = (partnerId: string, thgId: string, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/partner/${partnerId}/thg/${thgId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerQuickCheckoutAsAdmin
   * @summary Create a thg from an existing thg.
   * @request POST:/partner/{partnerId}/thg/{thgId}
   * @secure
   * @response `200` `ThgThgViewModelGen` The id of the image
   */
  thgControllerQuickCheckoutAsAdmin = (
    { thgId, partnerId, ...query }: ThgThgControllerQuickCheckoutAsAdminParamsGen,
    data: ThgCreateThgPartnerQuickCheckoutDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgViewModelGen, any>({
      path: `/partner/${partnerId}/thg/${thgId}`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerUpdate
   * @summary Updates a specific Thg
   * @request PATCH:/partner/{partnerId}/thg/{thgId}
   * @secure
   * @response `200` `ThgThgViewModelGen` The updated thg document
   * @response `403` `void` Forbidden.
   */
  thgControllerUpdate = (partnerId: string, thgId: string, data: ThgUpdateThgAdminDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/partner/${partnerId}/thg/${thgId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerUploadImageProofAdmin
   * @summary Submit a file proof for a thg
   * @request POST:/partner/{partnerId}/thg/{thgId}/proof
   * @secure
   * @response `400` `void` General error if request is not working.
   */
  thgControllerUploadImageProofAdmin = (
    partnerId: string,
    thgId: string,
    data: ThgChargingStationProofDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<any, void>({
      path: `/partner/${partnerId}/thg/${thgId}/proof`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerSyncVehicleOnThg
   * @summary (ADMIN) Syncs vehicle of ghgs
   * @request POST:/partner/{partnerId}/thg/{thgId}/vehicle/sync
   * @secure
   * @response `200` `ThgThgViewModelGen` The found records
   * @response `403` `void` Forbidden.
   */
  thgControllerSyncVehicleOnThg = (partnerId: string, thgId: string, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/partner/${partnerId}/thg/${thgId}/vehicle/sync`,
      method: "POST",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerDecommission
   * @summary Decommission a specific Thg
   * @request PATCH:/partner/{partnerId}/thg/{thgId}/decommission
   * @secure
   * @response `200` `ThgThgViewModelGen` The updated thg document
   * @response `403` `void` Forbidden.
   */
  thgControllerDecommission = (
    partnerId: string,
    thgId: string,
    data: ThgDecommissionThgDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/partner/${partnerId}/thg/${thgId}/decommission`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerCalculateEmissionSavings
   * @summary Calculate emissions savings for a specific Thg
   * @request PATCH:/partner/{partnerId}/thg/{thgId}/calculate-emission-savings
   * @secure
   * @response `200` `ThgThgViewModelGen` The updated thg document
   * @response `403` `void` Forbidden.
   */
  thgControllerCalculateEmissionSavings = (
    partnerId: string,
    thgId: string,
    data: ThgCalculateEmissionSavingsDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/partner/${partnerId}/thg/${thgId}/calculate-emission-savings`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerTransfer
   * @summary Transfer a THG-Document to another partner or user
   * @request PATCH:/partner/{partnerId}/thg/{thgId}/transfer
   * @secure
   * @response `200` `ThgThgViewModelGen` The transfered thg document
   * @response `403` `void` Forbidden.
   */
  thgControllerTransfer = (partnerId: string, thgId: string, data: ThgTransferThgDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/partner/${partnerId}/thg/${thgId}/transfer`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerGetBankingInformation
   * @summary Gets a specific Bankinginformation to a THG Document
   * @request GET:/partner/{partnerId}/thg/{thgId}/banking
   * @secure
   * @response `200` `ThgBankingViewModelGen` The requested bankingdocument asigend to the user
   * @response `403` `void` Forbidden.
   */
  thgControllerGetBankingInformation = (partnerId: string, thgId: string, params: RequestParams = {}) =>
    this.http.request<ThgBankingViewModelGen, void>({
      path: `/partner/${partnerId}/thg/${thgId}/banking`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerUpdateStatus
   * @summary Change the Status of a ThgDocument
   * @request PATCH:/partner/{partnerId}/thg/{thgId}/status
   * @secure
   * @response `200` `ThgThgViewModelGen` The requested thg document
   * @response `403` `void` Forbidden.
   */
  thgControllerUpdateStatus = (
    { partnerId, thgId, ...query }: ThgThgControllerUpdateStatusParamsGen,
    data: ThgUpdateThgStatusDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/partner/${partnerId}/thg/${thgId}/status`,
      method: "PATCH",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerAddRegistration
   * @summary Update Registration Information of the Thg Document
   * @request PATCH:/partner/{partnerId}/thg/{thgId}/registration
   * @secure
   * @response `200` `ThgThgViewModelGen` The updated thg document
   * @response `403` `void` Forbidden.
   */
  thgControllerAddRegistration = (
    partnerId: string,
    thgId: string,
    data: ThgRegistrationDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgViewModelGen, void>({
      path: `/partner/${partnerId}/thg/${thgId}/registration`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thg
   * @name ThgControllerUpdateRegistrationImages
   * @summary Update Registration Images of the Thg Document
   * @request PATCH:/partner/{partnerId}/thg/{thgId}/registrationimages
   * @secure
   * @response `200` `ThgThgViewModelGen` The updated thg document
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` Not Found.
   * @response `409` `ThgExceptionViewmodelGen` Conflict, e.g if image is already used.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgControllerUpdateRegistrationImages = (
    partnerId: string,
    thgId: string,
    data: ThgUpdateThgRegistrationImagesDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/thg/${thgId}/registrationimages`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
