import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ThgActivePriceViewModelGen } from "@/services/thg/v1/data-contracts";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";
import { ReportModule } from "@/store/modules/report.store";

export interface IPriceConfig {
  title?: string;
  subtitle?: string;
  description?: string;
  hint?: string;
  year: number;
  vehicleClass: ThgVehicleTypes;
  isPrice: boolean;
  isFixed: boolean;
  totalAmount: number;
  pricePerMWh: number;
  mwh: number;
  prefix: string;
  paymentDate: {
    token: string;
    values: { year: number };
  };
  paymentDateLong: {
    token: string;
    values: { year: number };
  };
  totalAmountAsString: string;
}

/**
 * @inheritdoc
 */
export class ActivePrice {
  /**
   * Creates A active price instance
   */
  constructor(prices: ThgActivePriceViewModelGen[], country?: CountryCodeEnum) {
    this._prices = prices;
    this._countryCode = country || (ReportModule.partner.countryCode as CountryCodeEnum);
  }
  /**
   * Active prices
   */
  private _prices: ThgActivePriceViewModelGen[] = [];

  /**
   * Active Country
   */
  private _countryCode: CountryCodeEnum;

  /**
   * TODO: get this from backend
   * 'vehicle-type-kwh.map'
   * amount in kwh per country and car
   */
  private _vehicleClassMwhMap = [
    {
      countryCode: CountryCodeEnum.germany,
      vehicleType: ThgVehicleTypes.M1,
      amountInKwh: 2000,
      title: "report.thgVehicleClass.classes.de.M1.title",
      classSpeaking: "report.thgVehicleClass.classes.de.M1.classSpeaking",
      hint: "report.thgVehicleClass.classes.de.M1.hint",
      src: "https://static.mmmint.ai/public-thg/2023-09-14-logo-f3788982c40337cc4a923577.jpg",
      isBestSeller: true
    },
    {
      countryCode: CountryCodeEnum.germany,
      vehicleType: ThgVehicleTypes.N1,
      amountInKwh: 3000,
      title: "report.thgVehicleClass.classes.de.N1.title",
      classSpeaking: "report.thgVehicleClass.classes.de.N1.classSpeaking",
      hint: "report.thgVehicleClass.classes.de.N1.hint",
      src: "https://static.mmmint.ai/public-thg/2023-09-14-logo-074955d712439753ab46a14d.jpg",
      isBestSeller: true
    },
    {
      countryCode: CountryCodeEnum.germany,
      vehicleType: ThgVehicleTypes.other,
      amountInKwh: 2000,
      title: "report.thgVehicleClass.classes.de.other.title",
      classSpeaking: "report.thgVehicleClass.classes.de.other.classSpeaking",
      hint: "report.thgVehicleClass.classes.de.other.hint",
      src: "https://static.mmmint.ai/public-thg/2023-09-14-logo-e6d545957c8e9e278d8ab9eb.jpg",
      isBestSeller: true
    },

    {
      countryCode: CountryCodeEnum.germany,
      vehicleType: ThgVehicleTypes.N2,
      amountInKwh: 20600,
      title: "report.thgVehicleClass.classes.de.N2.title",
      classSpeaking: "report.thgVehicleClass.classes.de.N2.classSpeaking",
      hint: "report.thgVehicleClass.classes.de.N2.hint",
      src: "https://static.mmmint.ai/public-thg/2023-09-14-logo-7d7d49db7b71cda1662f89e4.jpg",
      isBestSeller: false
    },
    {
      countryCode: CountryCodeEnum.germany,
      vehicleType: ThgVehicleTypes.N3,
      amountInKwh: 33400,
      title: "report.thgVehicleClass.classes.de.N3.title",
      classSpeaking: "report.thgVehicleClass.classes.de.N3.classSpeaking",
      hint: "report.thgVehicleClass.classes.de.N3.hint",
      src: "https://static.mmmint.ai/public-thg/2023-09-14-logo-7d7d49db7b71cda1662f89e4.jpg",
      isBestSeller: false
    },
    {
      countryCode: CountryCodeEnum.germany,
      vehicleType: ThgVehicleTypes.M3,
      amountInKwh: 72000,
      title: "report.thgVehicleClass.classes.de.M3.title",
      classSpeaking: "report.thgVehicleClass.classes.de.M3.classSpeaking",
      hint: "report.thgVehicleClass.classes.de.M3.hint",
      src: "https://static.mmmint.ai/public-thg/2023-09-14-logo-e5b05e82d5de069a173f037a.jpg",
      isBestSeller: false
    },
    {
      countryCode: CountryCodeEnum.austria,
      vehicleType: ThgVehicleTypes.M1,
      amountInKwh: 1500,
      title: "report.thgVehicleClass.classes.at.M1.title",
      classSpeaking: "report.thgVehicleClass.classes.at.M1.classSpeaking",
      hint: "report.thgVehicleClass.classes.at.M1.hint",
      src: "https://static.mmmint.ai/public-thg/2023-09-14-logo-f3788982c40337cc4a923577.jpg",
      isBestSeller: true
    }
  ];

  get vehicleClass() {
    return this._vehicleClassMwhMap.filter(vehicle => vehicle.countryCode === this._countryCode);
  }

  get vehicleClassBestSellers() {
    return this._vehicleClassMwhMap.filter(
      vehicle => vehicle.countryCode === this._countryCode && vehicle.isBestSeller
    );
  }

  get vehicleClassNoBestSellers() {
    return this._vehicleClassMwhMap.filter(
      vehicle => vehicle.countryCode === this._countryCode && !vehicle.isBestSeller
    );
  }

  get orderedPrice() {
    return this._prices.sort((a, b) => a.year - b.year);
  }

  /**
   *
   * Gets a price for year and vehicleclass
   *
   * @param options
   * @returns
   */
  public get(options: { year: number; vehicleClass: ThgVehicleTypes }): IPriceConfig {
    const pricePerMWh = this.pricePerMwh(options.year);
    const mwhPerVehicle = this.mwhPerVehicleType(options.vehicleClass);

    let pricePerMwhAsValue = 0;
    if (pricePerMWh) {
      pricePerMwhAsValue = pricePerMWh.value;
    }

    if (!pricePerMWh || !pricePerMwhAsValue) {
      return {
        title: pricePerMWh?.title,
        subtitle: pricePerMWh?.subtitle,
        description: pricePerMWh?.description,
        hint: pricePerMWh?.hint,
        year: options.year,
        vehicleClass: options.vehicleClass,
        isPrice: false,
        isFixed: false,
        totalAmount: 0,
        prefix: "report.thgVehicleClass.activePrice.unknown",
        paymentDate: this.getPaymentDate(options.year),
        paymentDateLong: this.getPaymentDate(options.year, false),
        totalAmountAsString: "",
        mwh: mwhPerVehicle,
        pricePerMWh: 0
      };
    }

    const totalAmount = pricePerMwhAsValue * mwhPerVehicle;
    return {
      title: pricePerMWh?.title,
      subtitle: pricePerMWh?.subtitle,
      description: pricePerMWh?.description,
      hint: pricePerMWh?.hint,
      year: options.year,
      vehicleClass: options.vehicleClass,
      isPrice: true,
      isFixed: pricePerMWh.isFixed,
      mwh: mwhPerVehicle,
      pricePerMWh: pricePerMWh.value,
      totalAmount: totalAmount,
      prefix: this.getPrefixAsString(pricePerMWh),
      paymentDate: this.getPaymentDate(options.year),
      paymentDateLong: this.getPaymentDate(options.year, false),
      totalAmountAsString: totalAmount.toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR"
      })
    };
  }

  public getYearCombinationsByVehicleClass(vehicleClass: ThgVehicleTypes) {
    const prices = [];
    const pricesCombi = [];
    const years = [];
    for (const price of this.orderedPrice) {
      const activePrice = this.get({ year: price.year, vehicleClass: vehicleClass });
      years.push(price.year);
      prices.push({
        years: [price.year],
        title: { token: "report.thgProducts.activePrice.ghg", values: { year: price.year } },
        prices: [activePrice]
      });
      pricesCombi.push(activePrice);
    }

    if (years.length > 1) {
      prices.unshift({
        years: years,
        title: { token: "report.thgProducts.activePrice.combi", values: undefined },
        prices: pricesCombi
      });
    }

    return prices;
  }

  vehicleClassInformation(type: ThgVehicleTypes) {
    const vehicle = this._vehicleClassMwhMap.find(
      vehicle => vehicle.countryCode === this._countryCode && vehicle.vehicleType === type
    );
    if (vehicle) {
      return vehicle;
    }
  }

  /**
   *
   * Returns price per mwh by year
   *
   * @param year
   * @returns
   */
  private pricePerMwh(year: number): ThgActivePriceViewModelGen | undefined {
    const price = this._prices.find(price => price.year === year);
    if (price) {
      return price;
    }
    return undefined;
  }

  mwhPerVehicleType(vehicleType: ThgVehicleTypes) {
    const mwhPerVehicle = this._vehicleClassMwhMap.find(
      vehicleTypeMwhMap =>
        vehicleTypeMwhMap.countryCode === this._countryCode && vehicleTypeMwhMap.vehicleType === vehicleType
    );
    if (mwhPerVehicle) {
      return mwhPerVehicle.amountInKwh / 1000;
    }
    return 0;
  }

  /**
   *
   * Gets the price prefix
   *
   * @param price
   * @returns
   */
  private getPrefixAsString(price: ThgActivePriceViewModelGen) {
    if (price.isFixed) {
      return "report.thgVehicleClass.activePrice.fix";
    }

    return "report.thgVehicleClass.activePrice.atLeast";
  }

  /**
   *
   * Gets the price prefix
   *
   * @param price
   * @returns
   */
  private getPaymentDate(year: number, long = true) {
    /**
     * TODO: Get this from price config
     */
    if (this._countryCode === CountryCodeEnum.austria) {
      return { token: "report.thgProducts.activePrice.date.at", values: { year: year + 1 } };
    }

    if (year === new Date().getFullYear()) {
      if (long) {
        return { token: "report.thgProducts.activePrice.date.currentYear.long", values: { year: year } };
      }
      return { token: "report.thgProducts.activePrice.date.currentYear.short", values: { year: year } };
    }

    if (long) {
      return { token: "report.thgProducts.activePrice.date.nextYear.long", values: { year: year } };
    }
    return { token: "report.thgProducts.activePrice.date.nextYear.short", values: { year: year } };
  }
}
