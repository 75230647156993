import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";
import VueRouter from "vue-router";
import { BaseGoToHelper } from "./goToHelper";
import { $t } from "./t";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ContractGoToHelper } from "./contract.go-to-helper";

export enum GroupRouteNames {
  GROUP_RESOURCE_TABLE = "GROUP_RESOURCE_TABLE",
  GROUP_RESOURCE_DETAIL = "GROUP_RESOURCE_DETAIL",
  GROUP_RESOURCE_CUSTOM_VIEW = "GROUP_RESOURCE_CUSTOM_VIEW"
}

type Tab = { newTab?: boolean };
type Partner = { partnerId: string };
type GroupType = { type: string };
type Group = { groupId: string };
type View = { viewId: string };
type Query = { query?: Record<string, string> };

export class GroupGoToHelper extends BaseGoToHelper {
  static get breadCrumbs() {
    const locations = GroupGoToHelper.locations;

    return {
      groupTypeTable: (d: Partner & GroupType & Query) => {
        return {
          text: $t(`group.${d.type}.plural`).toString(),
          exact: true,
          to: locations.groupTypeTable(d)
        } as IBreadcrumb;
      },
      groupTypeDetail: (d: Partner & GroupType & Group & Query) => {
        return {
          text: $t(`group.${d.type}.singular`).toString(),
          exact: true,
          to: locations.groupTypeDetail(d)
        } as IBreadcrumb;
      },
      groupTypeCustomView: (d: Partner & GroupType & Group & View & Query) => {
        return {
          text: $t(`group.${d.type}.singular`).toString(),
          exact: true,
          to: locations.groupTypeCustomView(d)
        } as IBreadcrumb;
      }
    };
  }

  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  static locations = {
    groupTypeTable: (d: Partner & GroupType & Query) => {
      return {
        name: GroupRouteNames.GROUP_RESOURCE_TABLE,
        params: { ...d, query: "" },
        query: d.query
      };
    },
    groupTypeDetail: (d: Partner & GroupType & Group & Query) => {
      return {
        name: GroupRouteNames.GROUP_RESOURCE_DETAIL,
        params: { ...d, query: "" },
        query: d.query
      };
    },
    groupTypeCustomView: (d: Partner & GroupType & Group & View & Query) => {
      return {
        name: GroupRouteNames.GROUP_RESOURCE_CUSTOM_VIEW,
        params: { ...d, query: "" },
        query: d.query
      };
    }
  };

  goToGroupTypeTable(d: Partner & GroupType & Query & Tab) {
    this.go(GroupGoToHelper.locations.groupTypeTable(d), d.newTab);
  }

  goToGroupTypeDetail(d: Partner & GroupType & Group & Query & Tab) {
    this.go(GroupGoToHelper.locations.groupTypeDetail(d), d.newTab);
  }

  goToGroupTypeCustomView(d: Partner & GroupType & Group & View & Query & Tab) {
    this.go(GroupGoToHelper.locations.groupTypeCustomView(d), d.newTab);
  }

  goToGroupTypeEntities(d: Partner & GroupType & Query) {
    switch (d.type) {
      case ResourceEnum.CONTRACT:
        new ContractGoToHelper(this.router).goToContractTable(d);
        break;
    }
  }
}
