import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivCreatePartnerBankingDtoGen,
  MrfiktivPartnerBankingControllerGetParamsGen,
  MrfiktivUpdatePartnerBankingDtoGen
} from "../v1/data-contracts";
import { PartnerBanking } from "../v1/PartnerBanking";

/**
 * Communicate with Partner Banking endpoints
 */
class PartnerBankingService {
  mrfiktivProxy: PartnerBanking;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new PartnerBanking(mrfiktivHttpClientProvider.client());
  }

  async create(partnerId: string, data: MrfiktivCreatePartnerBankingDtoGen) {
    return (await this.mrfiktivProxy.partnerBankingControllerCreate(partnerId, data)).data;
  }

  async findAll(params: MrfiktivPartnerBankingControllerGetParamsGen) {
    return (await this.mrfiktivProxy.partnerBankingControllerGet(params)).data;
  }

  async findOne(partnerId: string, partnerBankingId: string) {
    return (await this.mrfiktivProxy.partnerBankingControllerGetOne(partnerId, partnerBankingId)).data;
  }

  async update(partnerId: string, partnerBankingId: string, data: MrfiktivUpdatePartnerBankingDtoGen) {
    return (await this.mrfiktivProxy.partnerBankingControllerUpdate(partnerId, partnerBankingId, data)).data;
  }

  async remove(partnerId: string, partnerBankingId: string) {
    return (await this.mrfiktivProxy.partnerBankingControllerRemove(partnerId, partnerBankingId)).data;
  }
}

/**
 * Communicate with partnerBanking endpoints
 */
export default new PartnerBankingService(new MrfiktivHttpClientProvider());
