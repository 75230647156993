import { CountryCodeEnum } from "../enum/country-code.enum";
import { IUser } from "@/models/user.entity";
import {
  MrfiktivCreateUserDtoGen,
  MrfiktivUpdateUserDtoGen,
  MrfiktivUserViewmodelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { LanguageCodeEnum } from "../enum/language-code.enum";

export class UpdateUserDtoFactory {
  user: MrfiktivUpdateUserDtoGen = {};
  static emptyContact = { email: "", phone: "" };
  static emptyAddress = { city: "", state: "", street: "", zip: "" };

  createFromIUser(user: IUser | MrfiktivCreateUserDtoGen): MrfiktivUpdateUserDtoGen {
    if (user.firstName) {
      this.user.firstName = user.firstName;
    }

    if (user.lastName) {
      this.user.lastName = user.lastName;
    }

    if (user.contact) {
      this.user.contact = UpdateUserDtoFactory.emptyContact;
      if (user.contact.email) {
        this.user.contact.email = user?.contact?.email || user.userName;
      } else {
        this.user.contact.email = user.userName;
      }

      if (user.contact.phone) {
        this.user.contact.phone = user?.contact?.phone;
      }
    }

    if (user.address) {
      this.user.address = UpdateUserDtoFactory.emptyAddress;
      if (user?.address?.street) {
        this.user.address.street = user?.address?.street;
      }

      if (user?.address?.zip) {
        this.user.address.zip = user?.address?.zip;
      }

      if (user?.address?.city) {
        this.user.address.city = user?.address?.city;
      }
      if (user?.address?.state) {
        this.user.address.state = user?.address?.state;
      }

      if (user?.address?.countryCode) {
        this.user.address.countryCode = user?.address?.countryCode || CountryCodeEnum.germany;
      }
    }
    if (user.isCompany) {
      this.user.isCompany = user?.isCompany;

      if (user?.company) {
        this.user.company = user?.company;
      }

      if (user?.isTaxDeductible) {
        this.user.isTaxDeductible = user?.isTaxDeductible;

        if (user?.taxnumber) {
          this.user.taxnumber = user?.taxnumber;
        }
      } else {
        this.user.isTaxDeductible = false;
        this.user.taxnumber = "";
      }
    } else {
      this.user.isCompany = false;
      this.user.company = "";
      this.user.isTaxDeductible = false;
      this.user.taxnumber = "";
    }

    if (user?.isMarketingOptIn) {
      this.user.isMarketingOptIn = user?.isMarketingOptIn || false;
    }

    this.user.language = user?.language || LanguageCodeEnum.DE;

    return this.user;
  }

  /**
   * Initialize user info to be edited in a form from an existing user object.
   * Specifically, state logic on corporate client fields.
   * When a user is defined to be a company, is tax deductible and what happens if the tax number is required.
   * @param user user object
   * @param isTaxnumberRequired flag to indicate if the tax number must be present. If true, then the user must be a company
   * and a tax deductible.
   * @returns user to update object.
   */
  createInitialFromExistingUser(
    user: MrfiktivUserViewmodelGen,
    isTaxnumberRequired: boolean
  ): MrfiktivUpdateUserDtoGen {
    this.user = this.createFromIUser(user);

    if (user.company && user.isCompany === undefined) {
      this.user.isCompany = true;
    }

    /**
     * Set is tax deductible if either is not set by some reason or the tax number is required.
     */
    if ((user.company || user.isCompany) && user.isTaxDeductible === undefined) {
      this.user.isTaxDeductible = true;
    } else if ((user.company || user.isCompany) && user.isTaxDeductible === false && isTaxnumberRequired) {
      this.user.isTaxDeductible = true;
    }

    if (user.taxnumber) {
      this.user.isCompany = true;
      this.user.isTaxDeductible = true;
    }

    if (!this.user?.contact) {
      this.user.contact = UpdateUserDtoFactory.emptyContact;
    }

    if (!this.user?.contact?.email) {
      this.user.contact.email = "";
    }

    if (!this.user?.contact?.phone) {
      this.user.contact.phone = "";
    }

    if (!this.user?.address) {
      this.user.address = UpdateUserDtoFactory.emptyAddress;
    }

    if (!this.user?.address?.street) {
      this.user.address.street = "";
    }

    if (!this.user?.address?.state) {
      this.user.address.state = "";
    }

    if (!this.user?.address?.city) {
      this.user.address.city = "";
    }

    if (!this.user?.address?.zip) {
      this.user.address.zip = "";
    }

    if (!this.user?.address?.countryCode) {
      this.user.address.countryCode = CountryCodeEnum.germany;
    }

    return this.user;
  }
}
