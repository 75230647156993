import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { ICost } from "@/models/cost.entity";
import costService from "@/services/mrfiktiv/services/costService";
import { MrfiktivCostControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

export const CostPageDataProvider = new (class extends AbstractPageDataProvider<
  ICost,
  MrfiktivCostControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivCostControllerGetParamsGen): Promise<IPageViewModel<ICost>> {
    const res = await costService.get(query);

    const meta = res.meta;
    const data = (res.data ?? []) as ICost[];

    return { meta, data };
  }
})();
