import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IPersonGroup } from "@/models/person-group.entity";
import personGroupService from "@/services/mrfiktiv/services/person-group.service";
import { MrfiktivPersonGroupControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

export const PersonGroupPageDataProvider = new (class extends AbstractPageDataProvider<
  IPersonGroup,
  MrfiktivPersonGroupControllerGetParamsGen
> {
  itemsPerPage = 250;

  async getPage(query: MrfiktivPersonGroupControllerGetParamsGen): Promise<IPageViewModel<IPersonGroup>> {
    const res = await personGroupService.get(query);

    const meta = res.meta;
    const data = (res.data ?? []) as IPersonGroup[];

    return { meta, data };
  }
})();
