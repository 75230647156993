import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivCreateFleetDtoGen,
  MrfiktivHandoverControllerFindAllParamsGen,
  MrfiktivUpdateFleetDtoGen
} from "../v1/data-contracts";
import { Fleet } from "../v1/Fleet";

/**
 * Communicate with endpoints
 */
class FleetService {
  mrfiktivProxy: Fleet;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Fleet(mrfiktivHttpClientProvider.client());
  }

  async create(partnerId: string, data: MrfiktivCreateFleetDtoGen) {
    return (await this.mrfiktivProxy.fleetControllerCreate(partnerId, data)).data;
  }

  async findAll(params: MrfiktivHandoverControllerFindAllParamsGen) {
    return (await this.mrfiktivProxy.fleetControllerFindAll(params)).data;
  }

  async findOne(partnerId: string, id: string) {
    return (await this.mrfiktivProxy.fleetControllerFindOne(partnerId, id)).data;
  }

  async update(partnerId: string, id: string, data: MrfiktivUpdateFleetDtoGen) {
    return (await this.mrfiktivProxy.fleetControllerUpdate(partnerId, id, data)).data;
  }

  async remove(partnerId: string, id: string) {
    return (await this.mrfiktivProxy.fleetControllerDelete(partnerId, id)).data;
  }

  async findAllRelatedForFleet(partnerId: string, fleetId: string) {
    return (await this.mrfiktivProxy.fleetControllerFindAllRelatedForFleet(partnerId, fleetId)).data;
  }

  async findAllAncestorsForFleet(partnerId: string, fleetId: string) {
    return (await this.mrfiktivProxy.fleetControllerFindAllAncestorsForFleet(partnerId, fleetId)).data;
  }
}

/**
 * Communicate with handover endpoints
 */
export default new FleetService(new MrfiktivHttpClientProvider());
