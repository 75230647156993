import { DigitalSignatureRequestStatusEnum } from "@/lib/enum/digital-signature-request-status.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { GoToHelper } from "@/lib/utility/goToHelper";
import snapshotService from "@/services/mrfiktiv/services/snapshotService";
import {
  MrfiktivDocumentViewModelGen,
  MrfiktivSignSnapshotDtoGen,
  MrfiktivSnapshotSignerViewModelGen,
  MrfiktivSnapshotViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { HandoverDataAccessLayer } from "@/store/modules/access-layers/handover.access-layer";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import VueRouter from "vue-router";
import { HandoverSnapshot, IHandoverSnapshot } from "./handover-snapshot.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { HandoverSnapshotDataAccessLayer } from "@/store/modules/access-layers/snapshot.access-layer";
import { ActivityLog } from "./activity-log.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import Vue from "vue";

@IsFilterable
class SnapshotBase implements MrfiktivSnapshotViewModelGen {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.snapshot.id",
    config: {
      itemCallback: () => HandoverSnapshotDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-snapshot"
    }
  })
  id: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.handover.number"
  })
  number: number;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.handover.partnerId"
  })
  partnerId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.handover.id",
    config: {
      itemCallback: () => HandoverDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-handover"
    }
  })
  handoverId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: HandoverSnapshot
  })
  handover: IHandoverSnapshot;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.ticket.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId: string;

  @FilterConfig({
    type: FilterTypes.ENUM,
    displayName: "objects.shapshot.status",
    config: {
      items: Object.values(DigitalSignatureRequestStatusEnum).map(e => {
        return {
          text: `enums.DigitalSignatureRequestStatusEnum.${e}`,
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  status: DigitalSignatureRequestStatusEnum;

  /**
   * @inheritdoc
   */
  documentIds: string[];

  /**
   * @inheritdoc
   */
  documents: MrfiktivDocumentViewModelGen[] = [];

  signers?: MrfiktivSnapshotSignerViewModelGen[];

  /**
   * Construct handover
   */
  constructor(obj?: Partial<SnapshotBase | MrfiktivSnapshotViewModelGen>) {
    this.id = obj?.id ?? "";
    this.number = obj?.number ?? -1;

    this.partnerId = obj?.partnerId ?? "";
    this.handoverId = obj?.handoverId ?? "";
    this.handover = new HandoverSnapshot(obj?.handover);
    this.userId = obj?.userId ?? "";

    this.status = (obj?.status as DigitalSignatureRequestStatusEnum) ?? DigitalSignatureRequestStatusEnum.CREATED;

    this.documentIds = obj?.documentIds ?? [];
    this.documents = obj?.documents ?? [];
    this.timestamp = new Timestamp(obj?.timestamp);
    this.signers = obj?.signers;
  }

  /**
   * fetch handover
   */
  async fetch(): Promise<this> {
    const res = await snapshotService.findOneByHandoverId(this.partnerId, this.handoverId, this.id);

    this.map(res);
    HandoverSnapshotDataAccessLayer.set(this);

    return this;
  }

  /**
   * map props from viewmodel to this
   */
  private map(obj?: MrfiktivSnapshotViewModelGen) {
    if (!obj) return;
    this.id = obj.id;
    this.number = obj.number;
    this.status = obj.status as DigitalSignatureRequestStatusEnum;
    this.partnerId = obj.partnerId;
    this.handoverId = obj.handoverId;
    this.handover = new HandoverSnapshot(obj?.handover);
    this.timestamp = new Timestamp(obj.timestamp);
    this.documentIds = obj?.documentIds ?? [];
    this.documents = obj?.documents ?? [];
    this.signers = obj?.signers;
  }

  /**
   * create for handover
   */
  async create() {
    const res = await snapshotService.createForHandover(this.partnerId, this.handoverId);
    this.map(res);

    await new ActivityLog({
      partnerId: this.partnerId,
      target: [{ refType: (ResourceEnum.SNAPSHOT as unknown) as BackendResourceEnum, refId: this.id }],
      actionType: ActionEnum.CREATE,
      activity: ActivityTypeEnum.MANAGE_TARGETS_ON_SOURCE,
      source: { refId: this.handoverId, refType: BackendResourceEnum.HANDOVER },
      comment: `#${res.number}`
    })
      .create()
      .catch(Vue.$log.error);

    HandoverSnapshotDataAccessLayer.set(this);

    return this;
  }

  async sign(signDto: MrfiktivSignSnapshotDtoGen) {
    const res = await snapshotService.signSnapshot(this.partnerId, this.handoverId, this.id, signDto);

    this.map(res);

    HandoverSnapshotDataAccessLayer.set(this);

    return this;
  }

  goTo(router: VueRouter) {
    return { detail: new GoToHelper(router).goToSnapshotDetailView(this.partnerId, this.handoverId, this.id) };
  }
}

type ISnapshot = SnapshotBase;
const Snapshot = Filter.createForClass(SnapshotBase);

export { ISnapshot, Snapshot };
