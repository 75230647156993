import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { Booking, IBooking } from "@/models/booking.entity";
import bookingService from "@/services/booking/services/bookingService";
import { BookingBookingControllerFindAllByPartnerIdParamsGen } from "@/services/booking/v1/data-contracts";
import { Module, getModule } from "vuex-module-decorators";
import store from "../../store/VuexPlugin";
import { PaginatedBaseStore } from "../paginated-base.store";
import { BookingDataAccessLayer } from "./access-layers/booking.access-layer";

export const BookingPageDataProvider = new (class extends AbstractPageDataProvider<
  IBooking,
  BookingBookingControllerFindAllByPartnerIdParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: BookingBookingControllerFindAllByPartnerIdParamsGen): Promise<IPageViewModel<IBooking>> {
    const res = await bookingService.findAllByPartner(query);

    const data = (res.data ?? []) as IBooking[];
    const meta = res.meta;

    return { data, meta };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "booking",
  store
})
export class BookingStore extends PaginatedBaseStore<IBooking, BookingBookingControllerFindAllByPartnerIdParamsGen> {
  _data = BookingDataAccessLayer;
  _pageProvider = BookingPageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions = Booking.filterables;
}

export const BookingModule = getModule(BookingStore);
