import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { ICompanyGroup, CompanyGroup } from "@/models/company-group.entity";

export const CompanyGroupDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<ICompanyGroup, ""> {
  getIdentifier(entity: ICompanyGroup): string {
    return (entity as ICompanyGroup).id;
  }

  mapEntity(entity: ICompanyGroup): ICompanyGroup {
    return new CompanyGroup(entity);
  }
})();
