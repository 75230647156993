import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { ICompany } from "@/models/company.entity";
import companyService from "@/services/mrfiktiv/services/companyService";
import { MrfiktivCompanyControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

export const CompanyPageDataProvider = new (class extends AbstractPageDataProvider<
  ICompany,
  MrfiktivCompanyControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivCompanyControllerGetParamsGen): Promise<IPageViewModel<ICompany>> {
    const res = await companyService.get(query);

    const meta = res.meta;
    const data = (res.data ?? []) as ICompany[];

    return { meta, data };
  }
})();
