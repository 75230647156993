
































import { PersonGoToHelper } from "@/lib/utility/person.go-to-helper";
import { IPerson } from "@/models/person.entity";
import { Component, Prop } from "vue-property-decorator";
import BaseRef from "./BaseRef.vue";
import { IRefDetail } from "./RefsList.vue";

@Component({
  components: {},
  filters: {}
})
export default class RefsPerson extends BaseRef<IPerson> implements IRefDetail<IPerson> {
  @Prop()
  item!: IPerson;

  @Prop()
  small!: boolean;

  @Prop()
  confirmable!: boolean;

  async goToDetail() {
    const partnerId = this.item.partnerId;
    const personId = this.item.id;
    const newTab = false;

    new PersonGoToHelper(this.$router).goToPersonDetail({ personId, partnerId, newTab });
  }
}
