import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { CompanyServiceEnum } from "@/lib/enum/company-service.enum";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { IsCustomViewable } from "@/lib/interfaces/is-custom-viewable.interface";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { RulesEnum } from "@/lib/rules/rules.map";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import { IUpdateDto } from "@/lib/utility/data/update-dto.interface";
import { $t } from "@/lib/utility/t";
import companyService from "@/services/mrfiktiv/services/companyService";
import {
  MrfiktivCompanyAddressViewModelGen,
  MrfiktivCompanyViewModelGen,
  MrfiktivCompanyWithDistanceViewModelGen,
  MrfiktivCreateCompanyDtoGen,
  MrfiktivUpdateCompanyDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { CompanyGroupDataAccessLayer } from "@/store/modules/access-layers/company-group.access-layer";
import { CompanyDataAccessLayer } from "@/store/modules/access-layers/company.access-layer";
import { PartnerBankingDataAccessLayer } from "@/store/modules/access-layers/partner-banking.access-layer";
import { PartnerUserAccessLayer } from "@/store/modules/access-layers/partner-user.access-layer";
import { ConfigModule } from "@/store/modules/config";
import { latLng } from "leaflet";
import Vue from "vue";
import VueRouter from "vue-router";
import { ActivityLog } from "./activity-log.entity";
import { BillingProfile, IBillingProfile } from "./banking-information.entity";
import {
  CompanyAddress,
  CompanyAddressBilling,
  CompanyAddressDelivery,
  ICompanyAddress,
  ICompanyAddressBilling,
  ICompanyAddressDelivery
} from "./company-address.entity";
import { CustomFieldValue, ICustomFieldValue } from "./custom-field-value.entity";
import { IReference, Reference } from "./reference.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { Contact, IContact } from "./user-contact.entity";

@IsFormable
@IsFilterable
export class CompanyBase
  implements Omit<MrfiktivCompanyViewModelGen, "values">, ICreateDto<ICompany>, IsCustomViewable, IUpdateDto<ICompany> {
  /**
   * icons and color for companyservices
   */
  static serviceIconMapping = new Map<CompanyServiceEnum, { icon: string; color: string }>([
    [CompanyServiceEnum.WORKSHOP, { icon: "mdi-car-wrench", color: ConfigModule.color.analyticsColors[1] }],
    [CompanyServiceEnum.TOWING, { icon: "mdi-tow-truck", color: ConfigModule.color.analyticsColors[2] }],
    [CompanyServiceEnum.INSURANCE, { icon: "mdi-shield-car", color: ConfigModule.color.analyticsColors[7] }],
    [CompanyServiceEnum.LAWYER, { icon: "mdi-scale-balance", color: ConfigModule.color.analyticsColors[4] }],
    [CompanyServiceEnum.LEASING, { icon: "mdi-key-chain", color: ConfigModule.color.analyticsColors[5] }],
    [CompanyServiceEnum.RENTAL, { icon: "mdi-car-key", color: ConfigModule.color.analyticsColors[5] }],
    [CompanyServiceEnum.APPRAISER, { icon: "mdi-file-sign", color: ConfigModule.color.analyticsColors[6] }]
  ]);

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.id",
    config: {
      itemCallback: () => CompanyDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-company"
    }
  })
  id: string;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.company.userId",
    config: {
      itemCallback: () => PartnerUserAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.company.partnerId"
  })
  partnerId: string;

  /** Internal description of the company object */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.company.description"
  })
  description?: string;

  @FilterConfig({
    displayName: "objects.company.assignees",
    type: FilterTypes.OBJECT_ID,
    width: "120",
    config: {
      itemCallback: () => PartnerUserAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  assignees?: string[];

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.companyName", "company.company"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.company.companyName"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.company.companyName"
  })
  companyName: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.firstName", "company.company"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.company.firstName"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.company.firstName"
  })
  firstName?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.lastName", "company.company"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.company.lastName"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.company.lastName"
  })
  lastName?: string;

  isCompany?: boolean;

  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.language", "company.company"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      items: ConfigModule.availableLanguages.map(v => {
        return {
          text: $t(`enums.LanguageCodeEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value",
      label: "objects.company.language"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  @FilterConfig({
    displayName: "objects.company.language",
    type: FilterTypes.ENUM,
    config: {
      items: ConfigModule.availableLanguages.map(v => {
        return {
          text: $t(`enums.LanguageCodeEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  language: LanguageCodeEnum;

  @FormConfig({
    category: "objects.nouns.contact",
    searchKeywords: ["objects.nouns.contact"],
    type: Contact,
    clearable: true
  })
  @FilterConfig({
    type: Contact
  })
  contact?: IContact;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.isTaxDeductible", "company.company"],
    type: DetailFormComponentsEnum.CHECK_BOX,
    props: {
      type: "boolean",
      customField: {
        label: $t("objects.company.isTaxDeductible")
      },
      style: "width: 100%;"
    }
  })
  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.company.isTaxDeductible"
  })
  isTaxDeductible?: boolean;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "company.company",
    searchKeywords: ["objects.company.taxnumber", "company.company"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.company.taxnumber"
    },
    clearable: true,
    condition: {
      key: "isTaxDeductible",
      value: true
    }
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.company.taxnumber"
  })
  taxnumber?: string;

  @FormConfig({
    category: "common.nouns.billingAddress",
    searchKeywords: ["common.nouns.billingAddress", "common.nouns.address"],
    type: CompanyAddressBilling
  })
  get billingAddress(): ICompanyAddressBilling {
    return this.addresses.filter(a => a.isBilling === true)[0] as ICompanyAddressBilling;
  }
  set billingAddress(address: ICompanyAddressBilling) {
    this.addresses.splice(
      0,
      this.addresses.length,
      new CompanyAddress({ ...address, isBilling: true }),
      ...this.addresses.filter(a => a.isBilling === false)
    );
  }

  @FormConfig({
    category: "common.nouns.deliveryAddress",
    categoryHint: "company.addressHint",
    searchKeywords: ["common.nouns.deliveryAddress", "common.nouns.address"],
    type: CompanyAddressDelivery,
    clearable: true,
    isArray: true
  })
  get deliveryAddresses(): ICompanyAddressDelivery[] {
    return this.addresses.filter(a => a.isBilling === false);
  }
  set deliveryAddresses(addresses: ICompanyAddressDelivery[]) {
    this.addresses.splice(
      0,
      this.addresses.length,
      this.addresses.filter(a => a.isBilling === true)[0],
      ...addresses.map(a => new CompanyAddress({ ...a, isBilling: false }))
    );
  }

  @FilterConfig({
    type: CompanyAddress
  })
  addresses: ICompanyAddress[];

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "components.CompanyImportDialog.servicesLabel",
    searchKeywords: ["objects.company.isFleet", "company.company"],
    type: DetailFormComponentsEnum.SELECT_FIELD,
    props: {
      label: "objects.company.isFleet",
      items: [
        { text: $t("yes"), value: true },
        { text: $t("no"), value: false }
      ],
      itemValue: "value"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.company.isFleet"
  })
  isFleet?: boolean;

  @FormConfig({
    category: "components.CompanyImportDialog.servicesLabel",
    searchKeywords: ["objects.company.services", "company.company"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      items: Object.values(CompanyServiceEnum).map(v => {
        return {
          text: $t(`enums.CompanyServiceEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      multiple: true,
      itemValue: "value",
      label: "objects.company.services"
    },
    rules: [],
    clearable: true
  })
  @FilterConfig({
    displayName: "objects.company.services",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(CompanyServiceEnum).map(v => {
        return {
          text: $t(`enums.CompanyServiceEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  services?: CompanyServiceEnum[];

  company?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "company.companyGroup",
    searchKeywords: ["objects.company.groupId", "company.companyGroup"],
    type: DetailFormComponentsEnum.SELECT_ENTITY,
    props: {
      refType: BackendResourceEnum.COMPANY_GROUP,
      label: "objects.company.groupId"
    },
    rules: [],
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.company.groupId",
    config: {
      itemCallback: () => CompanyGroupDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-company-group"
    }
  })
  groupId?: string;

  @FilterConfig({ type: CustomFieldValue })
  values: ICustomFieldValue[] = [];

  @FilterConfig({
    type: Reference
  })
  refs: IReference[];

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.company.bankingIds",
    config: {
      itemCallback: () => PartnerBankingDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-partner-banking"
    }
  })
  bankingIds: string[];

  @FilterConfig({ type: BillingProfile })
  billingProfile: IBillingProfile;

  loading = false;

  get titleReadable() {
    return this.companyName;
  }

  /**
   * Construct company
   */
  constructor(company?: Partial<CompanyBase | MrfiktivCompanyViewModelGen>) {
    this.id = company?.id ?? "";
    this.userId = company?.userId;
    this.partnerId = company?.partnerId ?? "";
    this.description = company?.description ?? "";
    this.timestamp = new Timestamp(company?.timestamp);
    this.addresses =
      (company?.addresses as MrfiktivCompanyAddressViewModelGen[])?.map(a => new CompanyAddress(a)) ?? [];
    this.contact = company?.contact ? new Contact(company?.contact) : undefined;
    this.services = (company?.services ?? []) as CompanyServiceEnum[];
    this.assignees = company?.assignees || [];
    this.company = company?.companyName;
    this.companyName = company?.companyName ?? "";
    this.firstName = company?.firstName;
    this.lastName = company?.lastName;
    this.groupId = company?.groupId;
    this.isCompany = company?.isCompany;
    this.language = (company?.language as LanguageCodeEnum) ?? LanguageCodeEnum.DE;
    this.taxnumber = company?.taxnumber;
    this.isTaxDeductible = company?.isTaxDeductible;
    this.isFleet = company?.isFleet;
    this.values = CustomFieldValue.buildCustomFieldValues(company?.values || []);
    this.refs = Reference.filterDuplicates((company?.refs ?? []).map(ref => new Reference(ref)));
    this.bankingIds = company?.bankingIds ?? [];
    this.billingProfile = new BillingProfile(company?.billingProfile);
  }

  /**
   * fetch company
   */
  async fetch(): Promise<this> {
    this.loading = true;

    try {
      const res = await companyService.getOne(this.partnerId, this.id);

      this.map(res);
      CompanyDataAccessLayer.set(this);
    } catch (e) {
      Vue.$log.error(e);
      this.loading = false;
    } finally {
      this.loading = false;
    }

    return this;
  }

  /**
   * map props from viewmodel to this
   */
  map(company?: MrfiktivCompanyViewModelGen) {
    if (!company) return;
    this.id = company?.id ?? "";
    this.userId = company?.userId;
    this.partnerId = company?.partnerId ?? "";
    this.description = company?.description ?? "";
    this.timestamp = new Timestamp(company?.timestamp);
    this.addresses = (company?.addresses as ICompanyAddress[])?.map(a => new CompanyAddress(a)) ?? [];
    this.contact = company?.contact ? new Contact(company?.contact) : undefined;
    this.services = (company?.services ?? []) as CompanyServiceEnum[];
    this.isFleet = company?.isFleet ?? false;
    this.assignees = company?.assignees || [];
    this.company = company?.companyName;
    this.companyName = company?.companyName ?? "";
    this.firstName = company?.firstName;
    this.lastName = company?.lastName;
    this.groupId = company?.groupId ?? "";
    this.isCompany = company?.isCompany ?? true;
    this.language = (company?.language as LanguageCodeEnum) ?? LanguageCodeEnum.DE;
    this.taxnumber = company?.taxnumber;
    this.isTaxDeductible = company?.isTaxDeductible ?? false;
    this.values = CustomFieldValue.buildCustomFieldValues(company?.values || []);
    this.refs = Reference.filterDuplicates((company?.refs ?? []).map(ref => new Reference(ref)));
    this.bankingIds = company?.bankingIds ?? [];
    this.billingProfile = new BillingProfile(company?.billingProfile);
  }

  /**
   * create fetch company
   */
  async create() {
    const data: MrfiktivCreateCompanyDtoGen = {
      firstName: this.firstName || undefined,
      lastName: this.lastName || undefined,
      description: this.description || undefined,
      isCompany: this.isCompany,
      company: this.companyName,
      companyName: this.companyName,
      contact: this.contact || undefined,
      addresses: this.addresses || undefined,
      groupId: this.groupId,
      isTaxDeductible: this.isTaxDeductible,
      language: this.language || undefined,
      services: this.services || undefined,
      isFleet: this.isFleet,
      assignees: this.assignees || undefined,
      taxnumber: this.taxnumber || undefined,
      values: CustomFieldValue.buildCustomFieldValuesDto(this.values || []),
      bankingIds: this.bankingIds || [],
      billingProfile: this.billingProfile.dto,
      refs: this.refs
    };
    const res = await companyService.create(this.partnerId, data);

    this.map(res);

    CompanyDataAccessLayer.set(this);

    return this;
  }

  /**
   * delete company
   */
  async delete() {
    const res = await companyService.delete(this.partnerId, this.id);

    this.map(res);
    CompanyDataAccessLayer.delete(this);
  }

  /**
   * update company
   * @returns
   */
  async update() {
    const data: MrfiktivUpdateCompanyDtoGen = {
      description: this.description,
      addresses: this.addresses,
      company: this.companyName,
      companyName: this.companyName,
      contact: this.contact,
      groupId: this.groupId,
      isCompany: this.isCompany,
      taxnumber: this.taxnumber,
      isTaxDeductible: this.isTaxDeductible,
      language: this.language,
      services: this.services,
      isFleet: this.isFleet,
      assignees: this.assignees,
      firstName: this.firstName,
      lastName: this.lastName,
      bankingIds: this.bankingIds || [],
      billingProfile: this.billingProfile.dto,
      values: CustomFieldValue.buildCustomFieldValuesDto(this.values || [])
    };
    const res = await companyService.update(this.partnerId, this.id, data);
    this.map(res);
    CompanyDataAccessLayer.set(this);

    return this;
  }

  /**
   * update company via dto
   * @param dto
   * @returns
   */
  async updatePartial(dto: MrfiktivUpdateCompanyDtoGen) {
    const res = await companyService.update(this.partnerId, this.id, dto);

    this.map(res);

    CompanyDataAccessLayer.set(this);

    return this;
  }

  get isUpdateable() {
    return true;
  }

  /**
   * Create an activity log for assignee changes
   * @param newAssignees The ids of the new assignees of the ticket
   * @param activityType Whether the assignee was added or removed
   */
  async createAssigneeActivity(
    activityType: ActivityTypeEnum.CREATE_ASSIGNEE | ActivityTypeEnum.DELETE_ASSIGNEE,
    newAssignees?: string[]
  ) {
    if (!newAssignees?.length) return;

    await new ActivityLog().createAssigneeActivity({
      partnerId: this.partnerId,
      source: {
        refType: ResourceEnum.COMPANY,
        refId: this.id
      },
      newAssignees,
      activityType
    });
  }

  goTo(router: VueRouter) {
    return {
      table: () =>
        new CompanyGoToHelper(router).goToCompanyTable({
          partnerId: this.partnerId
        }),
      detail: () =>
        new CompanyGoToHelper(router).goToCompanyDetail({
          partnerId: this.partnerId,
          companyId: this.id
        }),
      form: () =>
        new CompanyGoToHelper(router).goToCompanyDetailForm({
          partnerId: this.partnerId,
          companyId: this.id
        })
    };
  }
}

type ICompany = CompanyBase;
const Company = Form.createForClass(Filter.createForClass(CompanyBase));

export { Company, ICompany };

export class CompanyWithGeoAndDistanceBase extends CompanyBase {
  address: ICompanyAddress;
  distance: number;

  constructor(company: MrfiktivCompanyWithDistanceViewModelGen) {
    super(company);
    this.address = new CompanyAddress(company.address);
    this.distance = company.distance;
  }

  get latLng() {
    return latLng(this.address?.geo?.lat || 0, this?.address?.geo?.lng || 0);
  }

  get formattedDistance() {
    const distanceString = this.distance.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return `${distanceString} km`;
  }

  /**
   * Icon for the map
   */
  get mapIcon(): { icon: string; color: string } {
    if (this?.isFleet) {
      return { icon: "mdi-car-multiple", color: ConfigModule.color.analyticsColors[0] };
    }

    const servicePriority: CompanyServiceEnum[] = [
      CompanyServiceEnum.WORKSHOP,
      CompanyServiceEnum.TOWING,
      CompanyServiceEnum.INSURANCE,
      CompanyServiceEnum.LAWYER,
      CompanyServiceEnum.LEASING,
      CompanyServiceEnum.RENTAL,
      CompanyServiceEnum.APPRAISER
    ];

    for (const service of servicePriority) {
      if (this?.services?.includes(service)) {
        const config = CompanyBase.serviceIconMapping.get(service);
        if (config) {
          return config;
        }
      }
    }

    return { icon: "", color: ConfigModule.color.primary };
  }

  get addressString() {
    return this.address.toString();
  }
}
