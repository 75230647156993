
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefBase } from "./RefsList.vue";

@Component({})
export default class BaseRef<T> extends Vue implements IRefBase<T> {
  @Prop()
  item!: T;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  get hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  get hasClickListener() {
    return Boolean(this.$listeners && this.$listeners.click);
  }

  get hasActionListener() {
    return Boolean(this.$listeners && this.$listeners.action);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  confirm() {
    this.$log.debug("confirm");
    this.$emit("confirm", this.item);
  }
}
