import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { ICompanyGroup } from "@/models/company-group.entity";
import companyGroupService from "@/services/mrfiktiv/services/companyGroupService";
import { MrfiktivCompanyGroupControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

export const CompanyGroupPageDataProvider = new (class extends AbstractPageDataProvider<
  ICompanyGroup,
  MrfiktivCompanyGroupControllerGetParamsGen
> {
  itemsPerPage = 250;

  async getPage(query: MrfiktivCompanyGroupControllerGetParamsGen): Promise<IPageViewModel<ICompanyGroup>> {
    const res = await companyGroupService.get(query);

    const meta = res.meta;
    const data = (res.data ?? []) as ICompanyGroup[];

    return { meta, data };
  }
})();
