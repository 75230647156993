/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
/**
 * Enum of resources for the @see ResourceGuard
 */
export enum BackendResourceEnum {
  PARTNER = "partner",
  FORM = "form",
  REPORT = "report",
  APPRAISAL = "appraisal",
  MANUFACTURER = "manufacturer",
  MACHINE = "machine",
  MACHINE_ATTACHMENT = "machine_attachment",
  ORGANIZATION = "organization",
  USER = "user",
  MESSAGE = "message",
  RATING = "rating",
  RESOURCE = "resource",
  SERVICE = "service",
  BOOKING = "booking",

  /**
   * Long running operation see @see IOperation
   */
  OPERATION = "operation",

  /**
   * Internal settings documents for a partner see @see ISettingPartnerInternal
   */
  SETTING_PARTNER_INTERNAL = "setting_partner_internal",

  /**
   * THG Document @see IThg
   */
  THG = "thg",

  /**
   * Analytics
   */
  ANALYTICS = "analytics",

  /**
   * Affiliate Document @see IAffiliate
   */
  AFFILIATE = "affiliate",

  /**
   * handle Templates
   */
  TEMPLATE = "template",

  /**
   * handle billing information of a partner
   */
  BILLING_INFORMATION = "billing_information",

  /**
   * Used to access billing information for partners
   */
  BILLING = "billing",

  /**
   * handle banking information of a partner
   * (the partner's bank account, not bank accounts of users in the scope of a pertner!)
   */
  PARTNER_BANKING = "partner_banking",

  /**
   * handle banking information of clients of a partner
   */
  PARTNER_CLIENT_BANKING = "partner_client_banking",

  /**
   * handle Vehicle Pool in fleet management context
   */
  POOL = "pool",

  /**
   * manage Drivers in fleet management context
   */
  DRIVER = "driver",

  /**
   * manage vehicles in fleet management context
   */
  VEHICLE = "vehicle",

  /**
   * manage allowed zips
   */
  ZIP = "zip",

  /**
   * allow to get customer data for partners
   */
  CUSTOMER_DATA = "customer_data",

  /**
   * Allows to use /sign routes
   */
  SIGN = "sign",

  /**
   * Allows to upload documents
   */
  DOCUMENT = "document",

  /**
   * Allows to share documents
   */
  SHARED_CONTENT = "shared_content",

  /**
   * Allows to set prices
   */
  PRICE = "price",

  /**
   * Allows to manage FAQs
   */
  FAQ = "faq",

  /**
   * Allows to manage activities, like comments and references
   */
  ACTIVITY = "activity",

  /**
   * Allows to manage custom fields
   */
  CUSTOM_FIELD = "custom_field",

  /**
   * Allows to manage events
   */
  EVENT = "event",

  /**
   * Allows to manage projects
   */
  PROJECT = "project",

  /**
   * Allows to manage ticket
   */
  TICKET = "ticket",

  /**
   * Allows to manage attachment request
   */
  ATTACHMENT_REQUEST = "attachment_request",

  /**
   * Allows to manage vehicle mileage (in frontend)
   */
  MILEAGE = "mileage",

  /**
   * Allows to manage vehicle blueprints (in frontend)
   */
  BLUEPRINT = "blueprint",

  /**
   * Allows to manage vehicle contracts (in frontend)
   */
  CONTRACT = "contract",

  /**
   * Report control system permission
   */
  REPORT_CONTROL = "report_control",

  /**
   * Links two partners
   */
  PARTNER_LINK = "partner_link",

  /**
   * The image
   */
  IMAGE = "image",

  /**
   * Cost management
   */
  COST = "cost",

  /**
   * Cost group management
   */
  COST_GROUP = "cost_group",

  /**
   * Credential management
   */
  CREDENTIAL = "credential",

  /**
   * anonymization service
   */
  ANONYMIZATION = "anonymization",

  /**
   * webhook configuration
   */
  WEBHOOK_CONFIG = "webhook_config",

  /**
   * Ai permission
   */
  AI = "ai",

  /**
   * Resource for inbound messages like replies to emails
   */
  MESSAGE_INBOUND = "message_inbound",

  /**
   * Handover resource
   */
  HANDOVER = "handover",

  /**
   * TPS resources
   */
  TPS = "tps",

  /**
   * Company resource
   */
  COMPANY = "company",

  /**
   * Company resource
   */
  COMPANY_GROUP = "company_group",

  /**
   * person resource
   */
  PERSON = "person",

  /**
   * person resource
   */
  PERSON_GROUP = "person_group",

  /**
   * Fleet
   */
  FLEET = "fleet",

  /**
   * Group
   */
  GROUP = "group"
}

export enum FrontendResourceEnum {
  /** Permission for logged in users in the app */
  LOGGED_IN_USER = "logged_in_user",
  THG_WORKITEMS = "thg_workitems",
  ADMIN = "admin",
  ANALYTICS_ADMIN = "analytics_admin",
  DASHBOARD = "dashboard",
  INSPECTION = "inspection",
  ALL = "all",
  SNAPSHOT = "snapshot",
  PERSON_EMAIL = "person_email",
  PERSON_PHONE = "person_phone",
  COMPANY_ADDRESS = "company_address",
  AGREEMENT = "agreement"
}

/**
 * Merge Enums using string enums
 * @see https://stackoverflow.com/questions/48478361/how-to-merge-two-enums-in-typescript
 */
export const ResourceEnum = { ...FrontendResourceEnum, ...BackendResourceEnum };
export type ResourceEnum = FrontendResourceEnum | BackendResourceEnum;
