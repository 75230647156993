import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

// interface ErrorLog {
//   err: Error;
//   vm?: any;
//   info?: string;
//   url?: string;
// }

export interface ErrorLogState {
  logs: Error[];
}

@Module({ dynamic: true, store, name: "errorLog" })
export class ErrorLog extends VuexModule implements ErrorLogState {
  public logs: Error[] = [];

  @Mutation
  private ADD_ERROR_LOG(log: Error) {
    this.logs.push(log);
  }

  @Mutation
  private CLEAR_ERROR_LOG() {
    this.logs.splice(0);
  }

  @Action
  public async addErrorLog(error: unknown) {
    if (error instanceof Error) {
      try {
        await Vue.$appInsights.trackException({ error: error });
      } catch (error) {
        console.error(error);
      }
      this.ADD_ERROR_LOG(error);
    }
  }

  @Action
  public clearErrorLog() {
    this.CLEAR_ERROR_LOG();
  }
}

export const ErrorLogModule = getModule(ErrorLog);
