import { Registration } from "@/models/registration.entity";
import { Report } from "@/models/report.entity";
import { IVehicle } from "@/models/vehicle.entity";
import inspectionService from "@/services/mrfiktiv/services/inspectionService";
import reportService from "@/services/mrfiktiv/services/reportService";
import {
  MrfiktivAddressGen,
  MrfiktivBrokerDetailsGen,
  MrfiktivContactDocumentGen,
  MrfiktivCreateAccidentDetailsDtoGen,
  MrfiktivCreateInsuranceDtoGen,
  MrfiktivCreateLeasingDetailsDtoGen,
  MrfiktivCreatePoliceDetailsDtoGen,
  MrfiktivCreateReportAsPartnerDtoGen,
  MrfiktivCreateReportImageCollectionDtoGen,
  MrfiktivCreateReportWittnesDtoGen,
  MrfiktivPositionDtoGen,
  MrfiktivPreExistingDamageDetailsGen,
  MrfiktivRegistrationDtoGen,
  MrfiktivVehicleRegistrationViewModelGen,
  MrfiktivVehicleViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { DamageTypeEnum } from "@/store/enum/damageType.enum";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { ReportType } from "@/store/enum/reportType";
import { VehicleTypes } from "@/store/enum/vehicle-types.enum";
import { vehicleClassToReportTypeMap } from "@/store/mapper/vehicle-class-to-report-type.map";
import { ReportDataAccessLayer } from "@/store/modules/access-layers/report.access-layer";
import { FleetAggregationModule } from "@/store/modules/fleet-aggregation.store";
import { PartnerModule } from "@/store/modules/partner";
import { CriticalityEnum } from "../enum/criticality.enum";
import { LanguageCodeEnum } from "../enum/language-code.enum";

export class RegistrationDto implements MrfiktivRegistrationDtoGen {
  numberplate?: string;
  name?: string;
  firstname?: string;
  street?: string;
  city?: string;
  zipCode?: string;
  country?: string;
  manufacturerType?: string;
  identificationnumber?: string;
  firstregistrationDay?: string;
  firstregistrationMonth?: string;
  firstregistrationYear?: string;
  vehicleClass?: string;
  manufacturerName?: string;
  manufacturerNameCode?: string;
  manufacturerTypeCode?: string;
  manufacturerDescription?: string;
  driveTyp?: string;
  huMonth?: string;
  huYear?: string;
  initialRegistrationDay?: string;
  initialRegistrationMonth?: string;
  initialRegistrationYear?: string;
  typeApproval?: string;
  typeApprovalDay?: string;
  typeApprovalMonth?: string;
  typeApprovalYear?: string;
  seatingCapacity?: number;
  standingCapacity?: number;
  brand?: string;
  width?: number;
  height?: number;
  length?: number;
  weight?: number;
  weightTotal?: number;
  speedLimit?: number;

  /**
   *  Maps the registration from a vehicle registration
   * @param registration the registration from a vehicle
   */
  constructor(registration?: MrfiktivVehicleRegistrationViewModelGen | MrfiktivRegistrationDtoGen) {
    this.numberplate = registration?.numberplate;
    this.name = registration?.name;
    this.firstname = registration?.firstname;
    this.street = registration?.street;
    this.city = registration?.city;
    this.zipCode = registration?.zipCode;
    this.country = registration?.country;
    this.manufacturerType = registration?.manufacturerType;
    this.identificationnumber = registration?.identificationnumber;
    this.firstregistrationDay = registration?.firstregistrationDay;
    this.firstregistrationMonth = registration?.firstregistrationMonth;
    this.firstregistrationYear = registration?.firstregistrationYear;
    this.vehicleClass = registration?.vehicleClass;
    this.manufacturerName = registration?.manufacturerName;
    this.manufacturerNameCode = registration?.manufacturerNameCode;
    this.manufacturerTypeCode = registration?.manufacturerTypeCode;
    this.manufacturerDescription = registration?.manufacturerDescription;
    this.driveTyp = registration?.driveTyp;
    this.huMonth = registration?.huMonth;
    this.huYear = registration?.huYear;
    this.initialRegistrationDay = registration?.initialRegistrationDay;
    this.initialRegistrationMonth = registration?.initialRegistrationMonth;
    this.initialRegistrationYear = registration?.initialRegistrationYear;
    this.typeApproval = registration?.typeApproval;
    this.typeApprovalDay = registration?.typeApprovalDay;
    this.typeApprovalMonth = registration?.typeApprovalMonth;
    this.typeApprovalYear = registration?.typeApprovalYear;
    this.seatingCapacity = registration?.seatingCapacity;
    this.standingCapacity = registration?.standingCapacity;
    this.brand = registration?.brand;
    this.width = registration?.width;
    this.height = registration?.height;
    this.length = registration?.length;
    this.weight = registration?.weight;
    this.weightTotal = registration?.weightTotal;
    this.speedLimit = registration?.speedLimit;
  }
}

/**
 * Dto to create a report document
 *
 * careful: because the update dto has witness and the create dto has wittness property this class implements the interface differntly so that the components can be reused
 */
export class CreateReportAsPartnerDto implements Omit<MrfiktivCreateReportAsPartnerDtoGen, "wittness"> {
  /**
   * Your name
   * @example Max Mustermann
   */
  customerName?: string;

  /**
   * Your first name
   * @example Max
   */
  firstName?: string;

  /**
   * Your last name
   * @example Mustermann
   */
  lastName?: string;

  /**
   * Name of the company
   * @example Muster GmbH
   */
  companyName?: string;

  /**
   * @example true
   */
  isCompany?: boolean;

  /**
   * Number of the tax
   * @example DE123123123
   */
  taxnumber?: string;

  /**
   * @example true
   */
  isTaxDeductible?: boolean;

  /**
   * External Id
   * @example ABCDEFGHI
   */
  externalId?: string;

  /**
   * The first name
   * @example Max
   */
  customerFirstName?: string;

  /**
   * Customers Numberplate
   * @example OS-MM 2021
   */
  numberplate?: string;

  /** Contact informations of the customer */
  customerContact?: MrfiktivContactDocumentGen;

  /** Contact informations of the customer */
  customerAddress?: MrfiktivAddressGen;

  /**
   * The Form Text
   * @example Hello i have a damaged car.
   */
  message?: string;

  /**
   * The Datepreference of the customer for a termin.
   * @example 2021-06-08T10:42:30.689Z
   */
  datePreference?: string;

  /** The collection of the provided images. */
  images?: MrfiktivCreateReportImageCollectionDtoGen;

  /**
   * The damage locations provided by the customer via damage locator.
   * @example ["tireFrontLeft"]
   */
  damage?: DamageTypeEnum[];

  /**
   * MarketingOptIn to contact customer for marketing options.
   * @example true
   */
  marketingOptIn?: boolean;

  /**
   * The type of report
   * @example car
   */
  reportType?: ReportType;

  /** Witness of the damage. */
  witness?: MrfiktivCreateReportWittnesDtoGen[];

  /** Accident details of the damage. */
  accidentDetails?: MrfiktivCreateAccidentDetailsDtoGen;

  /** Police information */
  policeDetails?: MrfiktivCreatePoliceDetailsDtoGen;

  /** Leasing information */
  leasing?: MrfiktivCreateLeasingDetailsDtoGen;

  /**
   * @inheritdoc
   */
  preExistingDamageDetails?: MrfiktivPreExistingDamageDetailsGen;

  /** Array of key value pair. */
  body?: object[];

  /** The registration information */
  registrationResults?: MrfiktivRegistrationDtoGen;

  vehicleId?: string;

  criticality?: CriticalityEnum;

  title?: string;

  insurances: MrfiktivCreateInsuranceDtoGen[] = [];

  language: LanguageCodeEnum = LanguageCodeEnum.DE;

  position?: MrfiktivPositionDtoGen;

  broker?: MrfiktivBrokerDetailsGen;

  /**
   * @inheritdoc
   */
  assignees?: string[];

  constructor(init?: Partial<CreateReportAsPartnerDto>) {
    Object.assign(this, init);
    if (!this.numberplate) {
      this.numberplate = "";
    }
    this.title = init?.title || this.numberplate;
    this.images = {
      registrations: init?.images?.registrations ?? [],
      cockpits: init?.images?.cockpits ?? [],
      damages: init?.images?.damages ?? [],
      damagesDetail: init?.images?.damagesDetail ?? [],
      overviews: init?.images?.overviews ?? []
    };

    this.registrationResults = new Registration(init?.registrationResults);

    if (PartnerModule.partner.partnerType === PartnerTypeEnum.TRAIN) {
      this.reportType = ReportType.TRAIN;
    }
  }

  static createFromVehicle(vehicle: IVehicle | MrfiktivVehicleViewModelGen) {
    const vehicleClass = vehicle.registration?.vehicleClass as VehicleTypes | undefined;
    const createReportAsPartnerDto = new CreateReportAsPartnerDto();

    createReportAsPartnerDto.numberplate = vehicle.numberplate || vehicle.registration?.numberplate;

    createReportAsPartnerDto.reportType = vehicleClass ? vehicleClassToReportTypeMap.get(vehicleClass) : undefined;
    createReportAsPartnerDto.images = {
      cockpits: [],
      damages: [],
      damagesDetail: [],
      overviews: [],
      registrations: []
    };

    createReportAsPartnerDto.registrationResults = new RegistrationDto(vehicle.registration);

    createReportAsPartnerDto.vehicleId = vehicle.id;
    createReportAsPartnerDto.title = vehicle?.numberplate;

    return createReportAsPartnerDto;
  }

  async create(partnerId: string) {
    const data = await reportService.createAsPartner(partnerId, this);

    const report = new Report(data);
    ReportDataAccessLayer.set(report);
    FleetAggregationModule.parseReports([report]);

    return report;
  }

  async createAsInspectionFinding(
    partnerId: string,
    handoverId: string,
    inspectionId: string,
    position?: MrfiktivPositionDtoGen
  ) {
    const data = await inspectionService.createReport(partnerId, handoverId, inspectionId, {
      ...this,
      position
    });

    const report = new Report(data);
    ReportDataAccessLayer.set(report);
    FleetAggregationModule.parseReports([report]);

    return report;
  }
}

export function getCreateReportDto(
  createReportAsPartnerDto: CreateReportAsPartnerDto
): MrfiktivCreateReportAsPartnerDtoGen {
  let customerName = createReportAsPartnerDto.customerName;
  if (createReportAsPartnerDto.firstName || createReportAsPartnerDto.lastName) {
    customerName = `${createReportAsPartnerDto.firstName} ${createReportAsPartnerDto.lastName}`;
  }

  return {
    vehicleId: createReportAsPartnerDto.vehicleId,
    accidentDetails: createReportAsPartnerDto.accidentDetails,
    body: createReportAsPartnerDto.body,
    companyName: createReportAsPartnerDto.companyName,
    taxnumber: createReportAsPartnerDto.taxnumber,
    isTaxDeductible: createReportAsPartnerDto.isTaxDeductible,
    isCompany: createReportAsPartnerDto.isCompany,
    customerAddress: createReportAsPartnerDto.customerAddress,
    customerContact: createReportAsPartnerDto.customerContact,
    customerFirstName: createReportAsPartnerDto.customerFirstName,
    customerName,
    damage: createReportAsPartnerDto.damage,
    datePreference: createReportAsPartnerDto.datePreference,
    externalId: createReportAsPartnerDto.externalId,
    firstName: createReportAsPartnerDto.firstName,
    lastName: createReportAsPartnerDto.lastName,
    leasing: createReportAsPartnerDto.leasing,
    preExistingDamageDetails: createReportAsPartnerDto.preExistingDamageDetails?.description
      ? createReportAsPartnerDto.preExistingDamageDetails
      : undefined,
    message: createReportAsPartnerDto.message,
    marketingOptIn: createReportAsPartnerDto.marketingOptIn,
    numberplate: createReportAsPartnerDto.numberplate,
    policeDetails: createReportAsPartnerDto.policeDetails,
    registrationResults: new RegistrationDto(createReportAsPartnerDto.registrationResults),
    reportType: createReportAsPartnerDto.reportType,
    wittness: createReportAsPartnerDto.witness,
    images: createReportAsPartnerDto.images,
    insurances: createReportAsPartnerDto.insurances,
    criticality: createReportAsPartnerDto.criticality,
    title: createReportAsPartnerDto.title || createReportAsPartnerDto.numberplate,
    position: createReportAsPartnerDto.position,
    broker: createReportAsPartnerDto.broker
  };
}
