import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { FormConfig, IsFormable } from "@/lib/formable";
import { RulesEnum } from "@/lib/rules/rules.map";
import { IEntity } from "@/lib/utility/data/entity.interface";
import partnerBankingService from "@/services/mrfiktiv/services/partner-banking.service";
import {
  MrfiktivPartnerBankingViewModelGen,
  MrfiktivUpdatePartnerBankingDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerBankingDataAccessLayer } from "@/store/modules/access-layers/partner-banking.access-layer";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { ActivityLog } from "./activity-log.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { handleError } from "@/lib/utility/handleError";
import VueRouter from "vue-router";
import { PartnerBankingGoToHelper } from "@/lib/utility/partner-banking.go-to-helper";
import { UpdateDto } from "@/lib/utility/data/update-dto.interface";
import { $t } from "@/lib/utility/t";

@IsFilterable
@IsFormable
class PartnerBankingBase
  implements
    IEntity<IPartnerBanking, MrfiktivUpdatePartnerBankingDtoGen>,
    Omit<MrfiktivPartnerBankingViewModelGen, "values">,
    UpdateDto<IPartnerBanking> {
  /** *id* of this *banking* */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.partnerBanking.id"
  })
  id: string;

  /** *id* of the *partner* that this banking belongs to */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.partnerBanking.partnerId"
  })
  partnerId: string;

  /** Name of the *user* that this banking belongs to */
  @FormConfig({
    category: "partnerBanking.partnerBanking",
    searchKeywords: ["objects.partnerBanking.name", "partnerBanking.partnerBanking"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "text",
      label: "objects.partnerBanking.name",
      hint: "objects.partnerBanking.nameHint"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.partnerBanking.name"
  })
  name: string;

  /** Name of the *bank* that this banking belongs to */
  @FormConfig({
    category: "partnerBanking.partnerBanking",
    searchKeywords: ["objects.partnerBanking.bank", "partnerBanking.partnerBanking"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "text",
      label: "objects.partnerBanking.bank",
      hint: "objects.partnerBanking.bankHint"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.partnerBanking.bank"
  })
  bank: string;

  /** Name of the *bank* that this banking belongs to */
  @FormConfig({
    category: "partnerBanking.partnerBanking",
    searchKeywords: ["objects.partnerBanking.iban", "partnerBanking.partnerBanking"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "text",
      label: "objects.partnerBanking.iban",
      hint: "objects.partnerBanking.ibanHint"
    },
    rules: [RulesEnum.REQUIRED_RULE, RulesEnum.IBAN_RULE]
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.partnerBanking.iban"
  })
  iban: string;

  /** Name of the *bank* that this banking belongs to */
  @FormConfig({
    category: "partnerBanking.partnerBanking",
    searchKeywords: ["objects.partnerBanking.isSepaDirectDebitMandate", "partnerBanking.partnerBanking"],
    type: DetailFormComponentsEnum.CHECK_BOX,
    props: {
      type: "boolean",
      label: $t("objects.partnerBanking.isSepaDirectDebitMandate"),
      customField: {
        label: $t("objects.partnerBanking.isSepaDirectDebitMandate")
      },
      style: "width: 100%;"
    },
    rules: []
  })
  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.partnerBanking.isSepaDirectDebitMandate"
  })
  isSepaDirectDebitMandate: boolean;

  /** timestamp */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  loading = false;

  isUpdateable = true;

  constructor(data?: Partial<MrfiktivPartnerBankingViewModelGen> | IPartnerBanking) {
    this.id = data?.id ?? "";
    this.partnerId = data?.partnerId ?? "";
    this.name = data?.name ?? "";
    this.bank = data?.bank ?? "";
    this.iban = data?.iban ?? "";
    this.isSepaDirectDebitMandate = data?.isSepaDirectDebitMandate ?? false;
    this.timestamp = new Timestamp(data?.timestamp);
  }

  map(data: MrfiktivPartnerBankingViewModelGen): void {
    this.id = data.id ?? "";
    this.partnerId = data.partnerId ?? "";
    this.name = data.name ?? "";
    this.bank = data.bank ?? "";
    this.iban = data.iban ?? "";
    this.isSepaDirectDebitMandate = data?.isSepaDirectDebitMandate ?? false;
    this.timestamp = new Timestamp(data.timestamp);
  }

  async create(): Promise<this> {
    const res = await partnerBankingService.create(this.partnerId, {
      isSepaDirectDebitMandate: this.isSepaDirectDebitMandate,
      name: this.name,
      bank: this.bank,
      iban: this.iban
    });

    this.map(res);
    PartnerBankingDataAccessLayer.set(this);

    return this;
  }

  async fetch(): Promise<this> {
    this.loading = true;
    try {
      const res = await partnerBankingService.findOne(this.partnerId, this.id);
      this.map(res);
      PartnerBankingDataAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      PartnerBankingDataAccessLayer.delete(this);
      throw e;
    }
    this.loading = false;

    return this;
  }

  async updatePartial(dto: MrfiktivUpdatePartnerBankingDtoGen): Promise<this> {
    this.loading = true;
    try {
      const res = await partnerBankingService.update(this.partnerId, this.id, dto);

      this.map(res);
      PartnerBankingDataAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      throw e;
    }
    this.loading = false;

    const changedProps: string[] = [];
    if (dto.name) changedProps.push($t("objects.partnerBanking.name"));
    if (dto.bank) changedProps.push($t("objects.partnerBanking.bank"));
    if (dto.iban) changedProps.push($t("objects.partnerBanking.iban"));
    if (dto.isSepaDirectDebitMandate) changedProps.push($t("objects.partnerBanking.isSepaDirectDebitMandate"));

    await new ActivityLog({
      partnerId: this.partnerId,
      source: {
        refType: BackendResourceEnum.PARTNER_CLIENT_BANKING,
        refId: this.id
      },
      actionType: ActionEnum.UPDATE,
      comment: changedProps.join(", "),
      isDeletable: true,
      activity: ActivityTypeEnum.UPDATE_BANKING
    })
      .create()
      .catch(handleError);

    return this;
  }

  async update(): Promise<this> {
    this.loading = true;
    try {
      await this.updatePartial({
        isSepaDirectDebitMandate: this.isSepaDirectDebitMandate,
        bank: this.bank,
        iban: this.iban,
        name: this.name
      });
    } catch (e) {
      this.loading = false;
      throw e;
    }
    this.loading = false;

    return this;
  }

  async delete(): Promise<void> {
    const res = await partnerBankingService.remove(this.partnerId, this.id);

    this.map(res);
    PartnerBankingDataAccessLayer.delete(this);
  }

  goTo(router: VueRouter) {
    return {
      table: () =>
        new PartnerBankingGoToHelper(router).goToPartnerBankingTable({
          partnerId: this.partnerId
        }),
      detail: () =>
        new PartnerBankingGoToHelper(router).goToPartnerBankingDetail({
          partnerId: this.partnerId,
          partnerBankingId: this.id
        }),
      form: () =>
        new PartnerBankingGoToHelper(router).goToPartnerBankingDetailForm({
          partnerId: this.partnerId,
          partnerBankingId: this.id
        })
    };
  }
}

type IPartnerBanking = PartnerBankingBase;
const PartnerBanking = Filter.createForClass(Filter.createForClass(PartnerBankingBase));

export { IPartnerBanking, PartnerBanking };
