/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Vehicle ownership type
 */
export enum VehicleOwnershipStatusEnum {
  /**
   * Identifies a vehicle as a leased vehicle.
   * Expect a leasing contract to be found.
   */
  LEASED = "leased",

  /**
   * The vehicle has been purchased, and is owned
   */
  OWNED = "owned",

  /**
   * Catch-all for any other status
   */
  OTHER = "other"

  /**
   * Financed, // Owned but still under a loan
   * Rented, // Like Sixt
   * Subscribed, // Like Finn
   * Shared // Like Miles
   */
}
