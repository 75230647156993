import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IEntity } from "@/lib/utility/data/entity.interface";
import costGroupService from "@/services/mrfiktiv/services/costGroupService";
import {
  MrfiktivCostGroupViewModelGen,
  MrfiktivUpdateCostGroupDtoGen,
  MrfiktivCustomFieldListElementDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { CostGroupDataAccessLayer } from "@/store/modules/access-layers/cost-group.access-layer";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { IProjectConfiguration, ProjectConfiguration } from "./project-configuration.entity";
import { CustomViewEntity } from "@/lib/interfaces/custom-view-entity.interface";
import { PartnerUserModule } from "@/store/modules/partner-user.store";

@IsFilterable
class CostGroupBase
  implements IEntity<MrfiktivCostGroupViewModelGen, MrfiktivUpdateCostGroupDtoGen>, CustomViewEntity<ICostGroup> {
  /** Id of the cost group */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.cost.id"
  })
  id: string;

  /** The partnerId of the cost */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.cost.partnerId"
  })
  partnerId: string;

  /** Title of the cost group */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.cost.title"
  })
  title: string;

  /** Internal description of the cost group */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.cost.description"
  })
  description: string;

  @FilterConfig({
    type: ProjectConfiguration
  })
  configuration: IProjectConfiguration;

  /** The creator */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.costGroup.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: (item: any) => {
        return { item };
      },
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId?: string;

  /** timestamp */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  constructor(data: Partial<MrfiktivCostGroupViewModelGen>) {
    this.id = data.id ?? "";
    this.partnerId = data.partnerId ?? "";
    this.title = data.title ?? "";
    this.description = data.description ?? "";
    this.configuration = new ProjectConfiguration(data?.configuration);
    this.userId = data.userId;
    this.timestamp = new Timestamp(data.timestamp);
  }

  map(data: MrfiktivCostGroupViewModelGen): void {
    this.id = data.id;
    this.partnerId = data.partnerId;
    this.title = data.title;
    this.description = data.description;
    this.configuration = new ProjectConfiguration(data.configuration);
    this.userId = data.userId;
    this.timestamp = new Timestamp(data.timestamp);
  }

  private get customFieldConfigDto(): MrfiktivCustomFieldListElementDtoGen[] {
    return this.configuration.customFieldConfig
      .filter(c => c.customField.id) // filter out deleted custom fields
      .map(c => {
        return {
          configuration: c.configuration,
          customField: c.customField.id
        };
      });
  }

  async create(): Promise<this> {
    const res = await costGroupService.create(this.partnerId, {
      title: this.title,
      description: this.description,
      configuration: {
        isTemplate: this.configuration.isTemplate,
        customFieldConfig: this.customFieldConfigDto,
        views: this.configuration.views.map(v => v.dto)
      }
    });

    this.map(res);
    CostGroupDataAccessLayer.set(this);

    return this;
  }

  async fetch(): Promise<this> {
    try {
      const res = await costGroupService.getOne(this.partnerId, this.id);
      this.map(res);
      CostGroupDataAccessLayer.set(this);
    } catch (e) {
      CostGroupDataAccessLayer.delete(this);
      throw e;
    }

    return this;
  }

  async updatePartial(dto: Partial<MrfiktivUpdateCostGroupDtoGen>): Promise<this> {
    const res = await costGroupService.update(this.partnerId, this.id, dto);

    this.map(res);
    CostGroupDataAccessLayer.set(this);

    return this;
  }

  async update(): Promise<this> {
    const updateDto: MrfiktivUpdateCostGroupDtoGen = {
      title: this.title,
      description: this.description,
      configuration: {
        isTemplate: this.configuration.isTemplate,
        customFieldConfig: this.customFieldConfigDto,
        views: this.configuration.views.map(v => v.dto)
      }
    };

    return this.updatePartial(updateDto);
  }

  async delete(): Promise<void> {
    const res = await costGroupService.delete(this.partnerId, this.id);

    this.map(res);
    CostGroupDataAccessLayer.delete(this);
  }
}

type ICostGroup = CostGroupBase;
const CostGroup = Filter.createForClass(CostGroupBase);

export { CostGroup, ICostGroup };
