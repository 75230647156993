import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { AbstractLocalIndexDbAccessLayer } from "@/lib/utility/data/local-index-db-access-layer.abstract";
import { Fleet, IFleet } from "@/models/fleet.entity";

export const FleetDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IFleet> {
  getIdentifier(entity: IFleet): string {
    return entity.id;
  }

  mapEntity(entity: IFleet): IFleet {
    return new Fleet(entity);
  }
})();

export const FleetIndexDbAccessLayer = new (class extends AbstractLocalIndexDbAccessLayer<IFleet> {})(
  "mrfiktiv",
  1,
  "fleet",
  "id"
);
