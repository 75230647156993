import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { VehicleOwnershipStatusEnum } from "@/lib/enum/vehicle-ownership-status.enum";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { FormConfig, IsFormable } from "@/lib/formable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { formatYearsMonthDay } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { $t } from "@/lib/utility/t";
import vehicleService from "@/services/mrfiktiv/services/vehicleService";
import {
  MrfiktivBlueprintElementViewmodelGen,
  MrfiktivCreateVehicleRegistrationDtoGen,
  MrfiktivCustomFieldValueGen,
  MrfiktivLeasingContractGen,
  MrfiktivMileageGen,
  MrfiktivUpdateVehicleDtoGen,
  MrfiktivUpdateVehicleRegistrationDtoGen,
  MrfiktivVehicleDocumentSchemaGen,
  MrfiktivVehicleViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { FleetDataAccessLayer } from "@/store/modules/access-layers/fleet.access-layer";
import { VehicleAccessLayer } from "@/store/modules/access-layers/vehicle.access-layer";
import VueRouter from "vue-router";
import { CustomFieldValue } from "./custom-field-value.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { IVehicleRegistration, VehicleRegistration } from "./vehicle-registration.entity";
import { FleetAggregationModule } from "@/store/modules/fleet-aggregation.store";
import Vue from "vue";

/**
 * Ui class for vehicles
 */
@IsFormable
@IsFilterable
class VehicleBase implements MrfiktivVehicleViewModelGen {
  /**
   * @inheritdoc
   */
  id: string;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.vehicle.id"
  })
  get _id() {
    return this.id;
  }

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.vehicle.partnerId"
  })
  partnerId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.vehicle.groupId",
    config: {
      itemCallback: () => FleetDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-fleet"
    }
  })
  groupId?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.vehicle.displayName", "common.nouns.general"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.displayName"
    }
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.displayName"
  })
  displayName?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.vehicle.registration.numberplate", "common.nouns.general"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.numberplate"
    }
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.numberplate"
  })
  numberplate: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.vehicle.registration.identificationnumber", "common.nouns.general"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.identificationnumber"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.identificationnumber"
  })
  identificationnumber?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.vehicle.commissioningDate", "common.nouns.general"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.commissioningDate",
      type: "date"
    },
    clearable: true
  })
  get commissioningDateFormable(): string {
    if (!this.commissioningDate) {
      return "";
    }
    return formatYearsMonthDay(new Date(this.commissioningDate));
  }
  set commissioningDateFormable(date: string) {
    if (date) {
      this.commissioningDate = new Date(date).toISOString();
    }

    this.commissioningDate = date;
  }

  @FilterConfig({
    type: FilterTypes.DATE,
    displayName: "objects.vehicle.commissioningDate"
  })
  commissioningDate?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.vehicle.state", "common.nouns.general"],
    type: DetailFormComponentsEnum.SELECT_FIELD,
    props: {
      items: Object.values(VehicleStateEnum).map(e => {
        return {
          text: $t(`enums.VehicleStateEnum.${e}`),
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value",
      label: "objects.vehicle.state"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(VehicleStateEnum).map(e => {
        return {
          text: `enums.VehicleStateEnum.${e}`,
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    displayName: "objects.vehicle.state"
  })
  state?: VehicleStateEnum;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.vehicle.ownership", "common.nouns.general"],
    type: DetailFormComponentsEnum.SELECT_FIELD,
    props: {
      items: Object.values(VehicleOwnershipStatusEnum).map(e => {
        return {
          text: $t(`enums.VehicleOwnershipStatusEnum.${e}`),
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value",
      label: "objects.vehicle.ownership",
      clearable: true
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(VehicleOwnershipStatusEnum).map(e => {
        return {
          text: `enums.VehicleOwnershipStatusEnum.${e}`,
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    displayName: "objects.vehicle.ownership"
  })
  ownership?: "leased" | "owned" | "other";

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.vehicle.available", "common.nouns.general"],
    type: DetailFormComponentsEnum.CHECK_BOX,
    props: {
      type: "boolean",
      label: "objects.vehicle.available",
      customField: {
        label: $t("objects.vehicle.available")
      },
      style: "width: 100%;"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    config: { items: [true, false] },
    displayName: "objects.vehicle.available"
  })
  available?: boolean;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.vehicle.isCommercial", "common.nouns.general"],
    type: DetailFormComponentsEnum.CHECK_BOX,
    props: {
      type: "boolean",
      label: "objects.vehicle.isCommercial",
      customField: {
        label: $t("objects.vehicle.isCommercial")
      },
      style: "width: 100%;"
    },
    clearable: true
  })
  isCommercial?: boolean;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.vehicle.isRental", "common.nouns.general"],
    type: DetailFormComponentsEnum.CHECK_BOX,
    props: {
      type: "boolean",
      label: "objects.vehicle.isRental",
      customField: {
        label: $t("objects.vehicle.isRental")
      },
      style: "width: 100%;"
    },
    clearable: true
  })
  isRental?: boolean;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.general",
    searchKeywords: ["objects.vehicle.note", "common.nouns.general"],
    type: DetailFormComponentsEnum.TEXT_AREA,
    props: {
      label: "objects.vehicle.note"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.note"
  })
  note?: string;

  /**
   * @inheritdoc
   */
  tags?: string[];

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["common.nouns.vehicleRegistration"],
    type: VehicleRegistration
  })
  @FilterConfig({
    type: VehicleRegistration
  })
  registration?: IVehicleRegistration;

  /**
   * @inheritdoc
   */
  contracts?: MrfiktivLeasingContractGen[];

  /**
   * @inheritdoc
   */
  mileages?: MrfiktivMileageGen[];

  /**
   * @inheritdoc
   */
  blueprints: MrfiktivBlueprintElementViewmodelGen[];

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.currentDriver"
  })
  currentDriver?: string;

  /**
   * @inheritdoc
   */
  drivers?: string[];

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  /**
   * @inheritdoc
   */
  documents?: MrfiktivVehicleDocumentSchemaGen[];

  /**
   * @inheritdoc
   */
  values?: MrfiktivCustomFieldValueGen[] = [];

  loading = false;

  get commissioningDateString() {
    if (this.commissioningDate) {
      return new Date(this.commissioningDate).toISOString().slice(0, 10);
    }

    return undefined;
  }

  set commissioningDateString(value: string | undefined) {
    if (value) {
      this.commissioningDate = new Date(value).toISOString();
    }
  }

  readonly isUpdateable = true;

  constructor(vehicle: Partial<VehicleBase | MrfiktivVehicleViewModelGen>) {
    this.id = vehicle.id ?? "";
    this.partnerId = vehicle.partnerId ?? "";
    this.groupId = vehicle.groupId;
    this.numberplate = vehicle.numberplate ?? "";
    this.identificationnumber = vehicle.identificationnumber;
    this.displayName = vehicle.displayName;
    this.commissioningDate = vehicle.commissioningDate;
    this.state = (vehicle.state as VehicleStateEnum) || undefined;
    this.available = vehicle.available;
    this.isCommercial = vehicle.isCommercial;
    this.isRental = vehicle.isRental;
    this.ownership = vehicle.ownership;
    this.tags = vehicle.tags;
    this.registration = new VehicleRegistration(vehicle.registration);
    this.contracts = vehicle.contracts;
    this.mileages = vehicle.mileages;
    this.currentDriver = vehicle.currentDriver;
    this.drivers = vehicle.drivers;
    this.note = vehicle.note;
    this.timestamp = new Timestamp(vehicle.timestamp);
    this.documents = vehicle.documents;
    this.blueprints = vehicle.blueprints || [];
    this.values = CustomFieldValue.buildCustomFieldValues(vehicle?.values || []) as MrfiktivCustomFieldValueGen[];
  }

  map(vehicle: VehicleBase | MrfiktivVehicleViewModelGen) {
    this.id = vehicle.id;
    this.partnerId = vehicle.partnerId;
    this.groupId = vehicle.groupId;
    this.numberplate = vehicle.numberplate;
    this.identificationnumber = vehicle.identificationnumber;
    this.displayName = vehicle.displayName;
    this.commissioningDate = vehicle.commissioningDate;
    this.state = vehicle.state as VehicleStateEnum;
    this.available = vehicle.available;
    this.isCommercial = vehicle.isCommercial;
    this.isRental = vehicle.isRental;
    this.ownership = vehicle.ownership;
    this.tags = vehicle.tags;
    this.registration = new VehicleRegistration(vehicle.registration);
    this.contracts = vehicle.contracts;
    this.mileages = vehicle.mileages;
    this.currentDriver = vehicle.currentDriver;
    this.drivers = vehicle.drivers;
    this.note = vehicle.note;
    this.timestamp = new Timestamp(vehicle.timestamp || this.timestamp);
    this.documents = vehicle.documents;
    this.blueprints = vehicle.blueprints || [];
    this.values = CustomFieldValue.buildCustomFieldValues(vehicle?.values || []) as MrfiktivCustomFieldValueGen[];
  }

  get registrationDate() {
    const registration = this.registration;
    if (
      !registration ||
      !registration.firstregistrationDay ||
      !registration.firstregistrationMonth ||
      !registration.firstregistrationYear
    ) {
      return "";
    }

    return `${registration.firstregistrationYear}-${registration.firstregistrationMonth?.padStart(
      2,
      "0"
    )}-${registration.firstregistrationDay?.padStart(2, "0")}`;
  }

  set registrationDate(registrationDate: string) {
    if (!registrationDate) {
      return;
    }

    if (registrationDate) {
      const [year, month, day] = registrationDate.split("-");

      if (!this.registration) {
        this.registration = new VehicleRegistration();
      }

      this.registration.firstregistrationDay = day;
      this.registration.firstregistrationMonth = month;
      this.registration.firstregistrationYear = year;
    }
  }

  get updateDto(): MrfiktivUpdateVehicleDtoGen {
    return {
      numberplate: this.numberplate,
      identificationnumber: this.identificationnumber,
      displayName: this.displayName,
      commissioningDate: this.commissioningDate,
      state: this.state,
      available: this.available,
      isCommercial: this.isCommercial,
      isRental: this.isRental,
      ownership: this.ownership,
      tags: this.tags,
      currentDriver: this.currentDriver,
      drivers: this.drivers,
      note: this.note,
      mileages: this.mileages,
      registration: this.registration as MrfiktivUpdateVehicleRegistrationDtoGen,
      documents: this.documents,
      groupId: this.groupId
    };
  }

  async fetch() {
    this.loading = true;
    try {
      const vehicle = await vehicleService.getOne(this.partnerId, this.id);
      this.map(vehicle);
      VehicleAccessLayer.set(this);
    } finally {
      this.loading = false;
    }

    return this;
  }

  async openDetail($router: VueRouter, tab?: VehicleTabs, newTab?: boolean) {
    await new GoToHelper($router).goToVehicleDetail(this.id, this.partnerId, tab, newTab);
  }

  async update() {
    this.loading = true;
    try {
      const res = await vehicleService.update(this.partnerId, this.id, this.updateDto);

      this.map(res);
      VehicleAccessLayer.set(this);

      FleetAggregationModule.getVehicleAggregation(this.id)
        ?.updateMileages(this.mileages)
        .parseMileageDates();
    } catch (e) {
      Vue.$log.error(e);
      this.loading = false;
      throw e;
    } finally {
      this.loading = false;
    }

    return this;
  }

  async create() {
    this.loading = true;
    try {
      const res = await vehicleService.create(this.partnerId, {
        available: this.available,
        commissioningDate: this.commissioningDate,
        currentDriver: this.currentDriver,
        displayName: this.displayName,
        drivers: this.drivers,
        identificationnumber: this.identificationnumber,
        isCommercial: this.isCommercial,
        isRental: this.isRental,
        mileages: this.mileages,
        note: this.note,
        numberplate: this.numberplate,
        ownership: this.ownership,
        registration: this.registration as MrfiktivCreateVehicleRegistrationDtoGen,
        state: this.state,
        tags: this.tags,
        values: this.values,
        groupId: this.groupId
      });

      this.map(res);
      VehicleAccessLayer.set(this);
    } catch (e) {
      Vue.$log.error(e);
      this.loading = false;
      throw e;
    } finally {
      this.loading = false;
    }

    return this;
  }
}

type IVehicle = VehicleBase;
const Vehicle = Filter.createForClass(VehicleBase);

export { IVehicle, Vehicle };
