import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IPerson } from "@/models/person.entity";
import personService from "@/services/mrfiktiv/services/person.service";
import { MrfiktivPersonControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

export const PersonPageDataProvider = new (class extends AbstractPageDataProvider<
  IPerson,
  MrfiktivPersonControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivPersonControllerGetParamsGen): Promise<IPageViewModel<IPerson>> {
    const res = await personService.get(query);

    const meta = res.meta;
    const data = (res.data ?? []) as IPerson[];

    return { meta, data };
  }
})();
