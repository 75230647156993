






import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import TheNavigationLayout from "./TheNavigationLayout.vue";
import { ConfigModule } from "@/store/modules/config";

@Component({
  components: {
    TheNavigationLayout
  }
})
export default class ThePartnerList extends mixins(PermissionMixin) {
  get title() {
    return "";
  }

  get partnerId() {
    return PartnerModule.partner._id;
  }

  get items() {
    let icon = "mdi-car-wrench";
    if (PartnerModule.isTrain) {
      icon = "mdi-train";
    }

    const items: IMenuListItem[] = [
      {
        icon: "mdi-view-dashboard-outline",
        text: "navigation.ThePartnerList.overview",
        link: `/partners/${this.partnerId}/home`,
        isEnabled: true
      },
      {
        icon: "mdi-family-tree",
        link: `/v2/partner/${this.partnerId}/fleet`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.FLEET,
          action: ActionEnum.READ,
          field: this.partnerId
        },
        text: "navigation.TheFleetList.fleets"
      },
      {
        icon: "mdi-garage-open-variant",
        link: `/partner/${this.partnerId}/fleet`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.VEHICLE,
          action: ActionEnum.READ,
          field: this.partnerId
        },
        text: "navigation.TheFleetList.title"
      },
      {
        icon: "mdi-file-send-outline",
        text: "navigation.ThePartnerList.control",
        link: `/partners/${this.partnerId}/control`,
        isEnabled: ConfigModule.navigationSettings.showTheFleetList,
        permission: {
          resource: ResourceEnum.REPORT_CONTROL,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      },
      {
        icon: icon,
        text: "navigation.ThePartnerList.report",
        link: `/partners/${this.partnerId}/report`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.REPORT,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      },
      {
        icon: "mdi-clipboard-text-outline",
        text: "navigation.ThePartnerList.handover",
        link: `/partner/${this.partnerId}/handover`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.HANDOVER,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      },
      {
        icon: "mdi-email-multiple-outline",
        text: "navigation.ThePartnerList.form",
        link: `/partners/${this.partnerId}/form`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.FORM,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      }
    ];

    return items;
  }
}
