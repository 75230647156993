export enum ActivityTypeEnum {
  MANAGE_PROPERTIES_ON_SOURCE = "managePropertiesOnSource",
  MANAGE_TARGETS_ON_SOURCE = "manageTargetSOnSource",
  CREATE_DOCUMENT = "document",
  CREATE_ASSIGNEE = "addAssignee",
  CREATE_COST = "createCost",
  CREATE_BLUEPRINT = "createBlueprint",
  DELETE_BLUEPRINT = "deleteBlueprint",
  CREATE_IMAGE = "image",
  CREATE_TICKET = "ticket",
  DELETE_ASSIGNEE = "deleteAssignee",
  REQUEST_SIGNATURE = "sign-request",
  REQUEST_ATTACHMENT = "attachmentRequest",
  CREATE_ATTACHMENT_RESPONSE = "attachmentResponse",
  UPDATE_PROGRESS_STATUS = "progressStatusUpdate",
  UPDATE_IS_FIXED_STATUS = "isFixedStatusUpdate",
  UPDATE_REPORT = "reportUpdate",
  UPDATE_BANKING = "bankingUpdate",
  CREATE_EVENT_SERIES_FROM_OLD = "deriveNewSeriesFromOld",
  INTERRUPT_EVENT_SERIES_WITH_NEW = "interruptEventSeriesWithNew",
  SHARE = "share",
  SHARE_REPORT_INITIAL = "initialReportShare",
  MESSAGE = "message",
  COMMENT = "comment",
  CREATE = "created",
  SEND_PROGRESS_STATUS_UPDATE_MESSAGE = "progressStatusUpdateMessage",
  CREATE_REPORT_SUMMARY = "createReportSummary",
  UPDATE_STATUS = "updateStatus",
  EXPORT = "exported"
}
