


































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import Card from "./Card.vue";

@Component({
  components: { Card }
})
export default class ConfirmActionDialog extends DarkModeHighlightMixin {
  @Prop()
  title!: string;

  @Prop()
  subtitle?: string;

  @Prop({ default: false })
  isDialogActive!: boolean;

  @Prop()
  leftText?: string;

  @Prop()
  rightText?: string;

  @Prop()
  fullscreen?: boolean;

  @Prop({ default: false })
  outlined!: boolean;

  @Prop({ default: false })
  persistent!: boolean;

  @Prop()
  leftDisabled?: boolean;

  @Prop()
  rightDisabled?: boolean;

  @Prop({ default: "" })
  leftColor!: string;

  @Prop({ default: "success" })
  rightColor!: string;

  @Prop({ default: false })
  leftLoading!: boolean;

  @Prop({ default: false })
  rightLoading!: boolean;

  @Prop({ default: false })
  hideLeft!: boolean;

  @Prop({ default: false })
  hideRight!: boolean;

  @Prop({ default: "600px" })
  width!: string;

  @Prop({ default: "600px" })
  height!: number;

  @Prop({ default: false })
  showGradient!: boolean;

  @Prop({ default: false })
  hideClose!: boolean;

  @Prop({ default: false })
  supressKeyboardActions!: boolean;

  @Prop({ default: false })
  supressKeyboardConfirm!: boolean;

  @Prop({ default: false })
  supressKeyboardCancel!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: "" })
  cardClass!: string;

  get titleLocal() {
    if (this.title) {
      return this.title;
    }
    return String(this.$t("components.utility.ConfirmActionDialog.title"));
  }

  get leftTextLocal() {
    if (this.leftText) {
      return this.leftText;
    }
    return String(this.$t("components.utility.ConfirmActionDialog.abort"));
  }

  get rightTextLocal() {
    if (this.rightText) {
      return this.rightText;
    }
    return String(this.$t("components.utility.ConfirmActionDialog.confirm"));
  }

  onKeyDown(k: KeyboardEvent) {
    if (this.dialogModel && !this.supressKeyboardActions) {
      if (k.key === "Enter" && !this.rightDisabled && !this.supressKeyboardConfirm) {
        this.rightClick();
      }
      if (k.key === "Esc" && !this.leftDisabled && !this.supressKeyboardCancel) {
        this.leftClick();
      }
    }
  }

  mounted() {
    document.addEventListener("keydown", this.onKeyDown);
  }

  destroyed() {
    document.removeEventListener("keydown", this.onKeyDown);
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.$emit("update:isDialogActive", v);
  }

  leftClick() {
    this.$emit("leftClick");
  }

  rightClick() {
    this.$emit("rightClick");
  }

  close() {
    this.$emit("close");
    this.dialogModel = false;
  }
}
