import VueRouter from "vue-router";
import { BaseGoToHelper } from "./goToHelper";
import { $t } from "./t";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

export enum PartnerBankingRouteNames {
  PARTNER_BANKING_TABLE = "PARTNER_BANKING_TABLE",
  PARTNER_BANKING_DETAIL = "PARTNER_BANKING_DETAIL",
  PARTNER_BANKING_DETAIL_FORM = "PARTNER_BANKING_DETAIL_FORM"
}

type Tab = { newTab?: boolean };
type Partner = { partnerId: string };
type PartnerBanking = { partnerBankingId: string };
type Query = { query?: Record<string, string> };

export class PartnerBankingGoToHelper extends BaseGoToHelper {
  static get breadCrumbs() {
    const locations = PartnerBankingGoToHelper.locations;

    return {
      PartnerBankingTable: (d: Partner & Query) => {
        return {
          text: $t("partnerBanking.partnerBankings").toString(),
          exact: true,
          to: locations.partnerBankingTable(d)
        } as IBreadcrumb;
      },
      PartnerBankingDetail: (d: Partner & PartnerBanking & Query) => {
        return {
          text: $t("partnerBanking.partnerBanking").toString(),
          exact: true,
          to: locations.partnerBankingDetail(d)
        } as IBreadcrumb;
      },
      PartnerBankingDetailForm: (d: Partner & PartnerBanking & Query) => {
        return {
          text: $t("common.verbs.edit").toString(),
          exact: true,
          to: locations.partnerBankingDetailForm(d)
        } as IBreadcrumb;
      }
    };
  }

  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  static locations = {
    partnerBankingTable: (d: Partner & Query) => {
      return {
        name: PartnerBankingRouteNames.PARTNER_BANKING_TABLE,
        params: { ...d, query: "" },
        query: d.query
      };
    },
    partnerBankingDetail: (d: Partner & PartnerBanking & Query) => {
      return {
        name: PartnerBankingRouteNames.PARTNER_BANKING_DETAIL,
        params: { ...d, query: "" },
        query: d.query
      };
    },
    partnerBankingDetailForm: (d: Partner & PartnerBanking & Query) => {
      return {
        name: PartnerBankingRouteNames.PARTNER_BANKING_DETAIL_FORM,
        params: { ...d, query: "" },
        query: d.query
      };
    }
  };

  goToPartnerBankingTable(d: Partner & Query & Tab) {
    this.go(PartnerBankingGoToHelper.locations.partnerBankingTable(d), d.newTab);
  }

  goToPartnerBankingDetail(d: Partner & PartnerBanking & Query & Tab) {
    this.go(PartnerBankingGoToHelper.locations.partnerBankingDetail(d), d.newTab);
  }

  goToPartnerBankingDetailForm(d: Partner & PartnerBanking & Query & Tab) {
    this.go(PartnerBankingGoToHelper.locations.partnerBankingDetailForm(d), d.newTab);
  }
}
