














































import TheSettingsList from "@/layouts/navigation/TheSettingsList.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationRoute } from "@/store/mapper/navigation.route";
import { ConfigModule } from "@/store/modules/config";
import { UserModule } from "@/store/modules/me-user.store";
import { NavigationModule } from "@/store/modules/navigation.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import Debug from "@/components/utility/Debug.vue";
import { UiModule } from "@/store/modules/ui.store";

@Component({
  components: {
    TheSettingsList,
    Debug
  }
})
export default class TheNavigationBaseV2 extends Vue {
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;
  selectedItem = 1;

  get drawer() {
    return UiModule.drawer;
  }

  set drawer(val: boolean | null) {
    UiModule.setDrawer(val);
  }

  get logo() {
    return AssetRepository.getAsset(false, AssetEnum.damageReportLogo);
  }

  get navigationSettings() {
    return ConfigModule.navigationSettings;
  }

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return this.partner?.id ?? "";
  }

  get items() {
    if (!this.partnerId) {
      return [];
    }

    return NavigationModule.sideBarNavigationWithSubheader;
  }

  get navigationRoutes() {
    return NavigationModule.routes
      .filter(r => r.navigation)
      .sort(function(a, b) {
        return (b.navigation?.priority || 0) - (a.navigation?.priority || 0);
      });
  }

  get isDebug() {
    return ConfigModule.debug;
  }

  login() {
    this.$router.push({ name: "Login" });
  }

  to(item: NavigationRoute): RawLocation {
    return { name: item.name, params: { partnerId: this.partnerId } };
  }
}
