import VueRouter from "vue-router";
import { BaseGoToHelper } from "./goToHelper";
import { $t } from "./t";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

export enum ContractRouteNames {
  CONTRACT_TABLE = "CONTRACT_TABLE",
  CONTRACT_DETAIL = "CONTRACT_DETAIL",
  CONTRACT_DETAIL_FORM = "CONTRACT_DETAIL_FORM"
}

type Tab = { newTab?: boolean };
type Partner = { partnerId: string };
type Contract = { contractId: string };
type Query = { query?: Record<string, string> };

export class ContractGoToHelper extends BaseGoToHelper {
  static get breadCrumbs() {
    const locations = ContractGoToHelper.locations;

    return {
      ContractTable: (d: Partner & Query) => {
        return {
          text: $t("contract.contracts").toString(),
          exact: true,
          to: locations.contractTable(d)
        } as IBreadcrumb;
      },
      ContractDetail: (d: Partner & Contract & Query) => {
        return {
          text: $t("contract.contract").toString(),
          exact: true,
          to: locations.contractDetail(d)
        } as IBreadcrumb;
      },
      ContractDetailForm: (d: Partner & Contract & Query) => {
        return {
          text: $t("common.verbs.edit").toString(),
          exact: true,
          to: locations.contractDetailForm(d)
        } as IBreadcrumb;
      }
    };
  }

  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  static locations = {
    contractTable: (d: Partner & Query) => {
      return {
        name: ContractRouteNames.CONTRACT_TABLE,
        params: { ...d, query: "" },
        query: d.query
      };
    },
    contractDetail: (d: Partner & Contract & Query) => {
      return {
        name: ContractRouteNames.CONTRACT_DETAIL,
        params: { ...d, query: "" },
        query: d.query
      };
    },
    contractDetailForm: (d: Partner & Contract & Query) => {
      return {
        name: ContractRouteNames.CONTRACT_DETAIL_FORM,
        params: { ...d, query: "" },
        query: d.query
      };
    }
  };

  goToContractTable(d: Partner & Query & Tab) {
    this.go(ContractGoToHelper.locations.contractTable(d), d.newTab);
  }

  goToContractDetail(d: Partner & Contract & Query & Tab) {
    this.go(ContractGoToHelper.locations.contractDetail(d), d.newTab);
  }

  goToContractDetailForm(d: Partner & Contract & Query & Tab) {
    this.go(ContractGoToHelper.locations.contractDetailForm(d), d.newTab);
  }
}
