/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Services offered by a company
 */
export enum CompanyServiceEnum {
  WORKSHOP = "WORKSHOP",
  LAWYER = "LAWYER",
  APPRAISER = "APPRAISER",
  RENTAL = "RENTAL",
  INSURANCE = "INSURANCE",
  TOWING = "TOWING",
  LEASING = "LEASING"
}
