












































































































































import RefsPartner from "@/components/utility/RefsPartner.vue";
import RefsPerson from "@/components/utility/RefsPerson.vue";
import RefsReport from "@/components/utility/RefsReport.vue";
import RefsVehicle from "@/components/utility/RefsVehicle.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import NavigationListItem from "@/layouts/navigation/NavigationListItem.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { PersonRouteNames } from "@/lib/utility/person.go-to-helper";
import { PersonEmailBase } from "@/models/person-email.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { UserModule } from "@/store/modules/me-user.store";
import { NavigationModule } from "@/store/modules/navigation.store";
import { PartnerModule } from "@/store/modules/partner";
import { RefTypeMap } from "@/store/modules/refs.store";
import { PersonSearchModule, ReportSearchModule, VehicleSearchModule } from "@/store/modules/search.store";
import { debounce } from "debounce";
import Fuse from "fuse.js";
import { Component, Vue, Watch } from "vue-property-decorator";

export type CommandPaletteResultType = {
  type: string;
  label: string;
  tags?: string[];
  data?: any;
  action?: () => Promise<any>;
};

@Component({
  components: { Tooltip, RefsReport, RefsVehicle, RefsPartner, RefsPerson, NavigationListItem }
})
export default class CommandPalette extends Vue implements IDialog {
  isOpen = false;
  isLoading = false;
  searchPattern = "";

  debounceTimeout = 300;

  selectedItem: number | undefined = 0;

  @Watch("searchPattern")
  searchQuery() {
    this.debouncedSearch();
  }

  debouncedSearch = debounce(() => this.search(), this.debounceTimeout, false);

  async search() {
    this.isLoading = true;
    const promises: Promise<any>[] = [];

    try {
      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.REPORT, this.partner.id)) {
        ReportSearchModule.setSearch(this.searchPattern);
        promises.push(ReportSearchModule.fetchPage({ partnerId: this.partner.id }));
      }

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.VEHICLE, this.partner.id)) {
        VehicleSearchModule.setSearch(this.searchPattern);
        promises.push(VehicleSearchModule.fetchPage({ partnerId: this.partner.id }));
      }

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.PERSON, this.partner.id)) {
        PersonSearchModule.setSearch(this.searchPattern);
        promises.push(PersonSearchModule.fetchPage({ partnerId: this.partner.id }));
      }

      await Promise.all(promises);
    } finally {
      this.isLoading = false;
    }
  }

  goToQuickLinks() {
    this.$router.push({ name: "QuickLinks", params: { partnerId: this.partner.id } });
  }

  open(): void {
    this.openPalette();
  }

  close(): void {
    this.closePalette();
  }

  get reports() {
    return ReportSearchModule.entities;
  }

  get vehicles() {
    return VehicleSearchModule.entities;
  }

  get persons() {
    return PersonSearchModule.entities;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get partners() {
    return PartnerModule.partners;
  }

  get BackendResourceEnum() {
    return BackendResourceEnum;
  }

  get RefTypeMap() {
    return RefTypeMap;
  }

  get allQuickLinks() {
    return NavigationModule.quickLinks;
  }

  get favLinks() {
    return this.allQuickLinks.slice(0, 5);
  }

  get isAdmin() {
    return UserModule.isAdmin;
  }

  get mergedItems() {
    const results: CommandPaletteResultType[] = [];

    if (this.isAdmin) {
      results.push(
        ...this.partners.map(v => ({
          type: BackendResourceEnum.PARTNER,
          label: v.companyName ?? v.companyUsername,
          data: v,
          action: async () =>
            await this.$router.push({
              name: "PartnerDashboard",
              params: { partnerId: v.id }
            })
        }))
      );
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.VEHICLE, this.partner.id) && this.vehicles) {
      results.push(
        ...this.vehicles.map(v => ({
          type: BackendResourceEnum.VEHICLE,
          label: v.displayName ?? v.numberplate,
          tags: [v.identificationnumber].filter((tag): tag is string => tag !== undefined),
          data: v,
          action: async () =>
            await this.$router.push({
              name: "FleetVehicleDetail",
              params: { vehicleId: v.id, partnerId: v.partnerId }
            })
        }))
      );
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.REPORT, this.partner.id) && this.reports) {
      results.push(
        ...this.reports.map(r => ({
          type: BackendResourceEnum.REPORT,
          label: r.numberplate ?? r.title,
          tags: [r.customerContact?.email].filter((tag): tag is string => tag !== undefined),
          data: r,
          action: async () =>
            await this.$router.push({
              name: "PartnerReportsDetailView",
              params: { reportId: r.id, partnerId: r.partnerId }
            })
        }))
      );
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.PERSON, this.partner.id) && this.persons) {
      results.push(
        ...this.persons.map(r => ({
          type: BackendResourceEnum.PERSON,
          label: r.titleReadable,
          tags: r.emails.map((e: PersonEmailBase) => e.email).filter((tag): tag is string => tag !== undefined),
          data: r,
          action: async () =>
            await this.$router.push({
              name: PersonRouteNames.PERSON_DETAIL,
              params: { personId: r.id, partnerId: r.partnerId }
            })
        }))
      );
    }

    results.push(
      ...this.allQuickLinks.map(l => ({
        type: "link",
        label: l.title ?? l.name ?? l.path,
        tags: l.tags,
        data: l,
        action: async () => await this.$router.push({ name: l.name, params: { partnerId: this.partner.id } })
      }))
    );

    return results;
  }

  get filteredItems() {
    const fuseOptions = {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // fieldNormWeight: 1,
      keys: ["label", "tags"]
    };

    const fuse = new Fuse(this.mergedItems, fuseOptions);

    return fuse.search(this.searchPattern);
  }

  get emptyDataSrc() {
    return AssetRepository.getAsset(false, AssetEnum.loading);
  }

  openPalette() {
    this.isOpen = true;

    this.search();

    this.$nextTick(() => {
      const input: HTMLInputElement = this.$refs.commandPaletteSearchFiled as HTMLInputElement;
      if (input) {
        input.focus();
      } else {
        this.$log.warn("commandPaletteSearchFiled not found");
      }
    });
  }

  closePalette() {
    this.isOpen = false;
    this.searchPattern = "";
  }

  async selectItem(item: CommandPaletteResultType) {
    if (item.action) {
      await item.action();
    }

    this.closePalette();
  }

  onGlobalKeyDown(e: KeyboardEvent) {
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    const metaKey = isMac ? e.metaKey : e.ctrlKey;
    const altOrOption = e.altKey || (isMac && e.altKey);
    const pressedK = e.key.toLowerCase() === "k";

    if (metaKey && pressedK && !altOrOption) {
      e.preventDefault();
      this.openPalette();
    }

    if (metaKey && altOrOption && pressedK) {
      e.preventDefault();
      this.openPalette();
    }
  }

  mounted() {
    window.addEventListener("keydown", this.onGlobalKeyDown);
  }

  beforeDestroy() {
    window.removeEventListener("keydown", this.onGlobalKeyDown);
  }
}
