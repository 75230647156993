import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { ICompany, Company } from "@/models/company.entity";

export const CompanyDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<ICompany, ""> {
  getIdentifier(entity: ICompany): string {
    return entity.id;
  }

  mapEntity(entity: ICompany): ICompany {
    return new Company(entity);
  }
})();
