/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCompanyGroupControllerGetParamsGen,
  MrfiktivCompanyGroupViewModelGen,
  MrfiktivCreateCompanyGroupDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateCompanyGroupDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class CompanyGroup<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags company group
   * @name CompanyGroupControllerCreate
   * @summary (CompanyGroup - CREATE) Create a CompanyGroup
   * @request POST:/partner/{partnerId}/company-group
   * @secure
   * @response `201` `MrfiktivCompanyGroupViewModelGen` The created company group
   */
  companyGroupControllerCreate = (
    partnerId: string,
    data: MrfiktivCreateCompanyGroupDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivCompanyGroupViewModelGen, any>({
      path: `/partner/${partnerId}/company-group`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags company group
   * @name CompanyGroupControllerGet
   * @summary (CompanyGroup - READ) Get all companies
   * @request GET:/partner/{partnerId}/company-group
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivCompanyGroupViewModelGen)[] })`
   */
  companyGroupControllerGet = (
    { partnerId, ...query }: MrfiktivCompanyGroupControllerGetParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivCompanyGroupViewModelGen[] }, any>({
      path: `/partner/${partnerId}/company-group`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags company group
   * @name CompanyGroupControllerGetOne
   * @summary (CompanyGroup - READ) Get a company group
   * @request GET:/partner/{partnerId}/company-group/{companyGroupId}
   * @secure
   * @response `200` `MrfiktivCompanyGroupViewModelGen` The company group
   */
  companyGroupControllerGetOne = (partnerId: string, companyGroupId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivCompanyGroupViewModelGen, any>({
      path: `/partner/${partnerId}/company-group/${companyGroupId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags company group
   * @name CompanyGroupControllerUpdate
   * @summary (CompanyGroup - UPDATE) Updates a company group
   * @request PATCH:/partner/{partnerId}/company-group/{companyGroupId}
   * @secure
   * @response `201` `MrfiktivCompanyGroupViewModelGen` The updated company group
   */
  companyGroupControllerUpdate = (
    partnerId: string,
    companyGroupId: string,
    data: MrfiktivUpdateCompanyGroupDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivCompanyGroupViewModelGen, any>({
      path: `/partner/${partnerId}/company-group/${companyGroupId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags company group
   * @name CompanyGroupControllerDelete
   * @summary (CompanyGroup - DELETE) Deletes a company group
   * @request DELETE:/partner/{partnerId}/company-group/{companyGroupId}
   * @secure
   * @response `200` `MrfiktivCompanyGroupViewModelGen` The deleted company group
   */
  companyGroupControllerDelete = (partnerId: string, companyGroupId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivCompanyGroupViewModelGen, any>({
      path: `/partner/${partnerId}/company-group/${companyGroupId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
