import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { driveTypeMap } from "@/lib/utility/driveTypeMap";
import { $t } from "@/lib/utility/t";
import { MrfiktivRegistrationDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { VehicleTypes } from "@/store/enum/vehicle-types.enum";

@IsFormable
@IsFilterable
export class RegistrationBase implements MrfiktivRegistrationDtoGen {
  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.name", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.name"
    },
    clearable: true
  })
  name?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.firstname", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.firstname"
    },
    clearable: true
  })
  firstname?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.street", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.street"
    },
    clearable: true
  })
  street?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.zipCode", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.zipCode"
    },
    clearable: true
  })
  zipCode?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.city", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.city"
    },
    clearable: true
  })
  city?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.country", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.vehicle.registration.country",
      items: Object.values(CountryCodeEnum).map(v => {
        return {
          text: $t(`CountryCodeEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    clearable: true
  })
  country?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.huMonth", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.vehicle.registration.huMonth",
      items: Array.from({ length: 12 }, (_, i) => 1 + i).map(v => `${v}`)
    },
    clearable: true
  })
  huMonth?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.huYear", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.vehicle.registration.huYear",
      items: Array.from({ length: 50 }, (_, i) => 1990 + i).map(v => `${v}`)
    },
    clearable: true
  })
  huYear?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.firstregistrationDay", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.vehicle.registration.firstregistrationDay",
      items: Array.from({ length: 31 }, (_, i) => 1 + i).map(v => `${v}`)
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.firstregistrationDay"
  })
  firstregistrationDay?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.firstregistrationMonth", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.vehicle.registration.firstregistrationMonth",
      items: Array.from({ length: 12 }, (_, i) => 1 + i).map(v => `${v}`)
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.firstregistrationMonth"
  })
  firstregistrationMonth?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.firstregistrationYear", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.vehicle.registration.firstregistrationYear",
      items: Array.from({ length: 50 }, (_, i) => 1990 + i).map(v => `${v}`)
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.firstregistrationYear"
  })
  firstregistrationYear?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.initialRegistrationDay", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.vehicle.registration.initialRegistrationDay",
      items: Array.from({ length: 31 }, (_, i) => 1 + i).map(v => `${v}`)
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.initialRegistrationDay"
  })
  initialRegistrationDay?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.initialRegistrationMonth", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.vehicle.registration.initialRegistrationMonth",
      items: Array.from({ length: 12 }, (_, i) => 1 + i).map(v => `${v}`)
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.initialRegistrationMonth"
  })
  initialRegistrationMonth?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.initialRegistrationYear", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.vehicle.registration.initialRegistrationYear",
      items: Array.from({ length: 50 }, (_, i) => 1990 + i).map(v => `${v}`)
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.initialRegistrationYear"
  })
  initialRegistrationYear?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.manufacturerNameCode", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.manufacturerNameCode"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.manufacturerNameCode"
  })
  manufacturerNameCode?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.manufacturerTypeCode", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.manufacturerTypeCode"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.manufacturerTypeCode"
  })
  manufacturerTypeCode?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.vehicleClass", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.vehicle.registration.vehicleClass",
      items: Object.values(VehicleTypes).map(t => {
        return {
          text: `${$t(`views.fleet.FleetVehicleListView.type.${t}.title`)} ${$t(
            `views.fleet.FleetVehicleListView.type.${t}.classSpeaking`
          )} ${$t(`views.fleet.FleetVehicleListView.type.${t}.hint`)}`,
          value: t
        };
      }),
      itemValue: "value"
    },
    clearable: true
  })
  vehicleClass?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.manufacturerType", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.manufacturerType"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.manufacturerType"
  })
  manufacturerType?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.manufacturerName", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.manufacturerName"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.manufacturerName"
  })
  manufacturerName?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.manufacturerDescription", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.manufacturerDescription"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.manufacturerDescription"
  })
  manufacturerDescription?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.driveTyp", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.vehicle.registration.driveTyp",
      items: Array.from(driveTypeMap.entries()).map(entry => {
        return {
          text: `${entry[1].text} (${entry[0]})`,
          value: entry[0]
        };
      }),
      itemValue: "value"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.driveTyp"
  })
  driveTyp?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.width", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.vehicle.registration.width"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.width"
  })
  width?: number;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.height", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.vehicle.registration.height"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.height"
  })
  height?: number;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.length", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.vehicle.registration.length"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.length"
  })
  length?: number;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.weight", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.vehicle.registration.weight"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.weight"
  })
  weight?: number;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.weightTotal", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.vehicle.registration.weightTotal"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.weightTotal"
  })
  weightTotal?: number;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.speedLimit", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.vehicle.registration.speedLimit"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.speedLimit"
  })
  speedLimit?: number;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.typeApproval", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.typeApproval"
    },
    clearable: true
  })
  typeApproval?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.typeApprovalDay", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.vehicle.registration.typeApprovalDay",
      items: Array.from({ length: 31 }, (_, i) => 1 + i).map(v => `${v}`)
    },
    clearable: true
  })
  typeApprovalDay?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.typeApprovalMonth", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.vehicle.registration.typeApprovalMonth",
      items: Array.from({ length: 12 }, (_, i) => 1 + i).map(v => `${v}`)
    },
    clearable: true
  })
  typeApprovalMonth?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.typeApprovalYear", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.vehicle.registration.typeApprovalYear",
      items: Array.from({ length: 50 }, (_, i) => 1990 + i).map(v => `${v}`)
    },
    clearable: true
  })
  typeApprovalYear?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.seatingCapacity", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.vehicle.registration.seatingCapacity"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.seatingCapacity"
  })
  seatingCapacity?: number;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.standingCapacity", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.vehicle.registration.standingCapacity"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.standingCapacity"
  })
  standingCapacity?: number;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "common.nouns.vehicleRegistration",
    searchKeywords: ["objects.vehicle.registration.brand", "common.nouns.vehicleRegistration"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.vehicle.registration.brand"
    },
    clearable: true
  })
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.brand"
  })
  brand?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.identificationnumber"
  })
  identificationnumber?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.numberplate"
  })
  numberplate?: string;

  constructor(registration?: Partial<MrfiktivRegistrationDtoGen>) {
    this.firstname = registration?.firstname ?? "";
    this.name = registration?.name ?? "";
    this.street = registration?.street ?? "";
    this.zipCode = registration?.zipCode ?? "";
    this.city = registration?.city ?? "";
    this.huYear = registration?.huYear ?? "";
    this.huMonth = registration?.huMonth ?? "";
    this.manufacturerNameCode = registration?.manufacturerNameCode;
    this.manufacturerTypeCode = registration?.manufacturerTypeCode;
    this.driveTyp = registration?.driveTyp;
    this.identificationnumber = registration?.identificationnumber ?? "";
    this.numberplate = registration?.numberplate ?? "";
    this.firstregistrationDay = registration?.firstregistrationDay ?? "";
    this.firstregistrationMonth = registration?.firstregistrationMonth ?? "";
    this.firstregistrationYear = registration?.firstregistrationYear;
    this.initialRegistrationDay = registration?.initialRegistrationDay ?? "";
    this.initialRegistrationMonth = registration?.initialRegistrationMonth ?? "";
    this.initialRegistrationYear = registration?.initialRegistrationYear ?? "";
    this.manufacturerName = registration?.manufacturerName ?? "";
    this.manufacturerType = registration?.manufacturerType ?? "";
    this.manufacturerDescription = registration?.manufacturerDescription ?? "";
    this.vehicleClass = registration?.vehicleClass ?? "";

    this.typeApproval = registration?.typeApproval;
    this.typeApprovalDay = registration?.typeApprovalDay;
    this.typeApprovalMonth = registration?.typeApprovalMonth;
    this.typeApprovalYear = registration?.typeApprovalYear;
    this.brand = registration?.brand;

    this.seatingCapacity = Number(registration?.seatingCapacity ?? 0);
    this.standingCapacity = Number(registration?.standingCapacity ?? 0);
    this.width = Number(registration?.width ?? 0);
    this.height = Number(registration?.height ?? 0);
    this.length = Number(registration?.length ?? 0);
    this.weight = Number(registration?.weight ?? 0);
    this.weightTotal = Number(registration?.weightTotal ?? 0);
    this.speedLimit = Number(registration?.speedLimit ?? 0);
  }

  get firstregistrationDate() {
    return `${this.firstregistrationDay}.${this.firstregistrationMonth}.${this.firstregistrationYear}`;
  }
}
type IRegistration = RegistrationBase;
const Registration = Form.createForClass(Filter.createForClass(RegistrationBase));

export { IRegistration, Registration };
