/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

export default class IndexedDBHelper<T> {
  /**
   * The store name
   */
  dbName: string;

  /**
   * The version
   */
  version: number;

  /**
   * The open database
   */
  db: IDBDatabase | null = null;

  /**
   * @param {string} dbName - The name of the IndexedDB database.
   * @param {number} version - The version of the database.
   */
  constructor(dbName: string, version = 1) {
    this.dbName = dbName;
    this.version = version;
  }

  /**
   * Opens (or creates) an object store.
   * @param {string} storeName - The name of the object store.
   * @param {string} id - The property to use as the primary key.
   * @returns {Promise} Resolves with the database instance.
   */
  openStore(storeName: string, id: string) {
    return new Promise((resolve, reject) => {
      if (!window.indexedDB) return;

      const request = indexedDB.open(this.dbName, this.version);

      // This event fires if the database is new or the version number is higher than the existing one.
      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = (event.target as IDBOpenDBRequest).result;

        if (!db.objectStoreNames.contains(storeName)) {
          // Create the store with the specified keyPath.
          const store = db.createObjectStore(storeName, { keyPath: id });
          // Create a secondary index for partnerId for sharding purposes.
          store.createIndex("partnerId", "partnerId", { unique: false });
        }
      };

      request.onsuccess = event => {
        this.db = (event.target as IDBOpenDBRequest).result;

        resolve(this.db);
      };

      request.onerror = event => {
        reject((event.target as IDBOpenDBRequest).error);
      };
    });
  }

  /**
   * Adds a new record or updates an existing one.
   * @param {string} storeName - The object store name.
   * @param {Object} data - The data object to add/update.
   * @returns {Promise} Resolves with the primary key of the stored record.
   */
  addOrUpdate(storeName: string, data: T): Promise<IDBValidKey> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return;
      }

      const transaction = this.db.transaction([storeName], "readwrite");
      const store = transaction.objectStore(storeName);
      const request = store.put(data);

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = event => {
        reject((event.target as IDBOpenDBRequest).error);
      };
    });
  }

  /**
   * Finds a record by its primary key (id) and verifies it belongs to the given partner.
   * @param {string} storeName - The object store name.
   * @param {*} id - The primary key of the record.
   * @returns {Promise} Resolves with the record if found and partnerId matches, otherwise null.
   */
  findById(storeName: string, id: string): Promise<T | undefined> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return;
      }

      const transaction = this.db.transaction([storeName], "readonly");
      const store = transaction.objectStore(storeName);
      const request = store.get(id);

      request.onsuccess = event => {
        const record = (event.target as IDBRequest).result;

        if (record) {
          resolve(record);
        } else {
          resolve(undefined);
        }
      };

      request.onerror = event => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  /**
   * Finds a record by its primary key (id) and verifies it belongs to the given partner.
   * @param {string} storeName - The object store name.
   * @param {*} partnerId - The partnerId to match against.
   * @param {*} id - The primary key of the record.
   * @returns {Promise} Resolves with the record if found and partnerId matches, otherwise null.
   */
  findByIdAndPartner(storeName: string, partnerId: string, id: string) {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return;
      }

      const transaction = this.db.transaction([storeName], "readonly");
      const store = transaction.objectStore(storeName);
      const request = store.get(id);

      request.onsuccess = event => {
        const record = (event.target as IDBRequest).result;

        if (record && record.partnerId === partnerId) {
          resolve(record);
        } else {
          resolve(undefined);
        }
      };

      request.onerror = event => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  getAll<T>(storeName: string): Promise<T[]> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return;
      }

      const transaction = this.db.transaction([storeName], "readonly");
      const store = transaction.objectStore(storeName);
      const request = store.getAll();

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (event: Event) => {
        reject(request.error);
      };
    });
  }

  delete(storeName: string, key: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return;
      }

      const transaction = this.db.transaction([storeName], "readwrite");
      const store = transaction.objectStore(storeName);
      const request = store.delete(key);
      request.onsuccess = () => resolve();
      request.onerror = (event: Event) => reject((event.target as IDBRequest).error);
    });
  }
}

/**
 *
 */
export interface IIndexDbAccessLayer<T> {
  get(id: string): Promise<T | undefined>;
  set(entity: T): Promise<T | undefined>;
  delete(id: string): Promise<void>;
  entities(): Promise<T[]>;
}

/**
 * A data access layer interface, that handles access to a list of entity of a certain type.
 */
export abstract class AbstractLocalIndexDbAccessLayer<T> implements IIndexDbAccessLayer<T> {
  private dbHelper: IndexedDBHelper<T>;

  private store: string;
  private idKey: string;

  constructor(db: string, v: number, store: string, idKey: string) {
    this.dbHelper = new IndexedDBHelper(db, v);
    this.store = store;
    this.idKey = idKey;

    this.dbHelper.openStore(store, idKey).catch(e => console.error(e));
  }

  /**
   * @inheritdoc
   */
  get(id: string): Promise<T | undefined> {
    return this.dbHelper.findById(this.store, id);
  }

  /**
  /**
   * @inheritdoc
   */
  async set(entity: T): Promise<T> {
    await this.dbHelper.addOrUpdate(this.store, entity);

    return entity;
  }

  /**
   * @inheritdoc
   */
  async delete(id: string): Promise<void> {
    return this.dbHelper.delete(this.store, id);
  }

  /**
   * @inheritdoc
   */
  async entities(): Promise<T[]> {
    return this.dbHelper.getAll(this.store);
  }
}
