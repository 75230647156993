/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreatePersonGroupDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivPersonGroupControllerGetParamsGen,
  MrfiktivPersonGroupViewModelGen,
  MrfiktivUpdatePersonGroupDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PersonGroup<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags person group
   * @name PersonGroupControllerCreate
   * @summary (PersonGroup - CREATE) Create a PersonGroup
   * @request POST:/partner/{partnerId}/person-group
   * @secure
   * @response `201` `MrfiktivPersonGroupViewModelGen` The created person group
   */
  personGroupControllerCreate = (
    partnerId: string,
    data: MrfiktivCreatePersonGroupDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPersonGroupViewModelGen, any>({
      path: `/partner/${partnerId}/person-group`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags person group
   * @name PersonGroupControllerGet
   * @summary (PersonGroup - READ) Get all companies
   * @request GET:/partner/{partnerId}/person-group
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivPersonGroupViewModelGen)[] })`
   */
  personGroupControllerGet = (
    { partnerId, ...query }: MrfiktivPersonGroupControllerGetParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivPersonGroupViewModelGen[] }, any>({
      path: `/partner/${partnerId}/person-group`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags person group
   * @name PersonGroupControllerGetOne
   * @summary (PersonGroup - READ) Get a person group
   * @request GET:/partner/{partnerId}/person-group/{personGroupId}
   * @secure
   * @response `200` `MrfiktivPersonGroupViewModelGen` The person group
   */
  personGroupControllerGetOne = (partnerId: string, personGroupId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPersonGroupViewModelGen, any>({
      path: `/partner/${partnerId}/person-group/${personGroupId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags person group
   * @name PersonGroupControllerUpdate
   * @summary (PersonGroup - UPDATE) Updates a person group
   * @request PATCH:/partner/{partnerId}/person-group/{personGroupId}
   * @secure
   * @response `201` `MrfiktivPersonGroupViewModelGen` The updated person group
   */
  personGroupControllerUpdate = (
    partnerId: string,
    personGroupId: string,
    data: MrfiktivUpdatePersonGroupDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPersonGroupViewModelGen, any>({
      path: `/partner/${partnerId}/person-group/${personGroupId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags person group
   * @name PersonGroupControllerDelete
   * @summary (PersonGroup - DELETE) Deletes a person group
   * @request DELETE:/partner/{partnerId}/person-group/{personGroupId}
   * @secure
   * @response `200` `MrfiktivPersonGroupViewModelGen` The deleted person group
   */
  personGroupControllerDelete = (partnerId: string, personGroupId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPersonGroupViewModelGen, any>({
      path: `/partner/${partnerId}/person-group/${personGroupId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
