






































































































import { detailedDateWithDay } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { IReport } from "@/models/report.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { reportStateMap, reportStatusIconMap } from "@/store/mapper/report-icon.map";
import { Component, Prop } from "vue-property-decorator";
import BaseRef from "./BaseRef.vue";
import Tooltip from "./tooltip.vue";
import { IRefDetail } from "./RefsList.vue";

@Component({
  components: { Tooltip }
})
export default class RefsReport extends BaseRef<IReport> implements IRefDetail<IReport> {
  @Prop()
  item!: IReport;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  @Prop({ default: false })
  confirmablePersistant!: boolean;

  loadingConfirm = false;

  ProgressStatusEnum = ProgressStatusEnum;

  get ActionEnum() {
    return ActionEnum;
  }

  get ResourceEnum() {
    return ResourceEnum;
  }

  get partnerId() {
    return this.item.partnerId;
  }

  get date() {
    if (this.item?.timestamps?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDateWithDay(this.item?.timestamps?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }

  get icon() {
    const status = this.item.progressStatus;
    if (!status) return "";
    return reportStatusIconMap.get(status as ProgressStatusEnum) || "";
  }

  get color() {
    return reportStateMap.get(this.item.progressStatus as ProgressStatusEnum)?.color || "blue-grey lighten-4";
  }

  get isOpen() {
    return (
      this.item.progressStatus === ProgressStatusEnum.NEW || this.item.progressStatus === ProgressStatusEnum.IN_PROGRESS
    );
  }

  get isClosed() {
    return (
      this.item.progressStatus === ProgressStatusEnum.DELETED ||
      this.item.progressStatus === ProgressStatusEnum.FINISHED
    );
  }

  get imageCount() {
    if (!this.item.images) {
      return 0;
    }

    let counter = 0;
    for (const image of Object.values(this.item.images)) {
      counter += image.length;
    }

    return counter;
  }

  get isConfirmable() {
    return (
      this.item.progressStatus !== ProgressStatusEnum.FINISHED &&
      this.item.progressStatus !== ProgressStatusEnum.DELETED
    );
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  confirm() {
    this.confirmAsync();
    this.$emit("confirm", this.item);
  }

  goToDetail() {
    new GoToHelper(this.$router).goToReportDetail(this.item.id, this.item.partnerId, true);
  }

  async confirmAsync() {
    try {
      this.loadingConfirm = true;
      this.item.updateProgressStatus(ProgressStatusEnum.FINISHED);

      this.$toast.success(this.$t("common.BackendResourceEnum.click.success.report"));
    } catch (error) {
      handleError(error);
    } finally {
      this.loadingConfirm = false;
    }
  }
}
