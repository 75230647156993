import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { CustomViewEntity } from "@/lib/interfaces/custom-view-entity.interface";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { GroupGoToHelper } from "@/lib/utility/group.go-to-helper";
import groupService from "@/services/mrfiktiv/services/group-service";
import {
  MrfiktivCustomFieldListElementDtoGen,
  MrfiktivGroupViewModelGen,
  MrfiktivUpdateGroupDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { GroupDataAccessLayer } from "@/store/modules/access-layers/group.access-layer";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import VueRouter from "vue-router";
import { IProjectConfiguration, ProjectConfiguration } from "./project-configuration.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { IGroupType } from "@/lib/utility/group/interface/group-type.interface";
import { GroupTypes } from "@/lib/utility/group/group-types.const";

@IsFilterable
class GroupBase
  implements IEntity<Omit<MrfiktivGroupViewModelGen, "type">, MrfiktivUpdateGroupDtoGen>, CustomViewEntity<IGroup> {
  /** Id of the group */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.group.id"
  })
  id: string;

  /** The partnerId of the group */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.group.partnerId"
  })
  partnerId: string;

  /** The creator */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.group.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: (item: any) => {
        return { item };
      },
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId: string;

  /** Title of the group */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.group.title"
  })
  title: string;

  /** Internal description of the group */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.group.description"
  })
  description?: string;

  @FilterConfig({
    type: ProjectConfiguration
  })
  configuration: IProjectConfiguration;

  @FilterConfig({
    type: FilterTypes.ENUM,
    displayName: "objects.group.type",
    config: {
      items: GroupTypes.map(e => {
        return {
          text: `BackendResourceEnum.${e}`,
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  type: IGroupType;

  /** timestamp */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  constructor(data?: Partial<MrfiktivGroupViewModelGen>) {
    this.id = data?.id ?? "";
    this.userId = data?.userId ?? "";
    this.partnerId = data?.partnerId ?? "";
    this.title = data?.title ?? "";
    this.description = data?.description ?? "";
    this.configuration = new ProjectConfiguration(data?.configuration);
    this.type = data?.type as IGroupType;
    this.timestamp = new Timestamp(data?.timestamp);
  }

  map(data: MrfiktivGroupViewModelGen): void {
    this.id = data.id;
    this.userId = data.userId;
    this.partnerId = data.partnerId;
    this.title = data.title;
    this.description = data.description;
    this.configuration = new ProjectConfiguration(data.configuration);
    this.type = data?.type as IGroupType;
    this.timestamp = new Timestamp(data.timestamp);
  }

  private get customFieldConfigDto(): MrfiktivCustomFieldListElementDtoGen[] {
    return this.configuration.customFieldConfig
      .filter(c => c.customField.id) // filter out deleted custom fields
      .map(c => {
        return {
          configuration: c.configuration,
          customField: c.customField.id
        };
      });
  }

  async create(): Promise<this> {
    const res = await groupService.create(this.partnerId, {
      title: this.title,
      description: this.description,
      configuration: {
        isTemplate: this.configuration.isTemplate,
        customFieldConfig: this.customFieldConfigDto,
        views: this.configuration.views.map(v => v.dto)
      },
      type: this.type
    });

    this.map(res);
    GroupDataAccessLayer.set(this);

    return this;
  }

  async fetch(): Promise<this> {
    try {
      const res = await groupService.getOne(this.partnerId, this.id);
      this.map(res);
      GroupDataAccessLayer.set(this);
    } catch (e) {
      GroupDataAccessLayer.delete(this);
      throw e;
    }

    return this;
  }

  async updatePartial(dto: Partial<MrfiktivUpdateGroupDtoGen>): Promise<this> {
    const res = await groupService.update(this.partnerId, this.id, dto);

    this.map(res);
    GroupDataAccessLayer.set(this);

    return this;
  }

  async update(): Promise<this> {
    const updateDto: MrfiktivUpdateGroupDtoGen = {
      title: this.title,
      description: this.description,
      configuration: {
        isTemplate: this.configuration.isTemplate,
        customFieldConfig: this.customFieldConfigDto,
        views: this.configuration.views.map(v => v.dto)
      }
    };

    return this.updatePartial(updateDto);
  }

  async delete(): Promise<void> {
    const res = await groupService.delete(this.partnerId, this.id);

    this.map(res);
    GroupDataAccessLayer.delete(this);
  }

  goTo(router: VueRouter) {
    return {
      table: () =>
        new GroupGoToHelper(router).goToGroupTypeTable({
          partnerId: this.partnerId,
          type: this.type
        }),
      detail: () =>
        new GroupGoToHelper(router).goToGroupTypeDetail({
          partnerId: this.partnerId,
          type: this.type,
          groupId: this.id
        }),
      customView: () =>
        new GroupGoToHelper(router).goToGroupTypeCustomView({
          partnerId: this.partnerId,
          type: this.type,
          groupId: this.id,
          viewId: "0"
        }),
      all: () =>
        new GroupGoToHelper(router).goToGroupTypeEntities({
          partnerId: this.partnerId,
          type: this.type
        })
    };
  }
}

type IGroup = GroupBase;
const Group = Filter.createForClass(GroupBase);

export { Group, IGroup };
